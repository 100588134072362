import React, {
    useContext,
    useMemo
} from "react";
import {
    Button
} from "react-bootstrap";
import SimpleMDEEditor from "react-simplemde-editor";
import PropTypes from "prop-types";
import moment from "moment";

import ShopsContext from "../../../context/ShopsContext";
import ProductDescriptionMarkdown from "../../../components/ProductDescriptionMarkdown";

function ProductForm({ state, originalState, onChange, loading, onSave }) {
    const shopsContext = useContext(ShopsContext);

    const productUrlResult = useMemo(() => {
        if(state.url.length === 0) {
            return null;
        }
        const folder = state.productType === "rental" ? "rental" : "product";
        return shopsContext.currentShop.url + "/" + folder + "/x/" + state.url;
    }, [state, shopsContext.currentShop]);

    const simpleMdeOptions = useMemo(() => {
        return {
            spellChecker: false,
            autoDownloadFontAwesome: false,
            toolbar: ["bold", "italic", "heading", "|", "quote", "unordered-list", "ordered-list", "|", "link", "horizontal-rule", "|", "guide"]
        }
    }, []);
    const onDescriptionChange = useMemo(() => {
        return (value) => {
            onChange({ description: value });
        }
    }, [onChange]);
    const onSummaryChange = useMemo(() => {
        return (value) => {
            onChange({ summary: value });
        }
    }, [onChange]);

    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h4>Productinformatie</h4>
                    <div className="form-group">
                        <label htmlFor="name">Productnaam</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={ state.name }
                            onChange={ (event) => onChange({ name: event.target.value })}
                            disabled={ loading }
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="subtitle">Subtitel</label>
                        <input
                            type="text"
                            className="form-control"
                            id="subtitle"
                            value={ state.subtitle }
                            onChange={ (event) => onChange({ subtitle: event.target.value })}
                            disabled={ loading }
                        />
                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="url">Pagina URL</label>
                        <input
                            type="text"
                            className="form-control"
                            id="url"
                            value={ state.url }
                            onChange={ (event) => onChange({ url: event.target.value.replace(" ", "-").replace("--", "-") })}
                            disabled={ loading }
                        />
                        { productUrlResult && (
                            <small className="form-text text-muted">
                                Resultaat: { productUrlResult }
                            </small>
                        )}
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Beschikbaarheid</h4>
                    <div className="custom-control custom-checkbox mb-0">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="orderable"
                            checked={ state.orderable }
                            onChange={ (event) => onChange({ orderable: event.target.checked })}
                            disabled={ loading }
                        />
                        <label htmlFor="orderable" className="custom-control-label">Bestelbaar</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="preorder"
                            checked={ state.preorder }
                            onChange={ (event) => onChange({ preorder: event.target.checked })}
                            disabled={ loading }
                        />
                        <label htmlFor="preorder" className="custom-control-label">Markeer als pre-order</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="visible"
                            checked={ state.visible }
                            onChange={ (event) => onChange({ visible: event.target.checked }) }
                            disabled={ loading }
                        />
                        <label htmlFor="visible" className="custom-control-label">Publiek zichtbaar</label>
                    </div>
                    <div className={ "custom-control custom-checkbox" + (state.releaseDate !== null ? " mt-3" : "") }>
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enableReleaseDate"
                            checked={ state.releaseDate !== null }
                            onChange={ (event) => {
                                if(event.target.checked) {
                                    if(originalState?.releaseDate) {
                                        onChange({ releaseDate: originalState.releaseDate });
                                    } else {
                                        onChange({ releaseDate: moment().format("YYYY-MM-DD HH:mm:ss") });
                                    }
                                } else {
                                    onChange({ releaseDate: null });
                                }
                            } }
                            disabled={ loading }
                        />
                        <label htmlFor="enableReleaseDate" className="custom-control-label">Publicatiedatum instellen</label>
                    </div>
                    { state.releaseDate !== null && (
                        <div className="form-group mb-0 ml-4 mt-2">
                            <input
                                type="datetime-local"
                                className="form-control"
                                id="releaseDate"
                                placeholder="Publicatiedatum"
                                value={ moment(state.releaseDate).format("YYYY-MM-DDTHH:mm") }
                                onChange={ (event) => onChange({ releaseDate: moment(event.target.value).format("YYYY-MM-DD HH:mm:ss") })}
                                disabled={ loading }
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Prijs</h4>
                    <div className="form-group">
                        <label htmlFor="price">Prijs incl. btw (Euro &euro;)</label>
                        <input
                            type="number"
                            className="form-control"
                            id="price"
                            value={ state.price }
                            onChange={ (event) => onChange({ price: event.target.value })}
                            disabled={ loading }
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="price">Btw percentage (%)</label>
                        <input
                            type="number"
                            className="form-control"
                            id="price"
                            value={ state.taxPercentage * 100 }
                            onChange={ (event) => onChange({ taxPercentage: parseInt(event.target.value, 10) / 100 })}
                            disabled={ loading }
                        />
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="enableDiscountPrice"
                            checked={ state.enableDiscountPrice }
                            onChange={ (event) => onChange({ enableDiscountPrice: event.target.checked })}
                            disabled={ loading }
                        />
                        <label htmlFor="enableDiscountPrice" className="custom-control-label">Kortingsprijs instellen</label>
                    </div>
                    { state.enableDiscountPrice && (
                        <div className="form-group mb-0 mt-2 ml-4">
                            <input
                                type="number"
                                className="form-control"
                                id="price"
                                placeholder="Kortingsprijs"
                                value={ state.discountPrice }
                                onChange={ (event) => onChange({ discountPrice: event.target.value }) }
                                disabled={ loading }
                            />
                            <small className="form-text text-muted">
                                Kortingsprijs incl. btw (Euro &euro;)
                            </small>
                        </div>
                    )}
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Beschrijving</h4>
                    <div className="row">
                        <div className="col-xl">
                            <SimpleMDEEditor
                                value={ state.description }
                                onChange={ onDescriptionChange }
                                options={ simpleMdeOptions }
                            />
                        </div>
                        <div className="col-md">
                            <h5>Resultaat</h5>
                            <ProductDescriptionMarkdown>
                                { state.description }
                            </ProductDescriptionMarkdown>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Samenvatting</h4>
                    <div className="row">
                        <div className="col-xl">
                            <SimpleMDEEditor
                                value={ state.summary }
                                onChange={ onSummaryChange }
                                options={ simpleMdeOptions }
                            />
                        </div>
                        <div className="col-md">
                            <h5>Resultaat</h5>
                            <ProductDescriptionMarkdown>
                                { state.summary }
                            </ProductDescriptionMarkdown>
                        </div>
                    </div>
                </div>
            </div>

            <Button
                variant="primary"
                className="float-right"
                onClick={ onSave }
                disabled={ loading }
            >
                Opslaan
            </Button>
        </React.Fragment>
    )
}
ProductForm.propTypes = {
    state: PropTypes.object,
    originalState: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    onSave: PropTypes.func.isRequired
}

export default React.memo(ProductForm);
