import React from "react";
import PropTypes from "prop-types";

import myparcel from "../../img/myparcel-logo.svg";
import sendcloud from "../../img/sendcloud-logo.svg";

function ShipmentPlatformLogo({ shipmentPlatform, ...props }) {
    if(shipmentPlatform === "myparcel") {
        return (
            <img { ...props } src={ myparcel } alt="MyParcel"/>
        );
    }
    if(shipmentPlatform === "sendcloud") {
        return (
            <img { ...props } src={ sendcloud } alt="Sendcloud"/>
        );
    }
    return null;
}
ShipmentPlatformLogo.propTypes = {
    shipmentPlatform: PropTypes.string.isRequired
};

export default React.memo(ShipmentPlatformLogo);
