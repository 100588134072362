import React, {
    useContext,
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import {
    NavDropdown
} from "react-bootstrap";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

function NavbarAccountDropdown() {
    const userContext = useContext(AuthenticatedUserContext);

    const showAccountLink = useMemo(() => {
        const currentUser = userContext.user;
        if(!currentUser) {
            return false;
        }
        return currentUser.id === 1 || currentUser.id === 2;
    }, [userContext.user]);

    return (
        <NavDropdown
            title={ userContext.user && userContext.user.name }
            id="user"
            align="end"
        >
            { showAccountLink && (
                <React.Fragment>
                    <Link to={ "/account" } className="dropdown-item">
                        Account
                    </Link>
                    <NavDropdown.Divider />
                </React.Fragment>
            )}
            <NavDropdown.Item onClick={ userContext.logoutFunction } className="text-danger">
                Uitloggen
            </NavDropdown.Item>
        </NavDropdown>
    )
}

export default React.memo(NavbarAccountDropdown);
