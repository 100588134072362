import React from "react";
import {
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

function OAuthDisconnectModal({ service, show, handleClose, onSubmit, loading }) {
    return (
        <Modal show={ show } onHide={ handleClose } backdrop={ loading ? "static" : true }>
            <Modal.Header closeButton>
                <Modal.Title className="text-danger">
                    { service } loskoppelen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-0">
                    Weet je zeker dat je { service } wilt loskoppelen?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Annuleren
                </Button>
                <Button variant="danger" onClick={ onSubmit } disabled={ loading }>
                    { loading && (
                        <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                    )}
                    Loskoppelen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
OAuthDisconnectModal.propTypes = {
    service: PropTypes.string.isRequired,
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool
}

export default React.memo(OAuthDisconnectModal);
