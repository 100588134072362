import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
    Chart
} from "react-charts";

import ChartLoading from "../components/charts/ChartLoading";
import ChartNoData from "../components/charts/ChartNoData";
import ShopsContext from "../context/ShopsContext";
import ChartError from "../components/charts/ChartError";
import priceFormatter from "../components/priceFormatter";

function OrderRevenueChart({ periodGrouping, startDate, endDate }) {
    const shopsContext = useContext(ShopsContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const startDateFormat = startDate.format("YYYY-MM-DD");
        const endDateFormat = endDate.format("YYYY-MM-DD");
        setData(null);
        setError(null);
        axios.post("/charts/chartOrderGetRevenue", {
            shopId: shopsContext.currentShop.id,
            periodGrouping,
            startDate: startDateFormat,
            endDate: endDateFormat
        })
            .then((response) => {
                if(response.data.valid) {
                    setData(response.data.data);
                } else {
                    setError("Er is een fout opgetreden bij het laden van de grafiek. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden bij het laden van de grafiek. Probeer het later opnieuw.");
            })
    }, [shopsContext.currentShop, periodGrouping, startDate, endDate]);

    const primaryAxis = useMemo(
        () => ({
            getValue: (datum) => datum.key
        }), []
    );
    const secondaryAxes = useMemo(
        () => [
            {
                getValue: (datum) => datum.value,
                stacked: true,
                formatters: {
                    tooltip: (value) => {
                        return priceFormatter({ price: value });
                    },
                    scale: (value) => {
                        return priceFormatter({ price: value });
                    }
                }
            },
        ], []
    );

    if(error) {
        return (
            <ChartError error={ error }/>
        )
    }
    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes,
                getSeriesStyle: () => ({
                    color: "rgb(57, 181, 70)"
                })
            }}
        />
    )
}
OrderRevenueChart.propTypes = {
    periodGrouping: PropTypes.string,
    startDate: PropTypes.any,
    endDate: PropTypes.any
}

export default React.memo(OrderRevenueChart);
