import React, {
    useContext
} from "react";
import {
    Route,
    Switch
} from "react-router-dom";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import Helmet from "../../components/Helmet";

import SettingsOverview from "./tabs/SettingsOverview";
import SettingsMailchimp from "./tabs/SettingsMailchimp";
import SettingsMollie from "./tabs/SettingsMollie";
import SettingsMoneybird from "./tabs/SettingsMoneybird";
import SettingsShopInvoice from "./tabs/SettingsShopInvoice";

function Settings() {
    const shops = useContext(ShopsContext);
    const baseUrl = "/shop/" + shops.currentShop.codeName + "/settings";
    return (
        <React.Fragment>
            <Helmet title="Instellingen"/>
            <Title preTitle="Overzicht" noBottom>
                Instellingen
            </Title>

            <TabBar>
                <TabBarItem to={ baseUrl }>
                    Overzicht
                </TabBarItem>
                <TabBarItem to={ baseUrl + "/mollie" }>
                    Mollie
                </TabBarItem>
                { shops.currentShop.entitlementMailingList && (
                    <TabBarItem to={ baseUrl + "/mailchimp" }>
                        Mailchimp
                    </TabBarItem>
                )}
                { shops.currentShop.entitlementAccountingApplication && (
                    <React.Fragment>
                        <TabBarItem to={ baseUrl + "/moneybird" }>
                            Moneybird
                        </TabBarItem>
                        <TabBarItem to={ baseUrl + "/shop-invoice" }>
                            Shop Facturatie
                        </TabBarItem>
                    </React.Fragment>
                )}
            </TabBar>

            <Switch>
                <Route path={ baseUrl } exact>
                    <SettingsOverview/>
                </Route>
                <Route path={ baseUrl + "/mollie" } exact>
                    <SettingsMollie/>
                </Route>
                { shops.currentShop.entitlementMailingList && (
                    <Route path={ baseUrl + "/mailchimp" } exact>
                        <SettingsMailchimp/>
                    </Route>
                )}
                { shops.currentShop.entitlementAccountingApplication && (
                    <Route path={ baseUrl + "/moneybird" } exact>
                        <SettingsMoneybird/>
                    </Route>
                )}
                { shops.currentShop.entitlementAccountingApplication && (
                    <Route path={ baseUrl + "/shop-invoice" } exact>
                        <SettingsShopInvoice/>
                    </Route>
                )}
            </Switch>
        </React.Fragment>
    )
}

export default React.memo(Settings);
