import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import {
    Form
} from "react-bootstrap";
import PropTypes from "prop-types";

function ObjectSelector({ objects, selectedObject, setSelectedObject, searchMatch, disabled, objectListItemContent, emptyState }) {
    const searchInput = useRef();
    const selectedObjectListItem = useRef();

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredObjects, setFilteredObjects] = useState(null);

    const filterObjects = useCallback((localSearchQuery) => {
        if(localSearchQuery === "") {
            setFilteredObjects(objects);
            return;
        }
        setFilteredObjects(objects?.filter(searchMatch(localSearchQuery)));
    }, [objects, searchMatch]);

    useEffect(() => {
        filterObjects(searchQuery);
    }, [objects, filterObjects, searchQuery]);

    const onSearchFocus = useCallback(() => {
        setSelectedObject(null);
    }, [setSelectedObject]);

    const handleKeyDirection = useCallback((direction) => {
        const index = filteredObjects.findIndex((findObject) => findObject.id === selectedObject?.id) ?? -1;
        if(direction === "up") {
            if(index === -1) {
                searchInput.current?.blur();
                setSelectedObject(filteredObjects[filteredObjects.length - 1]);
            } else if(index === 0) {
                searchInput.current?.focus();
                setSelectedObject(null);
            } else {
                searchInput.current?.blur();
                setSelectedObject(filteredObjects[index - 1]);
            }
        } else if(direction === "down") {
            if(index >= filteredObjects.length - 1) {
                searchInput.current?.focus();
                setSelectedObject(null);
            } else {
                searchInput.current?.blur();
                setSelectedObject(filteredObjects[index + 1]);
            }
        }
    }, [selectedObject, setSelectedObject, filteredObjects]);

    const handleKeyPress = useCallback((event) => {
        if(event.keyCode === 38) {
            // ArrowUp
            event.preventDefault();
            handleKeyDirection("up");
        } else if(event.keyCode === 40) {
            // ArrowDown
            event.preventDefault();
            handleKeyDirection("down");
        }
    }, [handleKeyDirection]);
    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [handleKeyPress]);

    useEffect(() => {
        selectedObjectListItem.current?.scrollIntoView({ block: "center" });
    }, [selectedObject]);
    useEffect(() => {
        searchInput.current?.focus();
    }, []);

    return (
        <React.Fragment>
            <Form.Control
                ref={searchInput}
                type="search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
                className="mb-3"
                placeholder="Zoeken"
                disabled={disabled}
                onClick={onSearchFocus}
            />
            <ul
                className="list-group list-group-border list-group-scroll"
                style={{
                    height: "500px"
                }}
            >
                { filteredObjects?.map((object) => {
                    const active = object.id === selectedObject?.id
                    let listGroupItemClassNames = ["list-group-item", "list-group-item-hover", "pointer-cursor"];
                    if (active) {
                        listGroupItemClassNames.push("active");
                    }
                    return (
                        <li
                            key={object.id}
                            className={listGroupItemClassNames.join(" ")}
                            onClick={() => setSelectedObject(object)}
                            ref={active ? selectedObjectListItem : undefined}
                        >
                            { objectListItemContent(object) }
                        </li>
                    )
                })}
                { filteredObjects?.length === 0 && (
                    <li className="list-group-item text-center d-flex justify-content-center text-muted flex-column h-100">
                        <h4><i className="fas fa-search"/></h4>
                        <p>
                            { emptyState }
                        </p>
                    </li>
                )}
            </ul>
        </React.Fragment>
    );
}

ObjectSelector.propTypes = {
    objects: PropTypes.array.isRequired,
    selectedObject: PropTypes.object,
    setSelectedObject: PropTypes.func.isRequired,
    searchMatch: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    objectListItemContent: PropTypes.func.isRequired,
    emptyState: PropTypes.node.isRequired
};

export default React.memo(ObjectSelector);
