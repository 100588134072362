import React from "react";
import PropTypes from "prop-types";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import TagPill from "./TagPill";

function StatusPill({ status }) {
    const tagPill = (
        <TagPill color={ status.color }>{ status.name }</TagPill>
    )
    if(!status.shortDescription && !status.description) {
        return tagPill;
    }
    return (
        <OverlayTrigger overlay={
            <Tooltip id="status-description">{ status.shortDescription ?? status.description }</Tooltip>
        }>
            <span>
                { tagPill }
            </span>
        </OverlayTrigger>
    )
}
StatusPill.propTypes = {
    status: PropTypes.any.isRequired
}

export default React.memo(StatusPill);
