import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import ShopsContext from "../../../context/ShopsContext";
import DetailOverviewRow from "../../../components/DetailOverviewRow";
import Loading from "../../../components/Loading";
import StatusPill from "../../../components/pills/StatusPill";
import DateFormatter from "../../../components/DateFormatter";
import PriceFormatter from "../../../components/priceFormatter";

class RentalOrderDetailOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rentalOrder: null,
            error: null
        }
    }

    componentDidMount() {
        document.title = "Huurbestelling - ShopCrate";
        this.getRentalOrder(this.props.rentalOrderId);
    }

    getRentalOrder(rentalOrderId) {
        this.setState({ rentalOrder: null, error: null });
        axios.post("/getRentalOrder", { shopId: this.context.currentShop.id, rentalOrderId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ rentalOrder: response.data.rentalOrder });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    getAmountOfDays() {
        if(!this.state.rentalOrder) {
            return null;
        }
        const startDate = moment(this.state.rentalOrder.rentalStartDate);
        const endDate = moment(this.state.rentalOrder.rentalEndDate);
        return Math.abs(moment(startDate).diff(moment(endDate), "days")) + 1;
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        const product = this.state.rentalOrder ? this.state.rentalOrder.product : null;
        const image = !product || product.images.length <= 0 ? null : product.images[0];
        const rentalAmountOfDays = this.getAmountOfDays();
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-body">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            <DetailOverviewRow title="Status">
                                { this.state.rentalOrder ? (
                                    <StatusPill status={ this.state.rentalOrder.status }/>
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Huur periode">
                                { this.state.rentalOrder ? (
                                    <React.Fragment>
                                        <DateFormatter date={ this.state.rentalOrder.rentalStartDate } format="DD-MM-YYYY"/>
                                        { this.state.rentalOrder.rentalStartDate !== this.state.rentalOrder.rentalEndDate && (
                                            <React.Fragment>
                                                {" - "}
                                                <DateFormatter date={ this.state.rentalOrder.rentalEndDate } format="DD-MM-YYYY"/>
                                            </React.Fragment>
                                        )}
                                        {" "}({ rentalAmountOfDays } { rentalAmountOfDays === 1 ? "dag" : "dagen" })
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Factuuradres"/>
                            { this.state.rentalOrder && this.state.rentalOrder.isCompany && (
                                <DetailOverviewRow title="Bedrijf">
                                    { this.state.rentalOrder ? this.state.rentalOrder.companyName : <Skeleton width={200}/> }
                                </DetailOverviewRow>
                            )}
                            <DetailOverviewRow title="Naam">
                                { this.state.rentalOrder ? this.state.rentalOrder.invoiceFirstName + " " + this.state.rentalOrder.invoiceLastName : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Adres">
                                { this.state.rentalOrder ? this.state.rentalOrder.invoiceStreet + " " + this.state.rentalOrder.invoiceHouseNumber : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Stad">
                                { this.state.rentalOrder ? this.state.rentalOrder.invoiceCity : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Postcode">
                                { this.state.rentalOrder ? this.state.rentalOrder.invoicePostalCode : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Contact informatie"/>
                            <DetailOverviewRow title="Email">
                                { this.state.rentalOrder ? (
                                    <a href={ "mailto:" + this.state.rentalOrder.email }>
                                        { this.state.rentalOrder.email }
                                    </a>
                                ) : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Telefoonnummer">
                                { this.state.rentalOrder ? (
                                    <a href={ "dial:" + this.state.rentalOrder.phoneNumber }>
                                        { this.state.rentalOrder.phoneNumber }
                                    </a>
                                ) : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            </tbody>
                        </table>
                    </div>
                </div>
                <h4 className="mt-4">Product</h4>
                { !this.state.rentalOrder ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <div className="card mb-2">
                            <div className="card-body">
                                <div className="d-flex">
                                    <div className="d-flex justify-content-center align-content-center" style={{ width: "150px", height: "100px" }}>
                                        <img
                                            src={ image ? image.url : "https://via.placeholder.com/175x130" }
                                            className="img-fluid rounded"
                                            alt="Product"
                                            style={{ maxWidth: "150px", maxHeight: "100px" }}
                                        />
                                    </div>
                                    <div className="pl-4 flex-fill">
                                        <h5 className="card-title">{ product.name }</h5>
                                        <b>
                                            <PriceFormatter price={ product.price }/>
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-end text-right mb-2 text-muted">
                                    <div style={{ width: "150px" }}>
                                        <b>BTW</b>
                                    </div>
                                    <div style={{ width: "150px" }}>
                                        <b>
                                            <PriceFormatter price={ this.state.rentalOrder.orderPrice - (this.state.rentalOrder.orderPrice / (1 + this.state.rentalOrder.orderTaxPercentage)) }/>
                                        </b>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end text-right mb-2 text-muted">
                                    <div style={{ width: "150px" }}>
                                        <b>Totaalbedrag</b>
                                        <br/>
                                        <span className="text-muted">exclusief btw</span>
                                    </div>
                                    <div style={{ width: "150px" }}>
                                        <b>
                                            <PriceFormatter price={ this.state.rentalOrder.orderPrice / (1 + this.state.rentalOrder.orderTaxPercentage) }/>
                                        </b>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end text-right">
                                    <div style={{ width: "150px" }}>
                                        <b>Totaalbedrag</b>
                                        <br/>
                                        <span className="text-muted">inclusief btw</span>
                                    </div>
                                    <div style={{ width: "150px" }}>
                                        <b>
                                            <PriceFormatter price={ this.state.rentalOrder.orderPrice }/>
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
RentalOrderDetailOverview.contextType = ShopsContext;
RentalOrderDetailOverview.propTypes = {
    rentalOrderId: PropTypes.number.isRequired
}

export default RentalOrderDetailOverview;
