import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    FormControl,
    InputGroup,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";
import SimpleMDEEditor from "react-simplemde-editor";

import ShopsContext from "../../../../context/ShopsContext";

class AddEditProductDigitalLinkModal extends React.PureComponent {
    getDefaultState() {
        const editing = this.isEditing() && this.props.productLink;
        return {
            title: editing ? this.props.productLink.title : "",
            description: editing ? this.props.productLink.description : "",
            url: editing ? this.props.productLink.url : "",

            saving: false,
            error: ""
        }
    }

    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
        this.onShow = this.onShow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.simpleMdeOptions = {
            spellChecker: false
        };
    }

    isEditing() {
        return this.props.productLink !== undefined;
    }

    onSubmit() {
        if(this.isEditing()) {
            this.setProductLink();
        } else {
            this.addProductLink();
        }
    }

    checkSubmit() {
        if(this.state.title.trim().length === 0) {
            this.setState({ error: "Titel is vereist." });
            return false;
        }
        if(this.state.url.trim().length === 0 && this.state.description.trim().length === 0) {
            this.setState({ error: "Beide link en beschrijving zijn leeg. 1 van de 2 moet minimaal ingevuld zijn." });
            return false;
        }
        return true;
    }

    addProductLink() {
        if(!this.checkSubmit()) {
            return;
        }
        this.setState({ saving: true });
        axios.post("/addDigitalProductLink", {
            shopId: this.context.currentShop.id,
            productId: this.props.product.id,
            title: this.state.title,
            url: this.state.url,
            description: this.state.description
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.handleClose();
                    this.props.linkAdded();
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    setProductLink() {
        if(!this.checkSubmit()) {
            return;
        }
        this.setState({ saving: true });
        axios.post("/setProductLink", {
            shopId: this.context.currentShop.id,
            productId: this.props.product.id,
            productLinkId: this.props.productLink.id,
            title: this.state.title,
            url: this.state.url,
            description: this.state.description
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.handleClose();
                    this.props.linkEdited();
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    onDescriptionChange(value) {
        this.setState({ description: value });
    }

    onShow() {
        this.setState(this.getDefaultState())
    }

    render() {
        const editing = this.isEditing();
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow } size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        { editing ? "Link bewerken" : "Link toevoegen" }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.error && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}

                    <label htmlFor="title">Titel</label>
                    <InputGroup className="mb-3">
                        <FormControl
                            id="title"
                            value={ this.state.title }
                            onChange={ (event) => this.setState({ title: event.target.value })}
                        />
                    </InputGroup>

                    <label htmlFor="url">Link</label>
                    <InputGroup className="mb-3">
                        <FormControl
                            id="url"
                            value={ this.state.url }
                            placeholder="https://"
                            onChange={ (event) => this.setState({ url: event.target.value })}
                        />
                    </InputGroup>

                    <label>Beschrijving</label>
                    <SimpleMDEEditor
                        value={ this.state.description }
                        onChange={ this.onDescriptionChange }
                        options={ this.simpleMdeOptions }
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.handleClose }>
                        Annuleer
                    </Button>
                    <Button variant="primary" onClick={ this.onSubmit } disabled={ this.state.saving }>
                        { this.state.saving && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                        )}
                        { editing ? "Opslaan" : "Toevoegen" }
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
AddEditProductDigitalLinkModal.contextType = ShopsContext;
AddEditProductDigitalLinkModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    linkAdded: PropTypes.func,
    linkEdited: PropTypes.func,
    productLink: PropTypes.object
}

export default AddEditProductDigitalLinkModal;
