import React, {
    useContext
} from "react";
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import PropTypes from "prop-types";

import Loading from "./components/Loading";
import ShopsContext from "./context/ShopsContext";
import BackofficeNavbar from "./components/layout/BackofficeNavbar";
import BackofficePageContainer from "./components/layout/BackofficePageContainer";
import BackofficeSidebar from "./components/layout/BackofficeSidebar";

import Error404 from "./pages/error/Error404";
import ErrorNoAccess from "./pages/error/ErrorNoAccess";
import Dashboard from "./pages/dashboard/Dashboard";
import Products from "./pages/products/Products";
import NewProduct from "./pages/products/NewProduct";
import ProductDetail from "./pages/products/detail/ProductDetail";
import Courses from "./pages/courses/Courses";
import NewCourse from "./pages/courses/NewCourse";
import CourseDetail from "./pages/courses/detail/CourseDetail";
import Categories from "./pages/categories/Categories";
import CategoryDetail from "./pages/categories/detail/CategoryDetail";
import Collections from "./pages/collections/Collections";
import CollectionDetail from "./pages/collections/detail/CollectionDetail";
import Orders from "./pages/orders/Orders";
import OrderDetail from "./pages/orders/detail/OrderDetail";
import Shipments from "./pages/shipments/Shipments";
import ShipmentDetail from "./pages/shipments/detail/ShipmentDetail";
import RentalOrders from "./pages/rental-orders/RentalOrders";
import RentalOrderDetail from "./pages/rental-orders/detail/RentalOrderDetail";
import ShopUsers from "./pages/shop-users/ShopUsers";
import ShopUserDetail from "./pages/shop-users/detail/ShopUserDetail";
import Settings from "./pages/settings/Settings";

// eslint-disable-next-line complexity
function BackofficeRouter(props) {
    const shopsContext = useContext(ShopsContext);
    if(shopsContext.shops === null || shopsContext.currentShop === undefined) {
        console.log("Waiting for shops to load...")
        return (
            <Loading/>
        )
    }
    if(shopsContext.currentShop === null) {
        console.log("currentShop is null, return to shops page.")
        return (
            <Redirect to="/shops"/>
        )
    }

    const entitlementCourses = shopsContext.currentShop.entitlementCourses;
    const entitlementCategories = shopsContext.currentShop.entitlementCategories;
    const entitlementCollections = shopsContext.currentShop.entitlementCollections;
    const entitlementOrders = shopsContext.currentShop.entitlementOrders;
    const entitlementShipments = shopsContext.currentShop.entitlementShipments;
    const entitlementRentals = shopsContext.currentShop.entitlementRentals;
    const entitlementAuthentication = shopsContext.currentShop.entitlementAuthentication;

    return (
        <React.Fragment>
            <BackofficeNavbar/>
            <BackofficeSidebar/>

            <BackofficePageContainer>
                <Switch>
                    <Route path="/shop/:shopCodeName/dashboard" component={Dashboard} />

                    <Route path="/shop/:shopCodeName/products" component={Products} exact/>
                    <Route path="/shop/:shopCodeName/products/add" component={NewProduct} exact/>
                    <Route path="/shop/:shopCodeName/product/:productId" component={ProductDetail} />

                    <Route path="/shop/:shopCodeName/courses" component={entitlementCourses ? Courses : ErrorNoAccess} exact/>
                    <Route path="/shop/:shopCodeName/courses/add" component={entitlementCourses ? NewCourse : ErrorNoAccess} exact/>
                    <Route path="/shop/:shopCodeName/course/:courseId" component={entitlementCourses ? CourseDetail : ErrorNoAccess}/>

                    <Route path="/shop/:shopCodeName/categories" component={entitlementCategories ? Categories : ErrorNoAccess} exact/>
                    <Route path="/shop/:shopCodeName/category/:categoryId" component={entitlementCategories ? CategoryDetail : ErrorNoAccess}/>

                    <Route path="/shop/:shopCodeName/collections" component={entitlementCollections ? Collections : ErrorNoAccess} exact/>
                    <Route path="/shop/:shopCodeName/collection/:collectionId" component={entitlementCollections ? CollectionDetail : ErrorNoAccess}/>

                    <Route path="/shop/:shopCodeName/orders" component={entitlementOrders ? Orders : ErrorNoAccess} exact/>
                    <Route path="/shop/:shopCodeName/orders/:filter" component={entitlementOrders ? Orders : ErrorNoAccess} />
                    <Route path="/shop/:shopCodeName/order/:orderId" component={entitlementOrders ? OrderDetail : ErrorNoAccess} />

                    <Route path="/shop/:shopCodeName/shipments" component={entitlementShipments ? Shipments : ErrorNoAccess} exact/>
                    <Route path="/shop/:shopCodeName/shipments/:filter" component={entitlementShipments ? Shipments : ErrorNoAccess} />
                    <Route path="/shop/:shopCodeName/shipment/:shipmentId" component={entitlementShipments ? ShipmentDetail : ErrorNoAccess} />

                    <Route path="/shop/:shopCodeName/rental-orders" component={entitlementRentals ? RentalOrders : ErrorNoAccess} exact/>
                    <Route path="/shop/:shopCodeName/rental-orders/:filter" component={entitlementRentals ? RentalOrders : ErrorNoAccess} />
                    <Route path="/shop/:shopCodeName/rental-order/:rentalOrderId" component={entitlementRentals ? RentalOrderDetail : ErrorNoAccess} />

                    <Route path="/shop/:shopCodeName/shop-users" component={entitlementAuthentication ? ShopUsers : ErrorNoAccess} exact/>
                    <Route path="/shop/:shopCodeName/shop-user/:shopUserId" component={entitlementAuthentication ? ShopUserDetail : ErrorNoAccess} />

                    <Route path="/shop/:shopCodeName/settings" component={Settings}/>

                    <Route path="/shop/:shopCodeName" exact>
                        <Redirect
                            to={{
                                pathname: props.location.pathname.replace(/\/$/, "") + "/dashboard",
                                state: { from: "/" }
                            }}
                        />
                    </Route>

                    <Route path="/shop/:shopCodeName" component={Error404}/>
                </Switch>
            </BackofficePageContainer>
        </React.Fragment>
    )
}
BackofficeRouter.propTypes = {
    location: PropTypes.object
}

export default BackofficeRouter;
