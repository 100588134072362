import React, {
    useContext,
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Button
} from "react-bootstrap";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import ShopsContext from "../../context/ShopsContext";
import NavbarAccountDropdown from "../layout/NavbarAccountDropdown";

function AccountNavbar() {
    const userContext = useContext(AuthenticatedUserContext);
    const shopsContext = useContext(ShopsContext);

    const goToAllShops = useMemo(() => {
        return () => {
            userContext.navigationTransition("/shops");
        }
    }, [userContext.navigationTransition]);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top navbar-only">
            <div className="container">
                <ul className="navbar-nav flex-grow-1 flex-row justify-content-start">
                    <li className="nav-item">
                        { shopsContext.shops && shopsContext.shops.length === 1 ? (
                            <Link to="/shops" className="nav-link">
                                <i className="fas fa-arrow-alt-circle-left mr-2"/>
                                Terug
                            </Link>
                        ) : (
                            <Button variant="link" className="nav-link" onClick={ goToAllShops }>
                                <i className="fas fa-arrow-alt-circle-left mr-2"/>
                                Terug
                            </Button>
                        )}
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto flex-grow-1 flex-row justify-content-end">
                    <NavbarAccountDropdown/>
                </ul>
            </div>
        </nav>
    );
}

export default React.memo(AccountNavbar);
