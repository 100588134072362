import React from "react";
import {
    Button
} from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";

function ProductLinkCard({ link, style, setLinkEnabled, onEdit }) {
    return (
        <div className="card mb-2" style={ style }>
            <div className="card-body">
                <Button variant="primary" size="sm" className="float-right ml-2" onClick={ () => window.open(link.url, "_blank").focus() }>
                    Link openen
                </Button>
                <Button variant="secondary" size="sm" className="float-right ml-2" onClick={ onEdit }>
                    <i className="fas fa-edit mr-2"/>
                    Bewerken
                </Button>
                <Button variant="secondary" size="sm" className="float-right" onClick={ setLinkEnabled.bind(this, link.id, !link.enabled) }>
                    <i className={ "mr-2 fas fa-" + (link.enabled ? "minus" : "plus") }/>
                    { link.enabled ? "Uitschakelen" : "Inschakelen" }
                </Button>
                <h5>{ link.title }</h5>
                <ReactMarkdown>
                    { link.description }
                </ReactMarkdown>
                <p className="mb-0 text-muted">
                    { link.url.trim().length === 0 ? (
                        <i>Geen link</i>
                    ) : (
                        <>Link: { link.url }</>
                    )}
                </p>
            </div>
        </div>
    )
}
ProductLinkCard.propTypes = {
    link: PropTypes.object,
    style: PropTypes.object,
    setLinkEnabled: PropTypes.func,
    onEdit: PropTypes.func
}

export default React.memo(ProductLinkCard);
