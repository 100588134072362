import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../../components/DetailOverviewRow";
import StatusPill from "../../../../components/pills/StatusPill";

function ShipmentDetailOverviewData({ shipment, onShowStatusHelp }) {
    return (
        <React.Fragment>
            <DetailOverviewRow title="Status">
                { shipment ? (
                    <React.Fragment>
                        <StatusPill status={ shipment.status }/>
                        <OverlayTrigger overlay={
                            <Tooltip id="status-help">Uitleg van status</Tooltip>
                        }>
                            <Button variant="link" className="py-0" onClick={ onShowStatusHelp }>
                                <i className="fas fa-question-circle"/>
                            </Button>
                        </OverlayTrigger>
                    </React.Fragment>
                ) : (
                    <Skeleton width={150}/>
                )}
            </DetailOverviewRow>
            { shipment && shipment.order.isCompany && (
                <DetailOverviewRow title="Bedrijf">
                    { shipment.order ? shipment.order.companyName : <Skeleton width={200}/> }
                </DetailOverviewRow>
            )}
            <DetailOverviewRow title={ shipment && shipment.order.invoiceEqualsDelivery ? "Aflever- en factuuradres" : "Afleveradres" }/>
            <DetailOverviewRow title="Naam">
                { shipment ? shipment.order.deliveryFirstName + " " + shipment.order.deliveryLastName : <Skeleton width={200}/> }
            </DetailOverviewRow>
            <DetailOverviewRow title="Adres">
                { shipment ? shipment.order.deliveryStreet + " " + shipment.order.deliveryHouseNumber : <Skeleton width={200}/> }
            </DetailOverviewRow>
            <DetailOverviewRow title="Stad">
                { shipment ? shipment.order.deliveryCity : <Skeleton width={200}/> }
            </DetailOverviewRow>
            <DetailOverviewRow title="Postcode">
                { shipment ? shipment.order.deliveryPostalCode : <Skeleton width={200}/> }
            </DetailOverviewRow>
            { shipment && !shipment.order.invoiceEqualsDelivery && (
                <React.Fragment>
                    <DetailOverviewRow title="Factuuradres"/>
                    <DetailOverviewRow title="Naam">
                        { shipment.order.invoiceFirstName + " " + shipment.order.invoiceLastName }
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Adres">
                        { shipment.order.invoiceStreet + " " + shipment.order.invoiceHouseNumber }
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Stad">
                        { shipment.order.invoiceCity }
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Postcode">
                        { shipment.order.invoicePostalCode }
                    </DetailOverviewRow>
                </React.Fragment>
            )}
            <DetailOverviewRow title="Contact informatie"/>
            <DetailOverviewRow title="Email">
                { shipment ? (
                    <a href={ "mailto:" + shipment.order.email }>
                        { shipment.order.email }
                    </a>
                ) : <Skeleton width={200}/> }
            </DetailOverviewRow>
            <DetailOverviewRow title="Telefoonnummer">
                { shipment ? (
                    <a href={ "dial:" + shipment.order.phoneNumber }>
                        { shipment.order.phoneNumber }
                    </a>
                ) : <Skeleton width={200}/> }
            </DetailOverviewRow>
            { shipment && shipment.myParcelConsignmentStatus && (
                <DetailOverviewRow title="MyParcel Status">
                    { shipment.myParcelConsignmentStatus.name }
                </DetailOverviewRow>
            )}
        </React.Fragment>
    );
}
ShipmentDetailOverviewData.propTypes = {
    shipment: PropTypes.object,
    onShowStatusHelp: PropTypes.func.isRequired
};

export default React.memo(ShipmentDetailOverviewData);
