import React from "react";
import PropTypes from "prop-types";

import Loading from "../../../../components/Loading";
import ProductImageDataCard from "../../../../components/ProductImageDataCard";

function ShipmentProductList({ shipment }) {
    if(!shipment) {
        return (
            <Loading/>
        );
    }
    return shipment.products.map((product) => {
        const image = product.images.length === 0 ? null : product.images[0].alternatives["175x130"];
        return (
            <div className="mb-2" key={ product.id }>
                <ProductImageDataCard image={ image }>
                    <h5 className="card-title">
                        { product.name }
                    </h5>
                    <p className="card-text mr-2">
                        <b>
                            { product.amount }x
                        </b>
                    </p>
                </ProductImageDataCard>
            </div>
        )
    })
}
ShipmentProductList.propTypes = {
    shipment: PropTypes.object
}

export default React.memo(ShipmentProductList);
