import React from "react";
import axios from "axios";
import ReactRouterPropTypes from "react-router-prop-types";
import {
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import Helmet from "../../components/Helmet";
import ProductForm from "./components/ProductForm";
import ProductTypePicker from "./components/ProductTypePicker";

class NewProduct extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            productType: "",
            name: "",
            subtitle: "",
            url: "",
            orderable: true,
            preorder: false,
            visible: true,
            releaseDate: null,
            price: 0,
            taxPercentage: 0.21,
            enableDiscountPrice: false,
            discountPrice: 0,
            description: "",
            summary: "",

            error: null,
            success: false
        }
        this.addProduct = this.addProduct.bind(this);
        this.onChange = this.onChange.bind(this);
        this.simpleMdeOptions = {
            spellChecker: false
        };
    }

    getProductUrl() {
        if(this.state.productType.length === 0) {
            return "";
        }
        const folder = this.state.productType === "rental" ? "rental" : "product";
        return this.context.currentShop.url + "/" + folder + "/x/" + this.state.url;
    }

    addProduct() {
        this.setState({ success: false });
        if(!this.validType()) {
            this.setState({ error: "Ongeldig product type geselecteerd." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(this.state.name.trim().length === 0) {
            this.setState({ error: "Naam is vereist." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(this.state.url.trim().length === 0) {
            this.setState({ error: "Pagina URL is vereist." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(this.state.taxPercentage < 0 || this.state.taxPercentage >= 100) {
            this.setState({ error: "Ongeldig BTW percentage." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(this.state.enableDiscountPrice && parseFloat(this.state.discountPrice) >= parseFloat(this.state.price)) {
            this.setState({ error: "Kortingsprijs kan niet hoger zijn dan de reguliere prijs." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        this.setState({ error: null, loading: true });
        axios.post("/addProduct", {
            shopId: this.context.currentShop.id,
            type: this.state.productType,
            name: this.state.name.trim(),
            subtitle: this.state.subtitle.trim(),
            url: this.state.url.trim(),
            orderable: this.state.orderable ? 1 : 0,
            preorder: this.state.preorder ? 1 : 0,
            visible: this.state.visible ? 1 : 0,
            releaseDate: this.state.releaseDate,
            price: parseFloat(this.state.price),
            discountPrice: this.state.enableDiscountPrice ? parseFloat(this.state.discountPrice) : "null",
            taxPercentage: parseFloat(this.state.taxPercentage),
            description: this.state.description,
            summary: this.state.summary
        })
            .then((response) => {
                if(response.data.valid) {
                    let productId = response.data.product.id;
                    this.props.history.push("/shop/" + this.context.currentShop.codeName + "/product/" + productId);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")", loading: false });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw.", loading: false });
            })
            .finally(() => {
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    onChange(newState) {
        this.setState(newState);
    }

    validType() {
        switch(this.state.productType) {
            case "physical":
            case "digital":
            case "rental":
            case "course":
                return true;
            default:
                return false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet title="Product toevoegen"/>
                <Title preTitle="Overzicht">
                    Product toevoegen
                </Title>
                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}
                <div className="card mb-3">
                    <div className="card-body">
                        <h4>Type product</h4>
                        <ProductTypePicker
                            productType={ this.state.productType }
                            setProductType={ (productType) => this.setState({ productType }) }
                        />
                    </div>
                </div>
                { this.validType() && (
                    <ProductForm
                        state={ this.state }
                        onChange={ this.onChange }
                        loading={ this.state.loading }
                        onSave={ this.addProduct }
                    />
                )}
            </React.Fragment>
        )
    }
}
NewProduct.contextType = ShopsContext;
NewProduct.propTypes = {
    history: ReactRouterPropTypes.history.isRequired
}

export default withRouter(NewProduct);
