import React, {
    useCallback,
    useContext,
    useState
} from "react";
import axios from "axios";
import {
    useHistory
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import Helmet from "../../components/Helmet";
import CourseForm from "./components/CourseForm";
import validateCourseFormInput from "./functions/validateCourseFormInput";

function NewCourse() {
    const shopsContext = useContext(ShopsContext);
    const history = useHistory();
    const [course, setCourse] = useState({
        title: "",
        maxAccessDuration: 365,
        description: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onSave = useCallback(async () => {
        const validationError = validateCourseFormInput(course);
        if(validationError) {
            setError(validationError);
            return;
        }
        setError(null);
        setLoading(true);
        try {
            const response = await axios.post("/addCourse", {
                shopId: shopsContext.currentShop.id,
                title: course.title,
                maxAccessDuration: parseInt(course.maxAccessDuration, 10),
                description: course.description,
            });
            const newCourse = response.data.course;
            history.push("/shop/" + shopsContext.currentShop.codeName + "/course/" + newCourse.id);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
            setLoading(false);
        }
    }, [course, history]);

    return (
        <React.Fragment>
            <Helmet title="Cursus toevoegen"/>
            <Title preTitle="Overzicht">
                Cursus toevoegen
            </Title>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <CourseForm
                course={ course }
                setCourse={ setCourse }
                loading={ loading }
                onSubmit={ onSave }
            />
        </React.Fragment>
    )
}

export default React.memo(NewCourse);
