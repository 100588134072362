import React, {
    useMemo
} from "react";
import PropTypes from "prop-types";

import PriceFormatter from "./priceFormatter";
import isString from "../utilities/isString";

function PriceDisplay({ amount = 1, price, originalPrice, stacked = false }) {
    const difference = useMemo(() => {
        const originalPriceStr = isString(originalPrice) ? originalPrice : originalPrice?.toFixed(2);
        const currentPriceStr = isString(price) ? price : price?.toFixed(2);
        return originalPriceStr !== currentPriceStr;
    }, [originalPrice, price]);

    return (
        <React.Fragment>
            { difference && (
                <React.Fragment>
                    <s
                        className={ "text-muted" + (stacked ? "" : " mr-1") }
                        style={{ fontSize: "0.9rem" }}
                    >
                        <PriceFormatter price={ amount * originalPrice }/>
                    </s>
                    { stacked && (
                        <br/>
                    )}
                </React.Fragment>
            )}
            <b>
                <PriceFormatter price={ amount * price }/>
            </b>
        </React.Fragment>
    )
}
PriceDisplay.propTypes = {
    amount: PropTypes.number,
    price: PropTypes.number,
    originalPrice: PropTypes.number,
    stacked: PropTypes.bool
};

export default React.memo(PriceDisplay);
