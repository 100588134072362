import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import ShopsContext from "../../../context/ShopsContext";
import Loading from "../../../components/Loading";
import OAuthConnectionCard from "../modal/OAuthConnectionCard";

function SettingsMollie() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [saveError, setSaveError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [settings, setSettings] = useState(null);
    const [editedSettings, setEditedSettings] = useState(null);
    const [madeChanges, setMadeChanges] = useState(false);

    const shops = useContext(ShopsContext);

    const mollieEnabled = useMemo(() => {
        const currentShop = shops.currentShop;
        if(!currentShop) {
            return false;
        }
        return currentShop.paymentPlatforms.includes("mollie");
    }, [shops]);
    useEffect(() => {
        if(!mollieEnabled) {
            return;
        }
        setError(null);
        setSettings(null);
        setEditedSettings(null);
        setMadeChanges(false);
        setSuccess(null);
        axios.post("/oauthMollieSetupGetSettings", { shopId: shops.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    setSettings(response.data);
                    setEditedSettings(response.data);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((setupError) => {
                console.error(setupError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            });
    }, [mollieEnabled]);

    const didClickSetup = useMemo(() => {
        return () => {
            setLoading(true);
            setError(null);
            setSuccess(null);
            axios.post("/oauthMollieSetup", { shopId: shops.currentShop.id })
                .then((response) => {
                    if(response.data.valid) {
                        window.location = response.data.oauthUrl;
                    } else {
                        setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                        setLoading(false);
                    }
                })
                .catch((setupError) => {
                    console.error(setupError);
                    setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                    setLoading(false);
                });
        }
    }, []);
    const didClickRemove = useMemo(() => {
        return (completionHandler) => {
            setLoading(true);
            setError(null);
            setSuccess(null);
            axios.post("/oauthMollieSetupRemove", { shopId: shops.currentShop.id })
                .then((response) => {
                    if(response.data.valid) {
                        shops.refreshShopsNoClear();
                    } else {
                        setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                    }
                })
                .catch((setupError) => {
                    console.error(setupError);
                    setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                })
                .finally(() => {
                    setLoading(false);
                    completionHandler();
                });
        }
    }, []);

    const onChangeSettings = useMemo(() => {
        return (changes) => {
            setMadeChanges(true);
            setSuccess(null);
            setEditedSettings((prevSettings) => {
                return { ...prevSettings, ...changes };
            });
        }
    }, []);
    const onClickSave = useMemo(() => {
        return () => {
            const {
                currentProfileId,
                testMode
            } = editedSettings;
            if(!currentProfileId || currentProfileId === "invalid") {
                setSaveError("Ongeldig websiteprofiel geselecteerd.");
                return;
            }
            setLoading(true);
            setSaveError(null);
            setSuccess(null);
            axios.post("/oauthMollieSetupSetSettings", {
                shopId: shops.currentShop.id,
                currentProfileId,
                testMode: testMode ? 1 : 0
            })
                .then((response) => {
                    if(response.data.valid) {
                        setSettings(editedSettings);
                        setMadeChanges(false);
                        shops.refreshShopsNoClear();
                        setSuccess("Wijzigingen opgeslagen!");
                    } else {
                        setSaveError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                    }
                })
                .catch((setupError) => {
                    console.error(setupError);
                    setSaveError("Er is een fout opgetreden. Probeer het later opnieuw.");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [editedSettings]);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { success && (
                <Alert variant="success">{ success }</Alert>
            )}
            <OAuthConnectionCard
                service="Mollie"
                enabled={ mollieEnabled }
                onClickSetup={ didClickSetup }
                onClickRemove={ settings && settings.mode === "oauth" && didClickRemove }
                loading={ loading }
            />
            { settings && settings.mode === "oauth" && !settings.currentProfileId && (
                <Alert variant="warning">
                    Een aantal vereiste instellingen zijn niet ingesteld! Er kunnen nog geen Mollie betalingen
                    uitgevoerd worden!
                </Alert>
            )}
            { !mollieEnabled || error ? null : !settings || !editedSettings ? (
                <Skeleton height={ 240 }/>
            ) : editedSettings.mode === "oauth" && (
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Mollie instellingen</h5>
                        { saveError && (
                            <Alert variant="danger">{ saveError }</Alert>
                        )}
                        { !editedSettings ? (
                            <Loading/>
                        ) : editedSettings && (
                            <React.Fragment>
                                <div className="form-group">
                                    <label htmlFor="profile">Websiteprofiel</label>
                                    <select
                                        className="form-control"
                                        id="profile"
                                        value={ editedSettings.currentProfileId ? editedSettings.currentProfileId : "invalid" }
                                        onChange={ (event) => onChangeSettings({ currentProfileId: event.target.value }) }
                                        disabled={ loading }
                                    >
                                        { !settings.currentProfileId && (
                                            <option value="invalid"/>
                                        )}
                                        { settings.profiles.map((profile) => (
                                            <option key={ profile.id } value={ profile.id }>
                                                { profile.name } - { profile.website }
                                            </option>
                                        ))}
                                    </select>
                                    <a
                                        href="https://my.mollie.com/dashboard/settings/profiles"
                                        target="_blank"
                                        rel="nofollow noreferrer"
                                        className="btn btn-link px-0"
                                        style={{ fontSize: "0.9rem" }}
                                    >
                                        Websiteprofielen beheren via Mollie Dashboard
                                        <i className="fas fa-external-link-alt ml-1" style={{ fontSize: "0.75rem" }}/>
                                    </a>
                                </div>
                                <div className="custom-control custom-checkbox mb-3">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="testMode"
                                        checked={ editedSettings.testMode }
                                        onChange={ (event) => onChangeSettings({ testMode: event.target.checked }) }
                                        disabled={ loading }
                                    />
                                    <label className="custom-control-label" htmlFor="testMode">
                                        Test modus
                                    </label>
                                </div>
                                <Button variant="primary" disabled={ !madeChanges || loading } onClick={ onClickSave }>
                                    { loading && (
                                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                                    )}
                                    Opslaan
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default React.memo(SettingsMollie);
