import React, {
    useContext,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import ShopsContext from "../../../context/ShopsContext";
import DetailOverviewRow from "../../../components/DetailOverviewRow";
import RegisterMyParcelConsignment from "./modal/RegisterMyParcelConsignment";
import CreateShipmentLabelModal from "./modal/CreateShipmentLabelModal";
import ShipmentStatusHelpModal from "./modal/ShipmentStatusHelpModal";
import ShipmentProductList from "./components/ShipmentProductList";
import ManuallySendShipmentModal from "./modal/ManuallySendShipmentModal";
import ShipmentDetailOverviewData from "./components/ShipmentDetailOverviewData";

function ShipmentDetailOverview({ shipment, setShipment, error }) {
    const shopsContext = useContext(ShopsContext);
    const [showRegisterMyParcelConsignmentModal, setShowRegisterMyParcelConsignmentModal] = useState(false);
    const [showMyParcelConsignmentLabelModal, setShowMyParcelConsignmentLabelModal] = useState(false);
    const [showStatusHelpModal, setShowStatusHelpModal] = useState(false);
    const [showManuallySendShipmentModal, setShowManuallySendShipmentModal] = useState(false);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    return (
        <React.Fragment>
            { shipment && (
                <React.Fragment>
                    <RegisterMyParcelConsignment
                        show={ showRegisterMyParcelConsignmentModal }
                        shipment={ shipment }
                        handleClose={ () => setShowRegisterMyParcelConsignmentModal(false) }
                        consignmentCreated={ (newShipment) => {
                            setShipment(newShipment);
                            setShowRegisterMyParcelConsignmentModal(false);
                        } }
                    />
                    <CreateShipmentLabelModal
                        show={ showMyParcelConsignmentLabelModal }
                        handleClose={ () => setShowMyParcelConsignmentLabelModal(false) }
                        shipment={ shipment }
                    />
                    <ShipmentStatusHelpModal
                        show={ showStatusHelpModal }
                        handleClose={ () => setShowStatusHelpModal(false) }
                    />
                    <ManuallySendShipmentModal
                        show={ showManuallySendShipmentModal }
                        handleClose={ () => setShowManuallySendShipmentModal(false) }
                        shipment={ shipment }
                        onSaved={ setShipment }
                    />
                </React.Fragment>
            )}
            <div className="card">
                <div className="card-body">
                    <table className="table table-borderless mb-0">
                        <tbody>
                        <ShipmentDetailOverviewData
                            shipment={ shipment }
                            onShowStatusHelp={ () => setShowStatusHelpModal(true) }
                        />
                        <DetailOverviewRow title="">
                            { shipment ? (
                                <React.Fragment>
                                    <Link
                                        to={ "/shop/" + shopsContext.currentShop.codeName + "/order/" + shipment.order.id }
                                        className="btn btn-secondary mr-2"
                                    >
                                        Bekijk bestelling
                                    </Link>
                                    { shipment.myParcelConsignmentId ? (
                                        <Button
                                            variant="primary"
                                            onClick={ () => setShowMyParcelConsignmentLabelModal(true) }
                                        >
                                            Verzendlabel downloaden
                                        </Button>
                                    ) : !shipment.status.sent ? (
                                        <React.Fragment>
                                            <Button
                                                variant="primary"
                                                onClick={ () => setShowRegisterMyParcelConsignmentModal(true) }
                                                className="mr-2"
                                            >
                                                Verzending registreren
                                            </Button>
                                            <Button
                                                variant="secondary"
                                                onClick={ () => setShowManuallySendShipmentModal(true) }
                                            >
                                                Handmatig verzenden
                                            </Button>
                                        </React.Fragment>
                                    ) : (
                                        <Button
                                            variant="secondary"
                                            onClick={ () => setShowManuallySendShipmentModal(true) }
                                        >
                                            Handmatig verzonden ongedaan maken
                                        </Button>
                                    )}
                                </React.Fragment>
                            ) : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        </tbody>
                    </table>
                </div>
            </div>
            <h4 className="mt-4">Producten</h4>
            <ShipmentProductList shipment={ shipment }/>
        </React.Fragment>
    );
}
ShipmentDetailOverview.propTypes = {
    shipment: PropTypes.object,
    setShipment: PropTypes.func.isRequired,
    error: PropTypes.string
}

export default ShipmentDetailOverview;
