import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";

import ShopsContext from "../../../context/ShopsContext";

function SettingsOverview() {
    const shops = useContext(ShopsContext);
    const [settings, setSettings] = useState(null);
    const [countries, setCountries] = useState(null);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(!shops?.currentShop) {
            return;
        }
        setSettings({
            shopBusinessName: shops?.currentShop?.shopBusinessName ?? "",
            shopStreet: shops?.currentShop?.shopStreet ?? "",
            shopHouseNumber: shops?.currentShop?.shopHouseNumber ?? "",
            shopPostalCode: shops?.currentShop?.shopPostalCode ?? "",
            shopCity: shops?.currentShop?.shopCity ?? "",
            shopState: shops?.currentShop?.shopState ?? "",
            shopCountry: shops?.currentShop?.shopCountry?.iso ?? "",
            shopEmail: shops?.currentShop?.shopEmail ?? "",
            shopPhoneNumber: shops?.currentShop?.shopPhoneNumber ?? "",
            shopBusinessNumber: shops?.currentShop?.shopBusinessNumber ?? "",
            shopVATNumber: shops?.currentShop?.shopVATNumber ?? "",
        });
    }, [shops]);

    useEffect(() => {
        setCountries(null);
        axios.get("/getCountries")
            .then((response) => {
                setCountries(response.data.countries);
            })
            .catch((requestError) => {
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                console.error(requestError);
            });
    }, []);

    const onChange = useCallback((event) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [event.target.name]: event.target.value
        }));
    }, [settings]);

    const onSave = useCallback(async () => {
        setSaving(true);
        setError(null);
        try {
            await axios.post("/setShopSettings", {
                ...settings,
                shopId: shops.currentShop.id
            });
            shops.refreshShops(false);
        } catch(requestError) {
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            console.error(requestError);
        } finally {
            setSaving(false);
        }
    }, [settings, shops]);

    const disabled = useMemo(() => {
        return !settings || !countries || saving;
    }, [settings, countries, saving]);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Bedrijfsgegevens</h5>
                    <div className="form-group">
                        <label htmlFor="shopBusinessName">Bedrijfsnaam</label>
                        <input
                            type="text"
                            className="form-control"
                            id="shopBusinessName"
                            name="shopBusinessName"
                            value={settings?.shopBusinessName ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="shopBusinessNumber">KvK nummer</label>
                        <input
                            type="text"
                            className="form-control"
                            id="shopBusinessNumber"
                            name="shopBusinessNumber"
                            value={settings?.shopBusinessNumber ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="shopVATNumber">BTW nummer</label>
                        <input
                            type="text"
                            className="form-control"
                            id="shopVATNumber"
                            name="shopVATNumber"
                            placeholder="NL000000000B01"
                            value={settings?.shopVATNumber ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Adresgegevens</h5>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label htmlFor="shopStreet">Straat</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="shopStreet"
                                    name="shopStreet"
                                    value={settings?.shopStreet ?? ""}
                                    onChange={onChange}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="shopHouseNumber">Huisnummer + toevoeging</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="shopHouseNumber"
                                    name="shopHouseNumber"
                                    value={settings?.shopHouseNumber ?? ""}
                                    onChange={onChange}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="shopStreet">Postcode</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="shopPostalCode"
                                    name="shopPostalCode"
                                    value={settings?.shopPostalCode ?? ""}
                                    onChange={onChange}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="shopCity">Stad</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="shopCity"
                                    name="shopCity"
                                    value={settings?.shopCity ?? ""}
                                    onChange={onChange}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="shopState">Staat / Provincie</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="shopState"
                                    name="shopState"
                                    value={settings?.shopState ?? ""}
                                    onChange={onChange}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="shopCountry">Land</label>
                                <select
                                    className="form-control"
                                    id="shopCountry"
                                    name="shopCountry"
                                    value={settings?.shopCountry ?? ""}
                                    onChange={onChange}
                                    disabled={disabled}
                                >
                                    <option value="">Selecteer een land</option>
                                    { countries && countries.map((country) => (
                                        <option key={country.iso} value={country.iso}>{country.nameEnglish}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Contactgegevens</h5>
                    <div className="form-group">
                        <label htmlFor="shopEmail">E-mailadres</label>
                        <input
                            type="email"
                            className="form-control"
                            id="shopEmail"
                            name="shopEmail"
                            value={settings?.shopEmail ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="shopPhoneNumber">Telefoonnummer</label>
                        <input
                            type="tel"
                            className="form-control"
                            id="shopPhoneNumber"
                            name="shopPhoneNumber"
                            value={settings?.shopPhoneNumber ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button
                    variant="primary"
                    onClick={onSave}
                    disabled={disabled}
                >
                    Opslaan
                </Button>
            </div>
        </React.Fragment>
    );
}

export default React.memo(SettingsOverview);
