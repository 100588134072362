import React from "react";
import * as Sentry from "@sentry/react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch,
    withRouter
} from "react-router-dom";
import {
    CSSTransition
} from "react-transition-group";
import ReactRouterPropTypes from "react-router-prop-types";

import {
    ShopsContextPropTypes,
    withShopsContext
} from "./context/ShopsContext";
import AuthenticatedUserContext from "./context/AuthenticatedUserContext";
import PrivateRoute from "./components/PrivateRoute";
import Logo from "./components/Logo";

import Login from "./pages/Login";
import OTPVerification from "./pages/OTPVerification";
import Shops from "./pages/Shops";
import BackofficeRouter from "./BackofficeRouter";
import OAuthSwitch from "./pages/oauth/OAuthSwitch";
import Account from "./pages/account/Account";

class AuthenticationManager extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showWhiteOverlayOverride: false,
            showInitialLoadingIndicator: false,
            authenticatedUser: {
                user: undefined,
                setUser: this.setUser.bind(this),
                login: this.login.bind(this),
                logout: this.logout.bind(this),
                otpVerification: this.otpVerification.bind(this),
                navigationTransition: this.navigationTransition.bind(this),

                loginFunction: this.login.bind(this),
                logoutFunction: this.logout.bind(this)
            }
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.state.authenticatedUser.user === undefined) {
                this.setState({ showInitialLoadingIndicator: true });
            }
        }, 100);
        this.getSession();
    }

    setUser(user) {
        if(user) {
            console.log("Logged in as user " + user.name + ".");
            Sentry.setUser({
                id: user.id,
                name: user.name,
                email: user.email
            });
        }
        this.setState((previousState) => {
            return { authenticatedUser: { ...previousState.authenticatedUser, user } }
        });
    }

    navigationTransition(to) {
        this.showWhiteOverlayOverridden(true);
        setTimeout(() => {
            this.props.history.push(to);
            this.showWhiteOverlayOverridden(false);
        }, 500);
    }

    showWhiteOverlayOverridden(value, stateCheck = () => { return false }) {
        this.setState(((prevState) => {
            if(!prevState.showWhiteOverlayOverride) {
                return {
                    showWhiteOverlayOverride: value,
                    showInitialLoadingIndicator: false
                };
            }
            return {
                showWhiteOverlayOverride: value
            };
        }));
        if(value) {
            setTimeout(() => {
                if(stateCheck()) {
                    this.setState({ showInitialLoadingIndicator: true });
                }
            }, 100);
        }
    }

    login(state, onErrorOccurred) {
        const email = state.email;
        const password = state.password;

        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user === null });
        axios.post("/login", { email, password, useCookie: true, deviceType: "desktop" })
            .then((response) => {
                if(response.data.valid) {
                    setTimeout(() => {
                        this.setUser(response.data.user);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        if(response.data.error === "NEEDS_OTP_VERIFICATION") {
                            this.props.history.push("/otp-verification");
                        } else if(response.data.error === "INVALID_CREDENTIALS") {
                            onErrorOccurred("Foutieve gebruikersnaam of wachtwoord.");
                        } else {
                            onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                        }
                    }, 1000);
                }
            })
            .catch(() => {
                setTimeout(() => {
                    onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                }, 1000);
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 1000);
            });
    }

    otpVerification(otp, onErrorOccurred) {
        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user === null });
        axios.post("/verifyOTP", { otp, useCookie: true, deviceType: "desktop" })
            .then((response) => {
                if(response.data.valid) {
                    setTimeout(() => {
                        this.setUser(response.data.user);
                    }, 1000);
                } else {
                    setTimeout(() => {
                        if(response.data.error === "INVALID_OTP") {
                            onErrorOccurred("Foutieve 2FA code.");
                        } else {
                            onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                        }
                    }, 1000);
                }
            })
            .catch(() => {
                setTimeout(() => {
                    onErrorOccurred("Er ging iets fout. Probeer het later opnieuw.");
                }, 1000);
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 1000);
            });
    }

    getSession() {
        axios.get("/getSession")
            .then((response) => {
                if(response.data.valid) {
                    this.setUser(response.data.user);
                } else {
                    this.setUser(null);
                    if(response.data.error === "NEEDS_OTP_VERIFICATION") {
                        this.props.history.push("/otp-verification");
                    } else {
                        this.props.history.push("/login");
                    }
                }
            })
            .catch(() => {
                this.setUser(null);
            });
    }

    logout() {
        this.showWhiteOverlayOverridden(true, () => { return this.state.authenticatedUser.user !== null });
        axios.get("/logout")
            .then((response) => {
                if(response.data.valid) {
                    setTimeout(() => {
                        this.setUser(null);
                        this.props.history.push("/login");
                    }, 500);
                } else {
                    // TODO: Display pop-over error message.
                }
            })
            .catch(() => {
                // TODO: Display pop-over error message.
            })
            .finally(() => {
                setTimeout(() => {
                    this.showWhiteOverlayOverridden(false);
                }, 500);
            });
    }

    render() {
        return (
            <React.Fragment>
                <CSSTransition in={ this.state.authenticatedUser.user === undefined || this.state.showWhiteOverlayOverride || (this.state.authenticatedUser.user && this.props.shopsContext.shops === null) }
                               timeout={500} classNames="initial-load-background" unmountOnExit>
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#F9FBFD",
                        zIndex: 1500
                    }}>
                        <CSSTransition in={ this.state.showInitialLoadingIndicator } timeout={500} classNames="initial-load-logo" appear={true} mountOnEnter>
                            <React.Fragment>
                                <Logo style={{ maxHeight: "132px" }} className="d-none d-md-block"/>
                                <Logo style={{ maxHeight: "132px" }} className="d-md-none" icon/>
                            </React.Fragment>
                        </CSSTransition>
                    </div>
                </CSSTransition>

                { this.state.authenticatedUser.user !== undefined && (
                    <AuthenticatedUserContext.Provider value={ this.state.authenticatedUser }>
                        <Switch>

                            <PrivateRoute authenticated={ this.state.authenticatedUser.user === null }
                                          path="/login" target="/shops"
                                          component={ Login }
                            />

                            <PrivateRoute authenticated={ this.state.authenticatedUser.user === null }
                                          path="/otp-verification" target="/shops"
                                          component={ OTPVerification }
                            />

                            <PrivateRoute authenticated={ this.state.authenticatedUser.user !== null }
                                          path="/" target="/login"
                            >
                                <Switch>
                                    <Route path="/shops" component={ Shops }/>
                                    <Route path="/shop/:shopCodeName" component={ BackofficeRouter }/>
                                    <Route path="/oauth" component={ OAuthSwitch }/>
                                    <Route path="/account" component={ Account }/>
                                    <Route path="/">
                                        <Redirect to="/shops"/>
                                    </Route>
                                </Switch>
                            </PrivateRoute>

                        </Switch>
                    </AuthenticatedUserContext.Provider>
                )}
            </React.Fragment>
        );
    }
}
AuthenticationManager.propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    shopsContext: ShopsContextPropTypes
}

export default withRouter(withShopsContext(AuthenticationManager));
