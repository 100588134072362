import React from "react";
import PropTypes from "prop-types";

import ProductImage from "./ProductImage";

function ProductImageDataCard({ image, children }) {
    return (
        <div className="d-flex flex-row w-100">
            <div className="mr-2">
                <div className="product-image-container">
                    <ProductImage image={ image }/>
                </div>
            </div>
            <div className="card flex-grow-1">
                <div className="card-body">
                    { children }
                </div>
            </div>
        </div>
    )
}
ProductImageDataCard.propTypes = {
    image: PropTypes.object,
    children: PropTypes.element.isRequired
}

export default React.memo(ProductImageDataCard);
