import React, {
    useCallback,
    useContext,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";
import {
    useLocalStorage
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../../../context/ShopsContext";
import ShipmentPlatformLogo from "../../../../components/integration/ShipmentPlatformLogo";

function CreateShipmentLabelModal({ show, handleClose, shipment }) {
    const shopsContext = useContext(ShopsContext);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [pageSize, setPageSize] = useLocalStorage("labelLastPageSize", "a4");

    const onShow = useCallback(() => {
        setError(null);
        setLoading(false);
        setPageSize(localStorage.getItem("labelLastPageSize") || "a4");
    }, []);

    const getShipmentLabel = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.post("/getShipmentLabel", {
                shopId: shopsContext.currentShop.id,
                shipmentId: shipment.id,
                pageSize
            });
            document.location.href = response.data.label;
        } catch(requestError) {
            const errorCode = requestError?.response.data?.error;
            switch(errorCode) {
                case "MYPARCEL_ACCOUNT_NOT_ACTIVE":
                    setError("MyParcel account is niet geactiveerd. Alleen dan kunnen verzendingen geregistreerd worden.");
                    break;
                case "MYPARCEL_NOT_SETUP":
                    setError("MyParcel API key is niet ingesteld.");
                    break;
                default:
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                    break;
            }
        } finally {
            setLoading(false);
        }
    }, [shipment, pageSize, shopsContext]);

    return (
        <Modal show={show} onHide={handleClose} onShow={onShow}>
            <Modal.Header closeButton>
                <Modal.Title>Verzendlabel downloaden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center mb-3 py-2">
                    <ShipmentPlatformLogo
                        shipmentPlatform={ shipment.shipmentPlatform === undefined ? "myparcel" : shipment.shipmentPlatform }
                        style={{ width: "250px" }}
                    />
                </div>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}

                <Form.Check
                    type="radio" custom checked={pageSize === "a4"}
                    id="a4"
                    label="Standaard printer (A4)"
                    onChange={(event) => {
                        if (!event.target.checked) {
                            return;
                        }
                        setPageSize("a4");
                    }}
                />
                <Form.Check
                    type="radio" custom checked={pageSize === "a6" }
                    id="a6"
                    label="Labelprinter (A6)"
                    onChange={(event) => {
                        if (!event.target.checked) {
                            return;
                        }
                        setPageSize("a6");
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    disabled={loading}
                >
                    Annuleer
                </Button>
                <Button
                    variant="primary"
                    onClick={getShipmentLabel}
                    disabled={loading}
                >
                    { loading && (
                        <Spinner animation="border" size="sm" className="mr-2" />
                    )}
                    Label downloaden
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
CreateShipmentLabelModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    shipment: PropTypes.object.isRequired
}

export default React.memo(CreateShipmentLabelModal);
