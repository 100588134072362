import React from "react";
import PropTypes from "prop-types";

function DetailOverviewRow({ title, children }) {
    return (
        <tr>
            <th className="detail-header-cell">{ title }</th>
            <td className="detail-value-cell">{ children }</td>
        </tr>
    )
}
DetailOverviewRow.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
    ])
}

export default React.memo(DetailOverviewRow);
