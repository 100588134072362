import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import {
    ShopsContextPropTypes,
    withShopsContext
} from "../../../context/ShopsContext";
import DetailOverviewRow from "../../../components/DetailOverviewRow";
import Loading from "../../../components/Loading";
import StatusPill from "../../../components/pills/StatusPill";
import ResendDigitalProductsLinkEmailModal from "./modal/ResendDigitalProductsLinkEmailModal";
import CreateInvoiceModal from "./modal/CreateInvoiceModal";
import OrderDetailOverviewActionButtons from "./components/OrderDetailOverviewActionButtons";
import OrderDetailProductsList from "./components/OrderDetailProductsList";
import OrderDetailPriceSummary from "./components/OrderDetailPriceSummary";
import ResendInvoiceModal from "./modal/ResendInvoiceModal";

class OrderDetailOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showResendDigitalProductsLinkEmailModal: false,
            showResendInvoiceModal: false,
            showCreateInvoiceModal: false,
            error: null
        }
        this.getOrderInvoice = this.getOrderInvoice.bind(this);
    }

    getOrderInvoice() {
        this.setState({ error: null });
        axios.post("/getOrderInvoice", {
            orderId: this.props.order.id,
            shopId: this.props.shopsContext.currentShop.id
        })
            .then((response) => {
                if(response.data.valid) {
                    window.location.href = response.data.invoiceUrl;
                } else {
                    this.setState({ error: "Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    // eslint-disable-next-line complexity
    render() {
        const {
            order,
            orderId,
            refreshOrder
        } = this.props;
        const {
            showResendDigitalProductsLinkEmailModal,
            showResendInvoiceModal,
            showCreateInvoiceModal,
            error
        } = this.state;
        return (
            <React.Fragment>
                <ResendDigitalProductsLinkEmailModal
                    show={ showResendDigitalProductsLinkEmailModal }
                    handleClose={ () => this.setState({ showResendDigitalProductsLinkEmailModal: false }) }
                    orderId={ orderId }
                />
                <CreateInvoiceModal
                    show={ showCreateInvoiceModal }
                    handleClose={ () => this.setState({ showCreateInvoiceModal: false }) }
                    orderId={ orderId }
                    refreshOrder={ refreshOrder }
                />
                <ResendInvoiceModal
                    show={ showResendInvoiceModal }
                    handleClose={ () => this.setState({ showResendInvoiceModal: false }) }
                    order={ order }
                />

                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}

                <div className="card">
                    <div className="card-body">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            <DetailOverviewRow title="Status">
                                { order ? (
                                    <StatusPill status={ order.status }/>
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            { order && order.isCompany && (
                                <DetailOverviewRow title="Bedrijf">
                                    { order ? order.companyName : <Skeleton width={200}/> }
                                </DetailOverviewRow>
                            )}
                            <DetailOverviewRow title={ order && order.invoiceEqualsDelivery ? "Aflever- en factuuradres" : "Afleveradres" }/>
                            <DetailOverviewRow title="Naam">
                                { order ? order.deliveryFirstName + " " + order.deliveryLastName : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Adres">
                                { order ? order.deliveryStreet + " " + order.deliveryHouseNumber : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Stad">
                                { order ? order.deliveryCity : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Postcode">
                                { order ? order.deliveryPostalCode : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            { order && order.deliveryState && (
                                <DetailOverviewRow title="Staat / Provincie">
                                    { order.deliveryState }
                                </DetailOverviewRow>
                            )}
                            { order && order.deliveryCountry && (
                                <DetailOverviewRow title="Land">
                                    { order.deliveryCountry.nameEnglish }
                                </DetailOverviewRow>
                            )}
                            { order && !order.invoiceEqualsDelivery && (
                                <React.Fragment>
                                    <DetailOverviewRow title="Factuuradres"/>
                                    <DetailOverviewRow title="Naam">
                                        { order.invoiceFirstName + " " + order.invoiceLastName }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Adres">
                                        { order.invoiceStreet + " " + order.invoiceHouseNumber }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Stad">
                                        { order.invoiceCity }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Postcode">
                                        { order.invoicePostalCode }
                                    </DetailOverviewRow>
                                    { order.invoiceState && (
                                        <DetailOverviewRow title="Staat / Provincie">
                                            { order.invoiceState }
                                        </DetailOverviewRow>
                                    )}
                                    { order.invoiceCountry && (
                                        <DetailOverviewRow title="Land">
                                            { order.invoiceCountry.nameEnglish }
                                        </DetailOverviewRow>
                                    )}
                                </React.Fragment>
                            )}
                            <DetailOverviewRow title="Contactinformatie"/>
                            <DetailOverviewRow title="Email">
                                { order ? (
                                    <a href={ "mailto:" + order.email }>
                                        { order.email }
                                    </a>
                                ) : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Telefoonnummer">
                                { order ? (
                                    <a href={ "dial:" + order.phoneNumber }>
                                        { order.phoneNumber }
                                    </a>
                                ) : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            { order && (
                                <OrderDetailOverviewActionButtons
                                    order={ order }
                                    onResendDigitalProductsLinkEmail={ () => this.setState({ showResendDigitalProductsLinkEmailModal: true }) }
                                    onOpenInvoice={ this.getOrderInvoice }
                                    onResendInvoice={ () => this.setState({ showResendInvoiceModal: true }) }
                                    onCreateInvoice={ () => this.setState({ showCreateInvoiceModal: true }) }
                                />
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <h4 className="mt-4">Producten</h4>
                { !order ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <OrderDetailProductsList
                            order={ order }
                        />
                        <OrderDetailPriceSummary
                            order={ order }
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
OrderDetailOverview.propTypes = {
    orderId: PropTypes.number.isRequired,
    order: PropTypes.object,
    shopsContext: ShopsContextPropTypes,
    refreshOrder: PropTypes.func.isRequired
}

export default withShopsContext(OrderDetailOverview);
