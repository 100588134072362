import React, {
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";

import {
    ShopsContextPropTypes,
    withShopsContext
} from "../../../../context/ShopsContext";
import DetailOverviewRow from "../../../../components/DetailOverviewRow";

function OrderDetailOverviewActionButtons({
    order,
    shopsContext,
    onResendDigitalProductsLinkEmail,
    onOpenInvoice,
    onResendInvoice,
    onCreateInvoice
}) {
    const baseUrl = "/shop/" + shopsContext.currentShop.codeName;
    const containsDigitalProducts = useMemo(() => {
        if(!order) {
            return false;
        }
        for(const product of order.products) {
            if(product.digital) {
                return true;
            }
        }
        return false;
    }, [order]);

    let buttons = [];
    if(order.status.paid && containsDigitalProducts) {
        buttons.push((passProps) => (
            <Button variant="secondary" className="mr-2" onClick={ onResendDigitalProductsLinkEmail } { ...passProps }>
                Digitale link opnieuw versturen
            </Button>
        ));
    }
    if(order.shopUser) {
        buttons.push((passProps) => (
            <Link to={`${baseUrl}/shop-user/${order.shopUser.id}`} className="btn btn-secondary mr-2" { ...passProps }>
                Gebruiker openen
            </Link>
        ));
    }
    if(order.status.paid && shopsContext.currentShop.entitlementAccountingApplication && shopsContext.currentShop.accountingApplications.length > 0) {
        if(order.invoiceId) {
            buttons.push((passProps) => (
                <Button variant="secondary" className="mr-2" onClick={ onOpenInvoice } { ...passProps }>
                    Factuur bekijken
                </Button>
            ));
            buttons.push((passProps) => (
                <Button variant="secondary" className="mr-2" onClick={ onResendInvoice } { ...passProps }>
                    Factuur versturen
                </Button>
            ));
        } else {
            buttons.push((passProps) => (
                <Button variant="primary" className="mr-2" onClick={ onCreateInvoice } { ...passProps }>
                    Factuur aanmaken
                </Button>
            ));
        }
    }
    if(buttons.length === 0) {
        return null;
    }
    return (
        <DetailOverviewRow>
            { buttons.map((ActionButton, index) => (
                <ActionButton key={ index }/>
            )) }
        </DetailOverviewRow>
    );
}
OrderDetailOverviewActionButtons.propTypes = {
    order: PropTypes.object,
    shopsContext: ShopsContextPropTypes,
    onResendDigitalProductsLinkEmail: PropTypes.func,
    onOpenInvoice: PropTypes.func,
    onResendInvoice: PropTypes.func,
    onCreateInvoice: PropTypes.func
}

export default withShopsContext(React.memo(OrderDetailOverviewActionButtons));
