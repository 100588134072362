import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import ShopsContext from "../../../context/ShopsContext";
import OAuthConnectionCard from "../modal/OAuthConnectionCard";
import UploadImageModal from "../../../components/modal/UploadImageModal";

function SettingsShopInvoice() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [saveError, setSaveError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [settings, setSettings] = useState(null);
    const [editedSettings, setEditedSettings] = useState(null);
    const [madeChanges, setMadeChanges] = useState(false);

    const [showUploadModal, setShowUploadModal] = useState(false);

    const shops = useContext(ShopsContext);

    const shopInvoiceEnabled = useMemo(() => {
        const currentShop = shops.currentShop;
        if(!currentShop) {
            return false;
        }
        return currentShop.accountingApplications.includes("shop-invoice");
    }, [shops]);

    const setEnabledState = useCallback(async (enabledState) => {
        setLoading(true);
        try {
            await axios.post("/shopInvoiceSetEnabled", { shopId: shops.currentShop.id, enabled: enabledState ? 1 : 0 });
            shops.refreshShopsNoClear();
            setSuccess("Shop Facturatie is succesvol ingesteld.");
        } catch(requestError) {
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [shops]);
    const onClickSetup = useCallback(() => setEnabledState(true), [setEnabledState]);
    const onClickRemove = useCallback(async (completionHandler) => {
        await setEnabledState(false);
        completionHandler();
    }, [setEnabledState]);

    const onClickUploadLogo = useCallback(() => {
        setShowUploadModal(true);
    }, []);

    const refreshData = useCallback(() => {
        if(!shopInvoiceEnabled) {
            return;
        }
        setError(null);
        setSettings(null);
        setEditedSettings(null);
        setMadeChanges(false);
        setSuccess(null);
        axios.post("/shopInvoiceGetSettings", { shopId: shops.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    setSettings(response.data);
                    setEditedSettings(response.data);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            });
    }, [shopInvoiceEnabled, shops]);
    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const onClickSave = useCallback(async () => {
        setSaveError(null);
        setLoading(true);
        setSuccess(null);
        try {
            await axios.post("/shopInvoiceSetSettings", {
                shopId: shops.currentShop.id,
                ...editedSettings
            });
            setSuccess("De instellingen zijn succesvol opgeslagen.");
            setMadeChanges(false);
        } catch(requestError) {
            console.error(requestError);
            const errorCode = requestError.response?.data?.error;
            if(errorCode === "INVALID_EMAIL") {
                setSaveError("Het ingevulde emailadres is ongeldig.");
            } else {
                setSaveError("Er is een fout opgetreden. Probeer het later opnieuw.");
            }
        }
        setLoading(false);
    }, [shops, editedSettings]);

    return (
        <React.Fragment>
            <UploadImageModal
                show={ showUploadModal }
                handleClose={ () => setShowUploadModal(false) }
                requestUrl="/uploadShopInvoiceImage"
                requestParameters={{ shopId: shops.currentShop.id }}
                imageUploaded={ refreshData }
            />
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { success && (
                <Alert variant="success">{ success }</Alert>
            )}
            <OAuthConnectionCard
                service="Shop Facturatie"
                enabled={ shopInvoiceEnabled }
                onClickSetup={ onClickSetup }
                onClickRemove={ onClickRemove }
                loading={ loading }
            />
            { !shopInvoiceEnabled || error ? null : !settings || !editedSettings ? (
                <Skeleton height={ 350 }/>
            ) : (
                <React.Fragment>
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Shop Facturatie instellingen</h5>
                            {saveError && (
                                <Alert variant="danger">{saveError}</Alert>
                            )}
                            <div className="form-group">
                                <label htmlFor="shopInvoiceColor">Factuur accentkleur</label>
                                <input
                                    type="color"
                                    id="shopInvoiceColor"
                                    className="form-control"
                                    value={editedSettings.shopInvoiceColor}
                                    onChange={(event) => {
                                        setEditedSettings({...editedSettings, shopInvoiceColor: event.target.value});
                                        setMadeChanges(true);
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="shopInvoiceCopyEmail">Emailadres voor factuur kopie</label>
                                <input
                                    type="email"
                                    id="shopInvoiceCopyEmail"
                                    className="form-control"
                                    value={editedSettings.shopInvoiceCopyEmail}
                                    onChange={(event) => {
                                        setEditedSettings({...editedSettings, shopInvoiceCopyEmail: event.target.value});
                                        setMadeChanges(true);
                                    }}
                                />
                                <small className="form-text text-muted">
                                    Dit emailadres ontvangt een kopie van elke factuur wanneer deze wordt aangemaakt.
                                    Je kunt meerdere emailadressen invullen door ze te scheiden met een komma.
                                </small>
                            </div>
                            <Button variant="primary" disabled={!madeChanges || loading} onClick={onClickSave}>
                                {loading && (
                                    <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                                )}
                                Opslaan
                            </Button>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-body">
                            <h5 className="card-title">Factuur logo</h5>
                            {settings.shopInvoiceLogoImage ? (
                                <div
                                    className="d-flex justify-content-center p-3 rounded mb-3"
                                    style={{backgroundColor: "#eee"}}
                                >
                                    <img
                                        src={settings.shopInvoiceLogoImage.url}
                                        alt="Logo"
                                        className="img-fluid"
                                        style={{
                                            maxHeight: "100px",
                                            objectFit: "cover"
                                        }}
                                    />
                                </div>
                            ) : (
                                <p className="card-text">Geen logo toegevoegd.</p>
                            )}
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={onClickUploadLogo}
                            >
                                <i className="fas fa-upload mr-2"/>
                                Afbeelding uploaden
                            </Button>
                        </div>
                    </div>
                </React.Fragment>
                )}
        </React.Fragment>
);
}

export default React.memo(SettingsShopInvoice);
