import React from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";
import {
    sortableContainer,
    sortableElement
} from "react-sortable-hoc";
import arrayMove from "array-move";

import ShopsContext from "../../../context/ShopsContext";
import Loading from "../../../components/Loading";
import CategoryAddProductModal from "./modal/CategoryAddProductModal";
import CategoryRemoveProductModal from "./modal/CategoryRemoveProductModal";
import ProductListItemCard from "../../../components/ProductListItemCard";

const SortableItem = sortableElement(({children}) => (
    <div>{children}</div>
));

const SortableContainer = sortableContainer(({children}) => (
    <div>{children}</div>
));

class CategoryDetailProducts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: null,
            error: null,
            success: null,
            savingError: null,
            changedOrder: false,
            savingOrder: false,

            showAddProductModal: false,
            showRemoveProductModal: false,
            removeProductModalProduct: null
        }
        this.setCategoryProductsOrder = this.setCategoryProductsOrder.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.onRemoveProduct = this.onRemoveProduct.bind(this);
    }

    componentDidMount() {
        if(this.props.category) {
            this.setProducts(this.props.category);
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.category !== this.props.category) {
            this.setProducts(this.props.category);
        }
    }

    setProducts(category) {
        this.setState({
            products: category.products
        });
    }

    setCategoryProductsOrder() {
        this.setState({ savingOrder: true, savingError: null, success: null });
        const productOrder = this.state.products.map((product) => product.id).join(",");
        axios.post("/setCategoryProductsOrder", { shopId: this.context.currentShop.id, categoryId: this.props.category.id, productOrder })
            .then((response) => {
                if(response.data.valid) {
                    this.setProducts(this.props.category);
                    this.props.setCategory(response.data.category);
                    this.setState({ changedOrder: false, success: "Product volgorde opgeslagen." });
                } else {
                    this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ savingOrder: false });
            });
    }

    onRemoveProduct(product) {
        this.setState({ showRemoveProductModal: true, removeProductModalProduct: product });
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState(({ products }) => {
            return { products: arrayMove(products, oldIndex, newIndex), changedOrder: true }
        });
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(!this.state.products) {
            return (
                <Loading/>
            )
        }
        return (
            <React.Fragment>
                <CategoryAddProductModal
                    show={ this.state.showAddProductModal }
                    handleClose={ () => { this.setState({ showAddProductModal: false }) } }
                    existingProducts={ this.state.products }
                    category={ this.props.category }
                    setCategory={ this.props.setCategory }
                />
                <CategoryRemoveProductModal
                    show={ this.state.showRemoveProductModal }
                    handleClose={ () => { this.setState({ showRemoveProductModal: false }) } }
                    product={ this.state.removeProductModalProduct }
                    category={ this.props.category }
                    setCategory={ this.props.setCategory }
                />
                { this.state.products.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-shopping-basket"/></h1>
                        <h4>Geen producten</h4>
                        <p>Deze categorie heeft nog geen producten.</p>
                        <Button variant="primary" onClick={ () => { this.setState({ showAddProductModal: true }) } }>
                            <i className="fas fa-plus mr-2"/>
                            Eerste product toevoegen
                        </Button>
                    </div>
                ) : (
                    <React.Fragment>
                        { this.state.success && (
                            <Alert variant="success">{ this.state.success }</Alert>
                        )}
                        { this.state.savingError && (
                            <Alert variant="danger">{ this.state.savingError }</Alert>
                        )}
                        <Button variant="primary" className="mb-2 mr-2" onClick={ this.setCategoryProductsOrder } disabled={ this.state.savingOrder || !this.state.changedOrder }>
                            <i className="fas fa-save mr-2"/>
                            Volgorde opslaan
                        </Button>
                        <Button variant="secondary" className="mb-2 mr-2" onClick={ () => { this.setState({ showAddProductModal: true }) } } disabled={ this.state.savingOrder }>
                            <i className="fas fa-plus mr-2"/>
                            Product toevoegen
                        </Button>
                        <SortableContainer onSortEnd={ this.onSortEnd }>
                            { this.state.products.map((product, index) => (
                                <SortableItem key={ product.id } index={ index } disabled={ this.state.savingOrder }>
                                    <div className="mb-2" style={{ cursor: "grab" }}>
                                        <ProductListItemCard
                                            product={ product }
                                            onClickRemove={ this.onRemoveProduct }
                                        />
                                    </div>
                                </SortableItem>
                            ))}
                        </SortableContainer>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
CategoryDetailProducts.contextType = ShopsContext;
CategoryDetailProducts.propTypes = {
    category: PropTypes.object,
    setCategory: PropTypes.func
}

export default CategoryDetailProducts;
