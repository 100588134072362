import React from "react";
import PropTypes from "prop-types";

import moneybird from "../../img/moneybird-logo.svg";
import shopInvoice from "../../img/shopcrate-logo.svg";

function AccountingApplicationLogo({ accountingApplication, ...props }) {
    if(accountingApplication === "moneybird") {
        return (
            <img { ...props } src={ moneybird } alt="Moneybird"/>
        );
    }
    if(accountingApplication === "shop-invoice") {
        return (
            <img { ...props } src={ shopInvoice } alt="Shop Invoice"/>
        );
    }
    return null;
}
AccountingApplicationLogo.propTypes = {
    accountingApplication: PropTypes.string.isRequired
};

export default React.memo(AccountingApplicationLogo);
