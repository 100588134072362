import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/DetailOverviewRow";

function CollectionDetailOverview({ collection }) {
    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-borderless mb-0">
                    <tbody>
                    <DetailOverviewRow title="Naam">
                        {collection ? (
                            collection.name
                        ) : (
                            <Skeleton width={150}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Interne naam">
                        {collection ? (
                            collection.codeName
                        ) : (
                            <Skeleton width={100}/>
                        )}
                    </DetailOverviewRow>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

CollectionDetailOverview.propTypes = {
    collection: PropTypes.object
}

export default CollectionDetailOverview;
