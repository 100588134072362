import React from "react";
import PropTypes from "prop-types";
import {
    SidebarManager
} from "@zandor300/backoffice-framework";

import {
    ShopsManager
} from "./context/ShopsContext";

function BackofficeManager({ children }) {
    return (
        <ShopsManager>
            <SidebarManager>
                { children }
            </SidebarManager>
        </ShopsManager>
    )
}
BackofficeManager.propTypes = {
    children: PropTypes.object
}

export default BackofficeManager;
