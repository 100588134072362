function validateCourseFormInput(course) {
    if(!course.title || course.title.length === 0) {
        return "De titel is verplicht.";
    }
    const maxAccessDuration = parseInt(course.maxAccessDuration, 10);
    if(maxAccessDuration <= 0) {
        return "De toegangsduur moet minimaal 1 dag zijn.";
    }
    return null;
}

export default validateCourseFormInput;
