import React, {
    useState
} from "react";
import {
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/DetailOverviewRow";
import DateFormatter from "../../../components/DateFormatter";
import ShopUserSendPasswordResetEmail from "./modal/ShopUserSendPasswordResetEmail";

function ShopUserDetailOverview({ shopUser }) {
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
    return (
        <React.Fragment>
            <ShopUserSendPasswordResetEmail
                show={ showPasswordResetModal }
                handleClose={ () => setShowPasswordResetModal(false) }
                shopUser={ shopUser }
            />
            <div className="card">
                <div className="card-body">
                    <table className="table table-borderless mb-0">
                        <tbody>
                        <DetailOverviewRow title="Naam">
                            {shopUser ? (
                                shopUser.name
                            ) : (
                                <Skeleton width={150}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Email">
                            {shopUser ? (
                                shopUser.email
                            ) : (
                                <Skeleton width={150}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Laatst ingelogd op">
                            {shopUser ? (
                                <DateFormatter date={ shopUser.lastLogin }/>
                            ) : (
                                <Skeleton width={150}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Geregistreerd op">
                            {shopUser ? (
                                <DateFormatter date={ shopUser.registerDate }/>
                            ) : (
                                <Skeleton width={150}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow>
                            {shopUser ? (
                                <Button variant="secondary" onClick={ () => setShowPasswordResetModal(true) }>
                                    Wachtwoord vergeten
                                </Button>
                            ) : (
                                <Skeleton width={150}/>
                            )}
                        </DetailOverviewRow>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

ShopUserDetailOverview.propTypes = {
    shopUser: PropTypes.object
}

export default ShopUserDetailOverview;
