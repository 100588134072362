import React, {
    useContext
} from "react";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import ReactRouterPropTypes from "react-router-prop-types";

import ShopsContext from "../../../context/ShopsContext";

import RentalOrderDetailOverview from "./RentalOrderDetailOverview";

function RentalOrderDetail(props) {
    const shopsContext = useContext(ShopsContext);
    const rentalOrderId = parseInt(props.match.params.rentalOrderId, 10);
    const baseUrl = "/shop/" + shopsContext.currentShop.codeName + "/rental-order/" + rentalOrderId;
    return (
        <React.Fragment>
            <Title preTitle="Detail" noBottom>
                Huurbestelling <small className="text-muted">#{rentalOrderId}</small>
            </Title>

            <TabBar>
                <TabBarItem to={ baseUrl }>
                    Overzicht
                </TabBarItem>
            </TabBar>

            <Switch>
                <Route path={ baseUrl } exact>
                    <RentalOrderDetailOverview rentalOrderId={ rentalOrderId }/>
                </Route>

                <Route path="/">
                    <Redirect
                        to={{
                            pathname: baseUrl,
                            state: {from: "/"}
                        }}
                    />
                </Route>
            </Switch>

        </React.Fragment>
    );
}
RentalOrderDetail.propTypes = {
    match: ReactRouterPropTypes.match
}

export default React.memo(RentalOrderDetail);
