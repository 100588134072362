import React, {
    useMemo
} from "react";
import {
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";

import DateFormatter from "../../../../components/DateFormatter";
import ProductImageDataCard from "../../../../components/ProductImageDataCard";

function ProductImageCard({ image, enabled, setImageEnabled }) {
    const onClickSetEnabledButton = useMemo(() => {
        return () => {
            setImageEnabled(image.id, !enabled);
        }
    }, [image, enabled, setImageEnabled]);
    const setEnabledButton = useMemo(() => {
        return (
            <Button variant="secondary" size="sm" onClick={ onClickSetEnabledButton }>
                { enabled ? (
                    <React.Fragment>
                        <i className="fas fa-minus mr-2"/>
                        Foto uitschakelen
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <i className="fas fa-plus mr-2"/>
                        Foto inschakelen
                    </React.Fragment>
                )}
            </Button>
        )
    }, [enabled, onClickSetEnabledButton]);

    return (
        <div className="w-100 mb-2" style={{ cursor: enabled ? "grab" : undefined, opacity: enabled ? 1 : 0.9 }}>
            <ProductImageDataCard image={ image }>
                <div className="d-flex flex-md-row flex-column text-muted">
                    <div className="mb-2 mr-5">
                        <div className="product-image-detail-title">
                            <b>Geupload door</b>
                        </div>
                        <div className="product-image-detail-value">
                            { image.author.name }
                        </div>
                    </div>
                    <div className="mb-2">
                        <div className="product-image-detail-title">
                            <b>Geupload op</b>
                        </div>
                        <div className="product-image-detail-value">
                            <DateFormatter date={ image.uploadDate }/>
                        </div>
                    </div>
                    <div className="flex-grow-1"/>
                    <div className="d-none d-lg-block">
                        { setEnabledButton }
                    </div>
                </div>
                <div className="d-lg-none">
                    { setEnabledButton }
                </div>
            </ProductImageDataCard>
        </div>
    );
}
ProductImageCard.propTypes = {
    image: PropTypes.object,
    enabled: PropTypes.bool,
    setImageEnabled: PropTypes.func
}

export default React.memo(ProductImageCard);
