import React from "react";

function Copyright() {
    return (
        <React.Fragment>
            Copyright &copy; 2020 - { new Date().getFullYear() } SR Productions. All rights reserved.
        </React.Fragment>
    )
}

export default Copyright;
