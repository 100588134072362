import React from "react";
import PropTypes from "prop-types";
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {
    CSS
} from "@dnd-kit/utilities";
import {
    Button
} from "react-bootstrap";

import CourseLessonCard from "./CourseLessonCard";
import CourseAddLessonCard from "./CourseAddLessonCard";

function CourseChapterCard({ chapter, onEditChapter, onDeleteChapter, onEditLesson, onAddLesson, onDeleteLesson }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: "chapter-" + chapter.id,
        transition: {
            duration: 150,
            easing: "cubic-bezier(0.25, 1, 0.5, 1)",
        }
    });

    const style = {
        transform: transform ? CSS.Transform.toString(transform) : undefined,
        transition,
        zIndex: isDragging ? 1550 : undefined
    };
    const buttonStyle = {
        cursor: isDragging ? "grabbing" : "grab"
    }

    return (
        <div
            className="mb-4"
            ref={ setNodeRef }
            style={ style }
        >
            <div className="card card-course-chapter">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="mr-2">
                            <Button
                                variant="light"
                                { ...attributes }
                                { ...listeners }
                                style={ buttonStyle }
                            >
                                <i className="fas fa-grip-lines"/>
                            </Button>
                        </div>
                        <div className="flex-grow-1">
                            { chapter.title }
                        </div>
                        { onEditChapter && (
                            <div className="ml-1">
                                <Button
                                    variant="light"
                                    onClick={ () => onEditChapter(chapter) }
                                >
                                    <i className="fas fa-edit"/>
                                </Button>
                            </div>
                        )}
                        { onDeleteChapter && chapter.lessons.length === 0 && (
                            <div className="ml-1">
                                <Button
                                    variant="light"
                                    className="text-danger"
                                    onClick={ () => onDeleteChapter(chapter) }
                                >
                                    <i className="fas fa-trash"/>
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            { chapter.lessons.length === 0 && (
                <p className="text-muted ml-5" style={{ fontSize: "0.9rem" }}>
                    <i>
                        Dit hoofdstuk bevat geen lessen en zal niet worden getoond aan cursisten.
                    </i>
                </p>
            )}
            <SortableContext
                items={ chapter.lessons.map((lesson) => ({ ...lesson, id: "lesson-" + lesson.id })) }
                strategy={ verticalListSortingStrategy }
            >
                { chapter.lessons.map((lesson) => (
                    <CourseLessonCard
                        key={ lesson.id }
                        lesson={ lesson }
                        onEdit={ onEditLesson ? () => onEditLesson(lesson) : undefined }
                        onDelete={ onDeleteLesson ? () => onDeleteLesson(lesson) : undefined }
                    />
                ))}
            </SortableContext>
            <CourseAddLessonCard
                onClick={ () => onAddLesson(chapter) }
            />
        </div>
    )
}
CourseChapterCard.propTypes = {
    chapter: PropTypes.object,
    onEditChapter: PropTypes.func,
    onDeleteChapter: PropTypes.func,
    onEditLesson: PropTypes.func,
    onAddLesson: PropTypes.func,
    onDeleteLesson: PropTypes.func
}

export default React.memo(CourseChapterCard);
