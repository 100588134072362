import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import ShopsContext from "../../../context/ShopsContext";
import DateFormatter from "../../../components/DateFormatter";
import EditShopUserCourseAccessModal from "./modal/EditShopUserCourseAccessModal";
import AddShopUserCourseAccessModal from "./modal/AddShopUserCourseAccessModal";

function ShopUserDetailCourses({ shopUser }) {
    const shopsContext = useContext(ShopsContext);
    const [courses, setCourses] = useState(null);
    const [error, setError] = useState(null);

    const [editCourseAccess, setEditCourseAccess] = useState(null);
    const [showEditCourseAccessModal, setShowEditCourseAccessModal] = useState(false);

    const [showAddCourseAccessModal, setShowAddCourseAccessModal] = useState(false);

    const refreshCourses = useCallback(async () => {
        if(!shopUser) {
            return;
        }
        try {
            const response = await axios.post("/getShopUserCourses", {
                shopId: shopsContext.currentShop.id,
                shopUserId: shopUser.id
            });
            setCourses(response.data.courses);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        }
    }, [shopUser, shopsContext.currentShop]);
    useEffect(() => {
        refreshCourses();
    }, [refreshCourses]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!courses) {
        return (
            <Loading/>
        )
    }
    return (
        <React.Fragment>
            <EditShopUserCourseAccessModal
                show={ showEditCourseAccessModal }
                handleClose={ () => setShowEditCourseAccessModal(false) }
                course={ editCourseAccess }
                shopUser={ shopUser }
                onChange={ refreshCourses }
            />
            <AddShopUserCourseAccessModal
                show={ showAddCourseAccessModal }
                handleClose={ () => setShowAddCourseAccessModal(false) }
                shopUser={ shopUser }
                onAdd={ refreshCourses }
            />
            { courses.length === 0 ? (
                <div className="text-center">
                    <h1><i className="fas fa-graduation-cap"/></h1>
                    <h4>Geen cursussen</h4>
                    <p>Deze gebruiker heeft nog geen cursussen.</p>
                    <Button
                        variant="primary"
                        onClick={ () => setShowAddCourseAccessModal(true) }
                    >
                        Cursus toegang verlenen
                    </Button>
                </div>
            ) : (
                <React.Fragment>
                    <div className="d-flex justify-content-end mb-3">
                        <Button
                            variant="primary"
                            onClick={ () => setShowAddCourseAccessModal(true) }
                        >
                            Cursus toegang verlenen
                        </Button>
                    </div>
                    <Table size="sm" hover>
                        <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th className="text-center">#</th>
                            <th className="text-left">Cursus</th>
                            <th className="text-center">Toegang</th>
                            <th className="text-right">Toegang sinds</th>
                            <th className="text-right">Verloopdatum</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        { courses.map((course) => {
                            const url = "/shop/" + shopsContext.currentShop.codeName + "/course/" + course.id;
                            return (
                                <tr key={ course.id } className="table-clickable-row">
                                    <th className="align-middle text-center">
                                        <Link to={ url }>
                                            { course.id }
                                        </Link>
                                    </th>
                                    <td className="align-middle text-left">
                                        <Link to={ url }>
                                            { course.title }
                                        </Link>
                                    </td>
                                    <td className="align-middle text-center">
                                        <Link to={ url }>
                                            { course.access.hasAccess ? (
                                                <i className="fas fa-check text-success"/>
                                            ) : (
                                                <i className="fas fa-times text-danger"/>
                                            )}
                                        </Link>
                                    </td>
                                    <td className="align-middle text-right">
                                        <Link to={ url }>
                                            <DateFormatter date={ course.access.firstAccessDate }/>
                                        </Link>
                                    </td>
                                    <td className="align-middle text-right">
                                        <Link to={ url }>
                                            { course.access.expirationDate ? (
                                                <DateFormatter date={ course.access.expirationDate }/>
                                            ) : (
                                                <i className="text-muted">Nooit</i>
                                            )}
                                        </Link>
                                    </td>
                                    <td className="align-middle text-right">
                                        <OverlayTrigger overlay={
                                            <Tooltip id="tooltip-edit">
                                                Bewerk cursustoegang
                                            </Tooltip>
                                        }>
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                onClick={ () => {
                                                    setEditCourseAccess(course);
                                                    setShowEditCourseAccessModal(true);
                                                }}
                                            >
                                                <i className="fa-solid fa-pen fa-fw"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            )
                        }) }
                        </tbody>
                    </Table>
                </React.Fragment>
            )}
        </React.Fragment>
    )

}
ShopUserDetailCourses.propTypes = {
    shopUser: PropTypes.object
}

export default React.memo(ShopUserDetailCourses);
