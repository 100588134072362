import React, {
    useContext
} from "react";
import {
    Sidebar,
    SidebarDivider,
    SidebarLink
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import ShopCrateLogo from "./ShopCrateLogo";

function BackofficeSidebar() {
    const shopsContext = useContext(ShopsContext);

    const shopUrl = "/shop/" + (shopsContext.currentShop ? shopsContext.currentShop.codeName : "");

    const {
        entitlementCourses,
        entitlementCategories,
        entitlementCollections,
        entitlementOrders,
        entitlementShipments,
        entitlementRentals,
        entitlementAuthentication
    } = shopsContext.currentShop;

    return (
        <Sidebar
            logo={
                <ShopCrateLogo/>
            }
        >
            <SidebarLink to={ `${shopUrl}/dashboard` } icon="fas fa-tachometer-alt">
                Dashboard
            </SidebarLink>
            <SidebarDivider/>
            <SidebarLink to={ `${shopUrl}/products` } additionalUrls={[`${shopUrl}/product/:productId`]} icon="fas fa-inventory">
                Producten
            </SidebarLink>
            { entitlementCourses && (
                <SidebarLink to={ `${shopUrl}/courses` } additionalUrls={[`${shopUrl}/course/:courseId`]} icon="fas fa-graduation-cap">
                    Cursussen
                </SidebarLink>
            )}
            { entitlementCategories && (
                <SidebarLink to={ `${shopUrl}/categories` } additionalUrls={[`${shopUrl}/category/:categoryId`]} icon="fas fa-th-list">
                    Categorieën
                </SidebarLink>
            )}
            { entitlementCollections && (
                <SidebarLink to={ `${shopUrl}/collections` } additionalUrls={[`${shopUrl}/collection/:collectionId`]} icon="fas fa-th-list">
                    Collecties
                </SidebarLink>
            )}
            <SidebarDivider/>
            { entitlementOrders && (
                <SidebarLink to={ `${shopUrl}/orders` } additionalUrls={[`${shopUrl}/order/:orderId`]} icon="fas fa-receipt">
                    Bestellingen
                </SidebarLink>
            )}
            { entitlementShipments && (
                <SidebarLink to={ `${shopUrl}/shipments` } additionalUrls={[`${shopUrl}/shipment/:shipmentId`]} icon="fas fa-truck-loading">
                    Verzendingen
                </SidebarLink>
            )}
            { entitlementRentals && (
                <SidebarLink to={ `${shopUrl}/rental-orders` } additionalUrls={[`${shopUrl}/rental-order/:rentalOrderId`]} icon="fas fa-calendar-alt">
                    Huurbestellingen
                </SidebarLink>
            )}
            <SidebarDivider/>
            { entitlementAuthentication && (
                <SidebarLink to={ `${shopUrl}/shop-users` } additionalUrls={[`${shopUrl}/shop-user/:shopUserId`]} icon="fas fa-users">
                    Gebruikers
                </SidebarLink>
            )}
            <SidebarLink to={ `${shopUrl}/settings` } icon="fas fa-cog">
                Instellingen
            </SidebarLink>
        </Sidebar>
    )
}

export default React.memo(BackofficeSidebar);
