import React, {
    useCallback,
    useContext,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import ShopsContext from "../../../../../context/ShopsContext";

function AddCourseShopUserAccessItemCard({ course, shopUser, onSaved }) {
    const shopsContext = useContext(ShopsContext);
    const [expirationDate, setExpirationDate] = useState(moment().add(1, "day").format("YYYY-MM-DD"));
    const [accessForever, setAccessForever] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onSave = useCallback(async () => {
        setError(null);
        setLoading(true);
        try {
            await axios.post("/addCourseShopUserAccess", {
                shopId: shopsContext.currentShop.id,
                shopUserId: shopUser.id,
                courseId: course.id,
                accessForever: accessForever ? 1 : 0,
                expirationDate
            });
            onSaved();
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [course, shopUser, shopsContext, accessForever, expirationDate, onSaved]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">Toegang verlenen</h5>

                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}

                <div className="d-flex flex-column flex-md-row align-items-md-end">
                    <div className="mr-3 d-flex align-items-center" style={{ minHeight: "38px" }}>
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="accessForever"
                                name="accessForever"
                                checked={accessForever}
                                onChange={(event) => setAccessForever(event.target.checked)}
                                // disabled={loading}
                                disabled={true}
                            />
                            <label className="custom-control-label" htmlFor="accessForever">
                                Onbeperkte toegang
                            </label>
                        </div>
                    </div>
                    <div className="flex-grow-1">
                        <div className="form-group mb-0">
                            <label htmlFor="expirationDate">Verloopdatum</label>
                            <input
                                type="date"
                                className="form-control"
                                id="expirationDate"
                                name="expirationDate"
                                value={expirationDate}
                                onChange={(event) => setExpirationDate(event.target.value)}
                                disabled={accessForever || loading}
                            />
                        </div>
                    </div>
                    <div className="ml-3 mt-3 mb-md-0">
                        <div className="d-flex flex-row justify-content-end">
                            <Button
                                variant="primary"
                                onClick={onSave}
                                disabled={loading}
                            >
                                { loading && (
                                    <Spinner animation="border" size="sm" className="mr-2"/>
                                )}
                                Toevoegen
                            </Button>
                        </div>
                    </div>
                </div>

                <p className="mb-0 mt-3 text-muted">
                    <i>
                        Vanwege een fout is onbeperkte toegang momenteel niet beschikbaar. Hieraan wordt gewerkt.
                    </i>
                </p>
            </div>
        </div>
    );
}

AddCourseShopUserAccessItemCard.propTypes = {
    course: PropTypes.object.isRequired,
    shopUser: PropTypes.object.isRequired,
    onSaved: PropTypes.func.isRequired
};

export default React.memo(AddCourseShopUserAccessItemCard);
