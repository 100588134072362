import React from "react";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";

import Loading from "../../../../components/Loading";
import ShopsContext from "../../../../context/ShopsContext";
import PriceFormatter from "../../../../components/priceFormatter";

class NewShipmentModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorSave: null,

            products: [],
            selectedProducts: []
        }
    }

    getProducts(orderId) {
        this.setState({ error: null, products: [], selectedProducts: [] });
        axios.post("/getOrderProductsToBeShipped", { shopId: this.context.currentShop.id, orderId })
            .then((response) => {
                if(response.data.valid) {
                    const selectedProducts = response.data.products.map((product) => {
                        return {
                            productId: product.id,
                            amount: product.amount
                        }
                    });
                    this.setState({ products: response.data.products, selectedProducts });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    addShipment() {
        if(!this.state.selectedProducts.find((product) => product.amount > 0)) {
            this.setState({ errorSave: "Er zijn geen producten geselecteerd om de verzending mee aan te maken." });
            return;
        }
        this.setState({ loading: true, errorSave: null });
        axios.post("/addShipment", { shopId: this.context.currentShop.id, orderId: this.props.orderId, products: JSON.stringify(this.state.selectedProducts) })
            .then((response) => {
                if(response.data.valid) {
                    const shipment = response.data.shipment;
                    console.log(shipment);
                    const url = "/shop/" + this.context.currentShop.codeName + "/shipment/" + shipment.id;
                    this.props.history.push(url);
                } else {
                    this.setState({ errorSave: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")", loading: false });
                }
            })
            .catch(() => {
                this.setState({ errorSave: "Er ging iets fout. Probeer het later opnieuw.", loading: false });
            });
    }

    onShow() {
        this.getProducts(this.props.orderId);
        this.setState({ selectedProducts: [] });
    }

    getSelectedAmount(productId) {
        const productAmount = this.state.selectedProducts.find((product) => product.productId === productId);
        return productAmount ? productAmount.amount : 0;
    }

    updateSelectedProduct(productId, amount) {
        this.setState((prevState) => {
            const selectedProducts = prevState.selectedProducts.map((product) => {
                if(product.productId === productId) {
                    return { productId, amount }
                }
                return product;
            });
            console.log(selectedProducts);
            return { selectedProducts };
        })
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow.bind(this) } size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Nieuwe verzending aanmaken</Modal.Title>
                </Modal.Header>
                <form className="mb-0">
                    <Modal.Body>
                        { this.state.error ? (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        ) : this.state.products === null || this.state.loading ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                { this.state.errorSave && (
                                    <Alert variant="danger">{ this.state.errorSave }</Alert>
                                )}
                                { this.state.products.length === 0 ? (
                                    <div className="text-center">
                                        <h1><i className="fas fa-truck-loading"/></h1>
                                        <h4>Verzendingen aangemaakt</h4>
                                        <p>Voor alle producten van deze bestelling is al een verzending gemaakt!</p>
                                        <Button variant="secondary" onClick={ this.props.handleClose }>
                                            Sluiten
                                        </Button>
                                    </div>
                                ) : this.state.products.map((product) => {
                                    const image = product.images.length === 0 ? null : product.images[0].alternatives["175x130"];
                                    const currentAmount = this.getSelectedAmount(product.id);
                                    return (
                                        <div className="card mb-2" key={ product.id }>
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="d-flex justify-content-center align-content-center" style={{ width: "150px", height: "100px" }}>
                                                        <img
                                                            src={ image ? image.url : "https://via.placeholder.com/175x130" }
                                                            className="img-fluid rounded"
                                                            alt="Product"
                                                            style={{ maxWidth: "150px", maxHeight: "100px" }}
                                                        />
                                                    </div>
                                                    <div className="pl-4 flex-fill">
                                                        <h5 className="card-title">{ product.name }</h5>
                                                        <b>
                                                            <span className="float-right">
                                                                <PriceFormatter price={ product.amount * product.price }/>
                                                            </span>
                                                            <div className="btn-group mr-2">
                                                                <Button
                                                                    variant="danger"
                                                                    disabled={ currentAmount <= 0 }
                                                                    onClick={ () => this.updateSelectedProduct(product.id, currentAmount - 1) }
                                                                >
                                                                    <i className="fas fa-minus"/>
                                                                </Button>
                                                                <input type="number" className="form-control" placeholder="Aantal" style={{ borderRadius: 0, width: "75px", textAlign: "center" }}
                                                                       value={ currentAmount }
                                                                       onChange={ (event) => this.updateSelectedProduct(product.id, parseInt(event.target.value, 10)) }
                                                                />
                                                                <Button
                                                                    variant="success"
                                                                    disabled={ currentAmount >= product.amount }
                                                                    onClick={ () => this.updateSelectedProduct(product.id, currentAmount + 1) }
                                                                >
                                                                    <i className="fas fa-plus"/>
                                                                </Button>
                                                            </div>
                                                            <span className="ml-2">
                                                                Te verzenden: { product.amount }
                                                            </span>
                                                        </b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                    { this.state.products && this.state.products.length > 0 && (
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.addShipment.bind(this) }>
                                Aanmaken
                            </Button>
                        </Modal.Footer>
                    )}
                </form>
            </Modal>
        )
    }
}
NewShipmentModal.contextType = ShopsContext;
NewShipmentModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
    history: ReactRouterPropTypes.history.isRequired
}

export default withRouter(NewShipmentModal);
