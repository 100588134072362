import React from "react";
import PropTypes from "prop-types";

function ChartError({ error = "Er is een fout opgetreden bij het laden van de grafiek. Probeer het later opnieuw." }) {
    return (
        <div className="h-100 w-100 d-flex align-items-center text-center">
            <div className="w-100 text-danger">
                <h1><i className="fas fa-exclamation-circle"/></h1>
                <h3>Error</h3>
                <p>{ error }</p>
            </div>
        </div>
    )
}
ChartError.propTypes = {
    error: PropTypes.string
}

export default React.memo(ChartError);
