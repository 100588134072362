/* eslint-disable max-lines */
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

import ShopsContext from "../../../context/ShopsContext";
import OAuthConnectionCard from "../modal/OAuthConnectionCard";
import Loading from "../../../components/Loading";

function ExternalMoneybirdLink({ href, children }) {
    return (
        <a
            href={ href }
            target="_blank"
            rel="nofollow noreferrer"
            className="btn btn-link px-0"
            style={{fontSize: "0.9rem"}}
        >
            { children }
            <i className="fas fa-external-link-alt ml-1" style={{fontSize: "0.75rem"}}/>
        </a>
    )
}
ExternalMoneybirdLink.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

// eslint-disable-next-line complexity
function SettingsMoneybird() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [saveError, setSaveError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [settings, setSettings] = useState(null);
    const [editedSettings, setEditedSettings] = useState(null);
    const [madeChanges, setMadeChanges] = useState(false);

    const shops = useContext(ShopsContext);

    const moneybirdEnabled = useMemo(() => {
        const currentShop = shops.currentShop;
        if(!currentShop) {
            return false;
        }
        return currentShop.accountingApplications.includes("moneybird");
    }, [shops]);
    useEffect(() => {
        if(!moneybirdEnabled) {
            return;
        }
        setError(null);
        setSettings(null);
        setEditedSettings(null);
        setMadeChanges(false);
        setSuccess(null);
        axios.post("/oauthMoneybirdSetupGetSettings", { shopId: shops.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    setSettings(response.data);
                    setEditedSettings(response.data);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((setupError) => {
                console.error(setupError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            });
    }, [moneybirdEnabled, shops]);

    const didClickSetup = useCallback(() => {
        setLoading(true);
        axios.post("/oauthMoneybirdSetup", { shopId: shops.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    window.location = response.data.oauthUrl;
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                    setLoading(false);
                }
            })
            .catch((setupError) => {
                console.error(setupError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                setLoading(false);
            });
    }, []);
    const didClickRemove = useCallback((completionHandler) => {
        setError(null);
        setLoading(true);
        axios.post("/oauthMoneybirdSetupRemove", { shopId: shops.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    shops.refreshShopsNoClear();
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((setupError) => {
                console.error(setupError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            })
            .finally(() => {
                setLoading(false);
                completionHandler();
            });
    }, []);

    const onChangeSettings = useCallback((changes) => {
        setMadeChanges(true);
        setSuccess(null);
        setEditedSettings((prevSettings) => {
            return { ...prevSettings, ...changes };
        });
    }, []);
    const onClickSave = useCallback(() => {
        const {
            currentWorkflowId,
            currentDocumentStyleId,
            currentLedgerAccountId,
            autoCreateInvoice,
            sendInvoice
        } = editedSettings;
        if(!currentWorkflowId || currentWorkflowId === "invalid") {
            setSaveError("Ongeldige workflow geselecteerd.");
            return;
        }
        if(!currentDocumentStyleId || currentDocumentStyleId === "invalid") {
            setSaveError("Ongeldige huisstijl geselecteerd.");
            return;
        }
        if(!currentLedgerAccountId || currentLedgerAccountId === "invalid") {
            setSaveError("Ongeldige categorie geselecteerd.");
            return;
        }
        setLoading(true);
        setSaveError(null);
        setSuccess(null);
        axios.post("/oauthMoneybirdSetupSetSettings", {
            shopId: shops.currentShop.id,
            currentWorkflowId,
            currentDocumentStyleId,
            currentLedgerAccountId,
            autoCreateInvoice: autoCreateInvoice ? 1 : 0,
            sendInvoice: sendInvoice ? 1 : 0
        })
            .then((response) => {
                if(response.data.valid) {
                    setSettings(editedSettings);
                    setMadeChanges(false);
                    shops.refreshShopsNoClear();
                    setSuccess("Wijzigingen opgeslagen!");
                } else {
                    setSaveError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((setupError) => {
                console.error(setupError);
                setSaveError("Er is een fout opgetreden. Probeer het later opnieuw.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [editedSettings]);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { success && (
                <Alert variant="success">{ success }</Alert>
            )}
            <OAuthConnectionCard
                service="Moneybird"
                enabled={ moneybirdEnabled }
                onClickSetup={ didClickSetup }
                onClickRemove={ didClickRemove }
                loading={ loading }
            />
            { settings && (!settings.currentWorkflowId || !settings.currentDocumentStyleId || !settings.currentLedgerAccountId) && (
                <Alert variant="warning">
                    Een aantal vereiste instellingen zijn niet ingesteld! Er kunnen nog geen facturen worden aangemaakt totdat deze instellingen
                    zijn geselecteerd.
                </Alert>
            )}
            { !moneybirdEnabled || error ? null : !settings || !editedSettings ? (
                <Skeleton height={ 357 }/>
            ) : (
                <div className="card mb-3">
                    <div className="card-body">
                        <h5 className="card-title">Moneybird instellingen</h5>
                        { saveError && (
                            <Alert variant="danger">{ saveError }</Alert>
                        )}
                        { !editedSettings ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                <div className="form-group">
                                    <label htmlFor="workflow">Workflow voor facturen</label>
                                    <select
                                        className="form-control"
                                        id="workflow"
                                        value={ editedSettings.currentWorkflowId ? editedSettings.currentWorkflowId : "invalid" }
                                        onChange={ (event) => onChangeSettings({ currentWorkflowId: event.target.value }) }
                                        disabled={ loading }
                                    >
                                        { !settings.currentWorkflowId && (
                                            <option value="invalid"/>
                                        )}
                                        { settings.workflows.map((workflow) => (
                                            <option key={ workflow.id } value={ workflow.id }>
                                                { workflow.name }
                                            </option>
                                        ))}
                                    </select>
                                    <ExternalMoneybirdLink href={ `https://moneybird.com/${settings.administrationId}/workflows` }>
                                        Workflows beheren in Moneybird
                                    </ExternalMoneybirdLink>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="documentStyle">Huisstijl voor facturen</label>
                                    <select
                                        className="form-control"
                                        id="documentStyle"
                                        value={ editedSettings.currentDocumentStyleId ? editedSettings.currentDocumentStyleId : "invalid" }
                                        onChange={ (event) => onChangeSettings({ currentDocumentStyleId: event.target.value }) }
                                        disabled={ loading }
                                    >
                                        { !settings.currentDocumentStyleId && (
                                            <option value="invalid"/>
                                        )}
                                        { settings.documentStyles.map((documentStyle) => (
                                            <option key={ documentStyle.id } value={ documentStyle.id }>
                                                { documentStyle.name }
                                            </option>
                                        ))}
                                    </select>
                                    <ExternalMoneybirdLink href={ `https://moneybird.com/${settings.administrationId}/document_styles` }>
                                        Huisstijlen beheren in Moneybird
                                    </ExternalMoneybirdLink>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ledgerAccount">Categorie voor facturen</label>
                                    <select
                                        className="form-control"
                                        id="ledgerAccount"
                                        value={ editedSettings.currentLedgerAccountId ? editedSettings.currentLedgerAccountId : "invalid" }
                                        onChange={ (event) => onChangeSettings({ currentLedgerAccountId: event.target.value }) }
                                        disabled={ loading }
                                    >
                                        { !settings.currentLedgerAccountId && (
                                            <option value="invalid"/>
                                        )}
                                        { settings.ledgerAccounts.map((ledgerAccount) => (
                                            <option key={ ledgerAccount.id } value={ ledgerAccount.id }>
                                                { ledgerAccount.name }
                                            </option>
                                        ))}
                                    </select>
                                    <ExternalMoneybirdLink href={ `https://moneybird.com/${settings.administrationId}/ledger_accounts` }>
                                        Categorieën beheren in Moneybird
                                    </ExternalMoneybirdLink>
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="autoCreateInvoice"
                                            checked={ editedSettings.autoCreateInvoice ?? false }
                                            onChange={ (event) => onChangeSettings({ autoCreateInvoice: event.target.checked }) }
                                            disabled={ loading }
                                        />
                                        <label className="custom-control-label" htmlFor="autoCreateInvoice">
                                            Factuur automatisch aanmaken na betaling
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="sendInvoice"
                                            checked={ editedSettings.sendInvoice ?? false }
                                            onChange={ (event) => onChangeSettings({ sendInvoice: event.target.checked }) }
                                            disabled={ loading }
                                        />
                                        <label className="custom-control-label" htmlFor="sendInvoice">
                                            Factuur versturen naar klant per email
                                        </label>
                                    </div>
                                </div>
                                <Button variant="primary" disabled={ !madeChanges || loading } onClick={ onClickSave }>
                                    { loading && (
                                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                                    )}
                                    Opslaan
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    )
}

export default React.memo(SettingsMoneybird);
