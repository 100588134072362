import React, {
    useContext
} from "react";
import PropTypes from "prop-types";

import ShopsContext from "../../../context/ShopsContext";
import RadioChip from "../../../components/RadioChip";

function getProductTypes(shop) {
    const productTypes = [];
    if(shop.entitlementOrders) {
        productTypes.push({
            value: "physical",
            name: "Koop fysiek",
            icon: "fa-solid fa-apple-whole",
        });
        productTypes.push({
            value: "digital",
            name: "Koop digitaal",
            icon: "fa-solid fa-laptop",
        });
    }
    if(shop.entitlementCourses) {
        productTypes.push({
            value: "course",
            name: "Cursus",
            icon: "fa-solid fa-graduation-cap",
        });
    }
    if(shop.entitlementRentals) {
        productTypes.push({
            value: "rental",
            name: "Huur",
            icon: "fa-solid fa-car-side",
        });
    }
    return productTypes;
}

function ProductTypePicker({ productType, setProductType }) {
    const {
        currentShop
    } = useContext(ShopsContext);

    const productTypes = getProductTypes(currentShop);

    return (
        <React.Fragment>
            <div className="d-md-none">
                { productTypes.map((type) => (
                    <RadioChip
                        key={ type.value }
                        checked={ productType === type.value }
                        onClick={ () => setProductType(type.value) }
                        className="mr-2 mt-2"
                    >
                        <i className={ `${type.icon} mr-2` }/>
                        { type.name }
                    </RadioChip>
                ))}
            </div>
            <div className="d-none d-md-block">
                <div className="row">
                    { productTypes.map((type) => (
                        <div
                            className="col-md-6 col-xl-3"
                            key={ type.value }
                        >
                            <div
                                className={"card pointer-cursor mt-2" + (productType === type.value ? " active" : "")}
                                onClick={ () => setProductType(type.value) }
                            >
                                <div className="card-body">
                                    <div style={{ fontSize: "1.5rem" }}>
                                        <i
                                            className={`${type.icon}`}
                                        />
                                    </div>
                                    <div className="mt-1">
                                        { type.name }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
ProductTypePicker.propTypes = {
    productType: PropTypes.string,
    setProductType: PropTypes.func.isRequired
};

export default React.memo(ProductTypePicker);
