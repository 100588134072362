import React from "react";
import PropTypes from "prop-types";
import {
    Button
} from "react-bootstrap";

function CourseAddChapterCard({ onClick }) {
    return (
        <div className="card card-course-chapter-add text-muted" onClick={ onClick }>
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div className="mr-2">
                        <Button
                            variant="light"
                            onClick={ onClick }
                        >
                            <i className="fas fa-plus"/>
                        </Button>
                    </div>
                    <div>
                        Nieuw hoofdstuk toevoegen
                    </div>
                </div>
            </div>
        </div>
    )
}
CourseAddChapterCard.propTypes = {
    onClick: PropTypes.func
}

export default React.memo(CourseAddChapterCard);
