import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import ShopsContext from "../../../../context/ShopsContext";
import ModalSuccess from "../../../../components/modal/content/ModalSuccess";

function ShopUserSendPasswordResetEmail({ show, handleClose, shopUser }) {
    const shopsContext = useContext(ShopsContext);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!show) {
            return;
        }
        setLoading(false);
        setSuccess(false);
        setError(null);
    }, [show]);
    const onSubmit = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            await axios.post("/sendShopUserPasswordResetEmail", {
                shopId: shopsContext.currentShop.id,
                shopUserId: shopUser.id
            });
            setSuccess(true);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [shopUser, shopsContext]);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Wachtwoord reset email versturen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                { success ? (
                    <ModalSuccess
                        icon="fa-solid fa-paper-plane"
                        message="Email verstuurd"
                    />
                ) : (
                    <p className="mb-0">
                        Weet je zeker dat je een email wilt versturen naar <b>{ shopUser?.email }</b>? In de email staat een link
                        waarmee de klant zelf een nieuw wachtwoord kan instellen.
                    </p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    { success ? "Sluiten" : "Annuleer" }
                </Button>
                { !success && (
                    <Button variant="primary" onClick={ onSubmit } disabled={ loading }>
                        { loading && (
                            <Spinner animation="border" size="sm" className="mr-2"/>
                        )}
                        Versturen
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}
ShopUserSendPasswordResetEmail.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    shopUser: PropTypes.object.isRequired
};

export default React.memo(ShopUserSendPasswordResetEmail);
