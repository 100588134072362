import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import ShopsContext from "../../../context/ShopsContext";
import StatusPill from "../../../components/pills/StatusPill";
import NewShipmentModal from "./modal/NewShipmentModal";

class OrderDetailShipments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shipments: null,
            error: null
        }
    }

    componentDidMount() {
        this.getShipmentsForOrder();
    }

    getShipmentsForOrder() {
        this.setState({ shipments: null, error: null });
        axios.post("/getShipmentsForOrder", { shopId: this.context.currentShop.id, orderId: this.props.orderId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ shipments: response.data.shipments });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        const order = this.props.order;
        const shipments = this.state.shipments;
        if(shipments === null || order === null) {
            return (
                <React.Fragment>
                    {[...Array(20)].map((value, index) => (
                        <div className="mb-3" key={ index }>
                            <Skeleton height={ 100 }/>
                        </div>
                    ))}
                </React.Fragment>
            )
        }
        const newShipmentModal = (
            <NewShipmentModal
                show={ this.state.showNewShipmentModal }
                handleClose={ () => { this.setState({ showNewShipmentModal: false }) }}
                orderId={ this.props.orderId }
            />
        )
        if(shipments.length === 0) {
            return (
                <div className="text-center">
                    { newShipmentModal }
                    <h1><i className="fas fa-box-open"/></h1>
                    <h3>Geen verzendingen</h3>
                    { order.status.paid ? (
                        <React.Fragment>
                            <p>Er zijn nog geen verzendingen aangemaakt bij deze bestelling.</p>
                            <button className="btn btn-primary btn-sm mb-3" onClick={ () => this.setState({ showNewShipmentModal: true }) }>
                                <i className="fas fa-plus mr-2"/>
                                Nieuwe verzending
                            </button>
                        </React.Fragment>
                    ) : (
                        <p>
                            De klant heeft nog niet betaald voor de bestelling.
                            <br/>
                            Er kunnen nog geen verzendingen gemaakt worden.
                        </p>
                    )}
                </div>
            )
        }
        return (
            <React.Fragment>
                { newShipmentModal }
                <button className="btn btn-primary btn-sm mb-3" onClick={ () => this.setState({ showNewShipmentModal: true }) }>
                    <i className="fas fa-plus mr-2"/>
                    Nieuwe verzending
                </button>
                { shipments.map(shipment => (
                    <div className="card mb-3" key={ shipment.id }>
                        <div className="card-body">
                            <Link to={ "/shop/" + this.context.currentShop.codeName + "/shipment/" + shipment.id } className="d-hidden stretched-link"/>
                            <h5 className="card-title">
                                Verzending #{ shipment.id }
                                <div className="float-right">
                                    <small>
                                        <StatusPill status={ shipment.status }/>
                                    </small>
                                </div>
                            </h5>
                            <p className="card-text">
                                { shipment.products.map((product) => (
                                    <React.Fragment key={ product.id }>
                                        { product.amount }x { product.name }
                                        <br/>
                                    </React.Fragment>
                                ))}
                            </p>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        )
    }
}
OrderDetailShipments.contextType = ShopsContext;
OrderDetailShipments.propTypes = {
    orderId: PropTypes.number.isRequired,
    order: PropTypes.object
}

export default OrderDetailShipments;
