import React, {
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Link,
    useHistory
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../components/Loading";
import ShopsContext from "../../context/ShopsContext";

function MollieOAuth() {
    const [error, setError] = useState(null);

    const shops = useContext(ShopsContext);

    const history = useHistory();

    useEffect(() => {
        if(!shops.shops) {
            return;
        }
        const queryString = window.location.search;
        if(queryString.length <= 0) {
            setError("Er ging iets fout met het koppelen. Probeer het later opnieuw.");
            return;
        }
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get("code");
        const shopId = urlParams.get("state");
        if(!code || !shopId) {
            setError("Er ging iets fout met het koppelen. Probeer het later opnieuw.");
            return;
        }

        axios.post("/oauthMollieSetupResponse", {
            shopId: shopId,
            code
        })
            .then((response) => {
                if(response.data.valid) {
                    const shop = response.data.shop;
                    shops.refreshShops();
                    history.push("/shop/" + shop.codeName + "/settings/mollie");
                } else {
                    setError("Mollie koppeling is mislukt. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((setupError) => {
                console.error(setupError);
                setError("Mollie koppeling is mislukt. Probeer het later opnieuw.");
            });
    }, [shops.shops]);

    return (
        <div className="container my-0">
            <div className="py-5 w-100 d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                { error ? (
                    <div className="text-center my-5">
                        <Alert variant="danger">{ error }</Alert>
                        <Link to="/shops" className="btn btn-primary">Terug naar backoffice</Link>
                    </div>
                ) : (
                    <div className="text-center">
                        <Loading/>
                        <h3>Koppelen met Mollie...</h3>
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(MollieOAuth);
