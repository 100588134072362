import React from "react";
import PropTypes from "prop-types";
import axios from "axios";

import BaseSelectObjectModal from "../../../../components/modal/BaseSelectObjectModal";

class AddProductCourseModal extends BaseSelectObjectModal {
    abstractGetTitle() {
        return "Cursus toevoegen"
    }

    async abstractLoadObjects() {
        const response = await axios.post("/getCourses", {
            shopId: this.context.currentShop.id
        });
        return response.data.courses;
    }

    // eslint-disable-next-line no-unused-vars
    async abstractSave(selectedObject) {
        await axios.post("/addCourseToProduct", {
            shopId: this.context.currentShop.id,
            productId: this.props.product.id,
            courseId: selectedObject
        });
        this.props.onSave();
    }

    abstractGetExistingObjects() {
        return this.props.existingCourses;
    }

    abstractGetObjectListItemContent(object) {
        return (
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    { object.title }
                </div>
            </div>
        );
    }

    abstractGetNoObjectsMessage() {
        return "Geen cursussen die matchen met je zoekopdracht.";
    }

    abstractAlreadyAddedMessage() {
        return "Deze cursus is al toegevoegd.";
    }

    matchObject(object, filter) {
        return object.title.toLowerCase().includes(filter);
    }
}
AddProductCourseModal.propTypes = {
    ...BaseSelectObjectModal.propTypes,
    product: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    existingCourses: PropTypes.array
}

export default AddProductCourseModal;
