import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    OverlayTrigger,
    Spinner,
    Table,
    Tooltip
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";
import fileDownload from "js-file-download";

import ShopsContext from "../../../context/ShopsContext";
import Loading from "../../../components/Loading";
import StatusPill from "../../../components/pills/StatusPill";
import DateFormatter from "../../../components/DateFormatter";
import PriceFormatter from "../../../components/priceFormatter";

class ProductDetailOrders extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            orders: null,
            selectedOrders: [],
            error: null,
            exportLoading: false
        }
        this.exportOrders = this.exportOrders.bind(this);
        this.didClickAllOrdersCheckbox = this.didClickAllOrdersCheckbox.bind(this);
    }

    componentDidMount() {
        if(this.props.product) {
            this.getOrders();
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((!prevProps.product && this.props.product) || (prevProps.product && this.props.product && prevProps.product.id !== this.props.product.id)) {
            this.getOrders();
        }
    }

    getOrders() {
        this.setState({ orders: null, selectedOrders: [], error: null });
        axios.post("/getOrdersForProduct", { shopId: this.context.currentShop.id, productId: this.props.product.id })
            .then((response) => {
                if(response.data.valid) {
                    const orders = response.data.orders.sort((order1, order2) => {
                        return moment(order1.orderDate) < moment(order2.orderDate) ? 1 : -1;
                    })
                    this.setState({ orders });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    exportOrders() {
        const shop = this.context.currentShop;
        this.setState({ exportLoading: true });
        axios.post("/exportOrdersCSV", { shopId: shop.id, orderIds: this.state.selectedOrders.join(",") })
            .then((response) => {
                fileDownload(response.data, shop.name + " Bestellingen Export " + moment().format("YYYY-MM-DD HH:mm:ss") + ".csv");
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ exportLoading: false });
            });
    }

    getTimesOrdered() {
        if(!this.state.orders || !this.props.product) {
            return 0;
        }
        const amounts = this.state.orders.map((order) => {
            const product = order.products.find((productSearch) => {
                return productSearch.id === this.props.product.id;
            });
            return product.amount;
        });
        return amounts.reduce((partialSum, amount) => partialSum + amount, 0);
    }

    didClickAllOrdersCheckbox() {
        this.setState(({ selectedOrders, orders }) => {
            if(!orders) {
                return {};
            }
            if(selectedOrders.length === orders.length) {
                return { selectedOrders: [] }
            }
            return { selectedOrders: orders.map((order) => order.id) }
        })
    }

    didClickOrderCheckbox(order) {
        this.setState(({ selectedOrders }) => {
            if(selectedOrders.includes(order.id)) {
                let index = selectedOrders.indexOf(order.id);
                if (index !== -1) {
                    selectedOrders.splice(index, 1);
                }
            } else {
                selectedOrders.push(order.id);
            }
            return { selectedOrders: [...selectedOrders] }
        })
    }

    render() {
        const {
            product
        } = this.props;
        const {
            orders,
            selectedOrders,
            error,
            exportLoading
        } = this.state;
        const timesOrdered = this.getTimesOrdered();
        return (
            <React.Fragment>
                <div className="d-flex flex-row align-items-md-center mb-4">
                    <div className="flex-grow-1">
                        { orders && product ? (
                            timesOrdered === orders.length ? (
                                <p className="mb-0">
                                    Dit product is in totaal { timesOrdered } keer besteld.
                                </p>
                            ) : (
                                <p className="mb-0">
                                    Dit product is in totaal { timesOrdered } keer besteld in { orders.length } bestellingen.
                                </p>
                            )
                        ) : (
                            <Skeleton width={ 400 }/>
                        )}
                    </div>
                    <div>
                        { selectedOrders.length > 0 && (
                            <OverlayTrigger overlay={
                                <Tooltip id="reset">Download CSV export</Tooltip>
                            }>
                                <Button
                                    variant="secondary"
                                    className="float-right ml-3"
                                    onClick={ this.exportOrders }
                                    disabled={ exportLoading }
                                >
                                    { exportLoading && (
                                        <Spinner animation="border" size="sm" className="mr-2"/>
                                    )}
                                    <i className="fas fa-fw fa-file-csv"/>
                                </Button>
                            </OverlayTrigger>
                        )}
                    </div>
                </div>
                <Table size="sm" className="results" hover={ orders && orders.length > 0 }>
                    <thead className="thead-light">
                        <tr className="tr-sticky">
                            <th className="text-center">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={ "orderAll" }
                                        onChange={ this.didClickAllOrdersCheckbox }
                                        checked={ (orders && selectedOrders.length === orders.length) ?? false }
                                        disabled={ exportLoading || !orders || orders.length === 0 }
                                    />
                                    <label className="custom-control-label" htmlFor={ "orderAll" }/>
                                </div>
                            </th>
                            <th className="text-center">#</th>
                            <th className="text-left">Status</th>
                            <th className="text-left">Naam</th>
                            <th className="text-left">Adres</th>
                            <th className="text-right">Aantal producten</th>
                            <th className="text-right">Totaal</th>
                            <th className="text-left" style={{ minWidth: "110px" }}>Datum</th>
                        </tr>
                    </thead>
                    <tbody>
                        { error ? (
                            <tr>
                                <td colSpan={ 8 }>
                                    <Alert variant="danger" className="mb-0">{ error }</Alert>
                                </td>
                            </tr>
                        ) : orders === null ? (
                            <tr>
                                <td colSpan={ 8 }>
                                    <Loading/>
                                </td>
                            </tr>
                        ) : orders.length === 0 ? (
                            <tr>
                                <td colSpan={ 8 } className="text-center">
                                    <h1><i className="fas fa-cash-register"/></h1>
                                    <h4>Geen bestellingen</h4>
                                    <p>Dit product is nog niet besteld.</p>
                                </td>
                            </tr>
                        ) : orders.map((order) => {
                            const url = "/shop/" + this.context.currentShop.codeName + "/order/" + order.id;
                            return (
                                <tr key={ order.id } className="table-clickable-row">
                                    <th className="align-middle text-center">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={ "order" + order.id }
                                                onChange={ () => this.didClickOrderCheckbox(order) }
                                                checked={ selectedOrders.includes(order.id) }
                                                disabled={ exportLoading }
                                            />
                                            <label className="custom-control-label" htmlFor={ "order" + order.id }/>
                                        </div>
                                    </th>
                                    <th scope="row" className="align-middle text-center">
                                        <Link to={ url }>
                                            { order.id }
                                        </Link>
                                    </th>
                                    <td className="align-middle">
                                        <Link to={ url }>
                                            <StatusPill status={ order.status }/>
                                        </Link>
                                    </td>
                                    <td className="align-middle">
                                        <Link to={ url }>
                                            { order.deliveryFirstName + " " + order.deliveryLastName }
                                        </Link>
                                    </td>
                                    <td className="align-middle">
                                        <Link to={ url }>
                                            { order.deliveryStreet + " " + order.deliveryHouseNumber + ", " + order.deliveryCity }
                                        </Link>
                                    </td>
                                    <td className="align-middle text-right">
                                        <Link to={ url }>
                                            { order.products.length === 0 ? 0 : order.products.map((orderProduct) => orderProduct.amount).reduce((amount1, amount2) => amount1 + amount2) }
                                        </Link>
                                    </td>
                                    <td className="align-middle text-right" style={{ whiteSpace: "nowrap" }}>
                                        <Link to={ url }>
                                            <PriceFormatter price={ order.totalPrice }/>
                                        </Link>
                                    </td>
                                    <td className="align-middle">
                                        <Link to={ url }>
                                            <DateFormatter date={ order.orderDate }/>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
}
ProductDetailOrders.contextType = ShopsContext;
ProductDetailOrders.propTypes = {
    product: PropTypes.object
}

export default ProductDetailOrders;
