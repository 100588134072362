import React, {
    useCallback,
    useMemo,
    useRef,
    useState
} from "react";
import {
    closestCenter,
    DndContext,
    getFirstCollision,
    KeyboardSensor,
    PointerSensor,
    pointerWithin,
    rectIntersection,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import PropTypes from "prop-types";

import CourseChapterCard from "./CourseChapterCard";
import CourseAddChapterCard from "./CourseAddChapterCard";

function CourseContentSorting({ chapters, setChapters, onEditChapter, onAddChapter, onDeleteChapter, onEditLesson, onAddLesson, onDeleteLesson }) {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const [currentActiveId, setCurrentActiveId] = useState(null);
    const lastOverId = useRef(null);
    const recentlyMovedToNewContainer = useRef(false);

    const handleDragEnd = useMemo(() => {
        return ({ active, over }) => {
            if(!active || !over) {
                return;
            }
            if(active.id === over.id) {
                return;
            }
            const activeType = active.id.split("-")[0];
            const overType = over.id.split("-")[0];
            if(activeType !== overType) {
                return;
            }
            const activeId = parseInt(active.id.split("-")[1], 10);
            const overId = parseInt(over.id.split("-")[1], 10);
            if(activeType === "chapter") {
                setChapters((prevChapters) => {
                    const oldIndex = prevChapters.findIndex((chapter) => chapter.id === activeId);
                    const newIndex = prevChapters.findIndex((chapter) => chapter.id === overId);

                    return arrayMove(prevChapters, oldIndex, newIndex);
                });
            } else if(activeType === "lesson") {
                setChapters((prevChapters) => {
                    return prevChapters.map((chapter) => {
                        const includesLesson = chapter.lessons.find((lesson) => lesson.id === activeId);
                        if(!includesLesson) {
                            return chapter;
                        }
                        const oldIndex = chapter.lessons.findIndex((lesson) => lesson.id === activeId);
                        const newIndex = chapter.lessons.findIndex((lesson) => lesson.id === overId);

                        return {
                            ...chapter,
                            lessons: arrayMove(chapter.lessons, oldIndex, newIndex)
                        };
                    });
                });
            }
        }
    }, []);
    const findChapterForLesson = useCallback((lessonId) => {
        return chapters.find((chapter) => {
            return chapter.lessons.find((lesson) => lesson.id === lessonId);
        });
    }, [chapters]);
    const handleDragOver = useMemo(() => {
        return ({ active, over }) => {
            if(!over || !active) {
                return;
            }
            if(active.id === over.id) {
                return;
            }
            const activeType = active.id.split("-")[0];
            const overType = over.id.split("-")[0];
            if(activeType === "chapter") {
                return;
            }
            const activeId = parseInt(active.id.split("-")[1], 10);
            const activeChapter = findChapterForLesson(activeId);
            let overChapter = null;
            if(overType === "chapter") {
                const overId = parseInt(over.id.split("-")[1], 10);
                overChapter = chapters.find((chapter) => chapter.id === overId);
            } else {
                const overId = parseInt(over.id.split("-")[1], 10);
                overChapter = findChapterForLesson(overId);
            }
            if(!overChapter || activeChapter.id === overChapter.id) {
                return;
            }
            setChapters((prevChapters) => {
                const prevActiveChapter = prevChapters.find((chapter) => chapter.id === activeChapter.id);
                const prevActiveLesson = prevActiveChapter.lessons.find((lesson) => lesson.id === activeId);
                return prevChapters.map((prevChapter) => {
                    if(prevChapter.id === activeChapter.id) {
                        return {
                            ...prevChapter,
                            lessons: prevChapter.lessons.filter((lesson) => lesson.id !== activeId)
                        };
                    }
                    if(prevChapter.id === overChapter.id) {
                        return {
                            ...prevChapter,
                            lessons: prevChapter.lessons.concat(prevActiveLesson)
                        };
                    }
                    return prevChapter;
                });
            });
        }
    }, [findChapterForLesson]);

    const collisionDetectionStrategy = useCallback((args) => {
        if(currentActiveId && currentActiveId.includes("chapter")) {
            return closestCenter({
                ...args,
                droppableContainers: args.droppableContainers.filter(
                    (container) => container.id.includes("chapter")
                ),
            });
        }

        // Start by finding any intersecting droppable
        const pointerIntersections = pointerWithin(args);
        // If there are droppables intersecting with the pointer, return those
        const intersections = pointerIntersections.length > 0 ? pointerIntersections : rectIntersection(args);
        let overId = getFirstCollision(intersections, 'id');

        if (overId !== null) {
            if (overId.includes("chapter")) {
                const chapterId = parseInt(overId.split("-")[1], 10);
                const chapter = chapters.find((findChapter) => findChapter.id === chapterId);

                // If a container is matched and it contains items (columns 'A', 'B', 'C')
                if (chapter.lessons.length > 0) {
                    // Return the closest droppable within that container
                    overId = closestCenter({
                        ...args,
                        droppableContainers: args.droppableContainers.filter(
                            (container) =>
                                container.id !== overId &&
                                container.id.includes("lesson")
                        ),
                    })[0].id;
                }
            }

            lastOverId.current = overId;

            return [{id: overId}];
        }

        // When a draggable item moves to a new container, the layout may shift
        // and the `overId` may become `null`. We manually set the cached `lastOverId`
        // to the id of the draggable item that was moved to the new container, otherwise
        // the previous `overId` will be returned which can cause items to incorrectly shift positions
        if (recentlyMovedToNewContainer.current) {
            lastOverId.current = currentActiveId;
        }

        // If no droppable is matched, return the last match
        return lastOverId.current ? [{id: lastOverId.current}] : [];
    }, [currentActiveId, chapters]);

    if(!chapters) {
        return null
    }
    return (
        <React.Fragment>
            <DndContext
                sensors={ sensors }
                collisionDetection={ collisionDetectionStrategy }
                onDragEnd={ handleDragEnd }
                onDragStart={ ({ active }) => {
                    setCurrentActiveId(active.id);
                }}
                onDragOver={ handleDragOver }
            >
                <SortableContext
                    items={ chapters.map((chapter) => ({ ...chapter, id: "chapter-" + chapter.id })) }
                    strategy={ verticalListSortingStrategy }
                >
                    { chapters.map((chapter) => (
                        <CourseChapterCard
                            chapter={ chapter }
                            key={ chapter.id }
                            onEditChapter={ onEditChapter }
                            onDeleteChapter={ onDeleteChapter }
                            onEditLesson={ onEditLesson }
                            onAddLesson={ onAddLesson }
                            onDeleteLesson={ onDeleteLesson}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            <CourseAddChapterCard
                onClick={ onAddChapter }
            />
        </React.Fragment>
    )
}
CourseContentSorting.propTypes = {
    chapters: PropTypes.array,
    setChapters: PropTypes.func.isRequired,
    onEditChapter: PropTypes.func,
    onAddChapter: PropTypes.func,
    onDeleteChapter: PropTypes.func,
    onEditLesson: PropTypes.func,
    onAddLesson: PropTypes.func,
    onDeleteLesson: PropTypes.func
}

export default React.memo(CourseContentSorting);
