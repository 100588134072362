import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import Loading from "../../components/Loading";
import TagPill from "../../components/pills/TagPill";
import Helmet from "../../components/Helmet";
import DateFormatter from "../../components/DateFormatter";

class ShopUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shopUsers: null,
            error: null
        }
    }

    componentDidMount() {
        this.getShopUsers();
    }

    getShopUsers() {
        this.setState({ shopUsers: null, error: null });
        axios.post("/getShopUsers", { shopId: this.context.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    const shopUsers = response.data.shopUsers.sort((shopUser1, shopUser2) => {
                        return shopUser1.name > shopUser2.name ? 1 : -1;
                    })
                    this.setState({ shopUsers });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    render() {
        const {
            shopUsers,
            error
        } = this.state;
        const baseUrl = "/shop/" + this.context.currentShop.codeName;
        return (
            <React.Fragment>
                <Helmet title="Gebruikers"/>
                <Title preTitle="Overzicht">
                    Gebruikers
                    <small>
                        <TagPill className="ml-2">{ shopUsers ? shopUsers.length : 0 }</TagPill>
                    </small>
                </Title>
                <Table size="sm" className="results" hover={ shopUsers && shopUsers.length > 0 }>
                    <thead className="thead-light">
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-left">Naam</th>
                        <th className="text-left">Email</th>
                        <th className="text-left">Geregistreerd op</th>
                    </tr>
                    </thead>
                    <tbody>
                    { error ? (
                        <tr>
                            <td colSpan={ 6 }>
                                <Alert variant="danger" className="mb-0">{ error }</Alert>
                            </td>
                        </tr>
                    ) : shopUsers === null ? (
                        <tr>
                            <td colSpan={ 6 }>
                                <Loading/>
                            </td>
                        </tr>
                    ) : shopUsers.length === 0 ? (
                        <tr>
                            <td colSpan={ 6 } className="text-center">
                                <h1><i className="fas fa-shopping-basket"/></h1>
                                <h4>Geen gebruikers</h4>
                                <p>Er zijn nog geen klanten met een account in de shop.</p>
                            </td>
                        </tr>
                    ) : shopUsers.map((shopUser) => {
                        const url = baseUrl + "/shop-user/" + shopUser.id;
                        return (
                            <tr key={ shopUser.id } className="table-clickable-row">
                                <th scope="row" className="align-middle text-center">
                                    <Link to={ url }>
                                        { shopUser.id }
                                    </Link>
                                </th>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { shopUser.name }
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { shopUser.email }
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        <DateFormatter date={ shopUser.registerDate }/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}
ShopUsers.contextType = ShopsContext;

export default ShopUsers;
