import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import ShopsContext from "../../../../context/ShopsContext";

function ResendInvoiceModal({ show, handleClose, order }) {
    const shopsContext = useContext(ShopsContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if(!show) {
            return;
        }
        setLoading(false);
        setError(null);
        setSuccess(false);
    }, [show]);
    const onClickSend = useCallback(async () => {
        setLoading(true);
        setError(null);
        setSuccess(false);
        try {
            await axios.post("/resendOrderInvoice", {
                shopId: shopsContext.currentShop.id,
                orderId: order.id,
            });
            setSuccess(true);
        } catch(requestError) {
            console.error(requestError);
            setError("Er is een fout opgetreden bij het versturen van de factuur. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [order, shopsContext]);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Factuur versturen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { success ? (
                    <div className="text-center">
                        <h1 className="text-success"><i className="fas fa-paper-plane"/></h1>
                        <h4>Factuur verstuurd per email!</h4>
                    </div>
                ) : (
                    <React.Fragment>
                        { error && (
                            <Alert variant="danger">{ error }</Alert>
                        )}
                        <p className="mb-0">
                            Weet je zeker dat je de factuur opnieuw wilt versturen naar { order?.email }?
                        </p>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                {success ? (
                    <Button variant="secondary" onClick={handleClose} disabled={loading}>
                        Sluiten
                    </Button>
                ) : (
                    <React.Fragment>
                        <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ onClickSend } disabled={ loading }>
                            { loading && (
                                <Spinner animation="border" size="sm" className="mr-2"/>
                            )}
                            Factuur versturen
                        </Button>
                    </React.Fragment>
                )}
            </Modal.Footer>
        </Modal>
    );
}
ResendInvoiceModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
};

export default React.memo(ResendInvoiceModal);
