import React from "react";
import {
    createRoot
} from "react-dom/client";

import * as Sentry from "@sentry/react";

import axios from "axios";
import { stringify } from "qs";

import moment from "moment";
import "moment/locale/nl";

import * as serviceWorker from "./serviceWorker";

import App from "./App";

import "./scss/main.scss";

const development = process.env.REACT_APP_ENVIRONMENT === "development";

if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://38380a2d26db4e309f8abd76540f00e7@sentry1.zsnode.com/6",
        autoSessionTracking: true,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            /^https:\/\/api\.shopcrate\.nl/,
            /^https:\/\/dev\.api\.shopcrate\.nl/,
        ],
    });
}

moment.locale("nl");

if(development) {
    axios.defaults.baseURL = "/v1/backoffice/";
} else {
    const SHOPCRATE_API_URL = process.env.REACT_APP_SHOPCRATE_API_URL ? process.env.REACT_APP_SHOPCRATE_API_URL : "dev.api.shopcrate.nl"
    axios.defaults.baseURL = "https://" + SHOPCRATE_API_URL + "/v1/backoffice/";
}
axios.defaults.withCredentials = true;

axios.defaults.transformRequest = [(data) => {
    data = stringify(data);
    return data;
}];

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
