import React from "react";
import PropTypes from "prop-types";

function PriceSummaryRow({ title, subtitle, children, className, style }) {
    return (
        <div
            className={ "d-flex justify-content-end text-right" + (className ? " " + className : "") }
            style={ style }
        >
            <div style={{ width: "150px" }}>
                <b>
                    { title }
                </b>
                { subtitle && (
                    <React.Fragment>
                        <br/>
                        <span className="text-muted">
                            { subtitle }
                        </span>
                    </React.Fragment>
                )}
            </div>
            <div style={{ width: "150px" }}>
                <b>
                    { children }
                </b>
            </div>
        </div>
    );
}
PriceSummaryRow.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    style: PropTypes.object
};

export default React.memo(PriceSummaryRow);
