import React, {
    useState
} from "react";
import PropTypes from "prop-types";
import {
    Button
} from "react-bootstrap";
import {
    ErrorBoundary
} from "@sentry/react";

import ChartModal from "./ChartModal";
import ChartError from "./ChartError";

function ChartCard({ title, children }) {
    const [showModal, setShowModal] = useState(false);
    return (
        <React.Fragment>
            <ChartModal
                title={ title }
                show={ showModal }
                handleClose={ () => setShowModal(false) }
            >
                <ErrorBoundary fallback={
                    <ChartError/>
                }>
                    { children }
                </ErrorBoundary>
            </ChartModal>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="card-title">{ title }</h5>
                        </div>
                        <div className="ml-2">
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={ () => setShowModal(true) }
                            >
                                <i className="fas fa-expand fa-fw"/>
                            </Button>
                        </div>
                    </div>
                    <div className="w-100" style={{ height: "400px" }}>
                        <ErrorBoundary fallback={
                            <ChartError/>
                        }>
                            { children }
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
ChartCard.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.object
    ]),
    children: PropTypes.element
}

export default React.memo(ChartCard);
