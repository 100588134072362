import React, {
    useMemo,
    useState
} from "react";
import PropTypes from "prop-types";
import {
    Button
} from "react-bootstrap";

import OAuthDisconnectModal from "./OAuthDisconnectModal";

function OAuthConnectionCard({ service, enabled, onClickSetup, onClickRemove, loading }) {
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);

    const openModal = useMemo(() => () => setShowDisconnectModal(true), []);
    const handleClose = useMemo(() => () => setShowDisconnectModal(false), []);

    const onClickRemoveModal = useMemo(() => {
        return () => {
            onClickRemove(() => handleClose());
        }
    }, [onClickRemove]);

    return (
        <React.Fragment>
            <OAuthDisconnectModal
                service={ service }
                show={ showDisconnectModal }
                handleClose={ handleClose }
                onSubmit={ onClickRemoveModal }
                loading={ loading }
            />
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">
                        { service }
                    </h5>
                    { enabled ? (
                        <React.Fragment>
                            <p className="card-text">
                                <i className="fas fa-check mr-2 text-success"/>
                                { service } is ingesteld!
                            </p>
                            { onClickRemove && (
                                <Button variant="danger" disabled={ loading } onClick={ openModal }>
                                    <i className="fas fa-unlink mr-2"/>
                                    Loskoppelen
                                </Button>
                            )}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <p className="card-text">
                                <i className="fas fa-times mr-2 text-danger"/>
                                { service } is niet ingesteld.
                            </p>
                            { onClickSetup && (
                                <Button variant="success" disabled={ loading } onClick={ onClickSetup }>
                                    <i className="fas fa-link mr-2"/>
                                    { service } koppelen
                                </Button>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}
OAuthConnectionCard.propTypes = {
    service: PropTypes.string.isRequired,
    enabled: PropTypes.bool,
    onClickSetup: PropTypes.func,
    onClickRemove: PropTypes.func,
    loading: PropTypes.bool
}

export default React.memo(OAuthConnectionCard);
