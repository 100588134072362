import React from "react";
import axios from "axios";
import {
    Alert,
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";
import {
    sortableContainer,
    sortableElement
} from "react-sortable-hoc";
import arrayMove from "array-move";

import ShopsContext from "../../../context/ShopsContext";
import Loading from "../../../components/Loading";
import UploadProductImageModal from "./modal/UploadProductImageModal";
import ProductImageCard from "./components/ProductImageCard";

const SortableItem = sortableElement(({children}) => (
    <div>{children}</div>
));

const SortableContainer = sortableContainer(({children}) => (
    <div>{children}</div>
));

class ProductDetailImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            disabledImages: null,
            error: null,
            success: null,
            savingError: null,
            changedOrder: false,
            savingOrder: false,

            showUploadImageModal: false
        }
        this.setImageEnabled = this.setImageEnabled.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.setImagesOrder = this.setImagesOrder.bind(this);
    }

    componentDidMount() {
        if(this.props.product) {
            this.setImages(this.props.product);
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.product !== this.props.product) {
            this.setImages(this.props.product);
        }
    }

    setImages(product) {
        if(!product) {
            this.setState({
                images: null,
                disabledImages: null,
                error: null,
                success: null,
                savingError: null,
                changedOrder: false,
                savingOrder: false
            });
            return;
        }
        const images = product.images;
        const enabledImages = images.filter((image) => {
            return image.enabled;
        });
        const disabledImages = images.filter((image) => {
            return !image.enabled;
        });
        this.setState({
            images: enabledImages,
            disabledImages
        });
    }

    setImageEnabled(imageId, enabled) {
        this.setState({ savingError: null, success: null });
        axios.post("/setProductImageEnabled", { shopId: this.context.currentShop.id, productId: this.props.product.id, imageId, state: enabled ? 1 : 0 })
            .then((response) => {
                if(response.data.valid) {
                    this.setImages(response.data.product);
                    this.props.setProduct(response.data.product);
                    this.setState({ success: "Foto " + (enabled ? "ingeschakeld" : "uitgeschakeld") + "." });
                } else {
                    this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setImagesOrder() {
        this.setState({ savingOrder: true, savingError: null, success: null });
        const imageOrder = this.state.images.map((image) => image.id).join(",");
        axios.post("/setProductImagesOrder", { shopId: this.context.currentShop.id, productId: this.props.product.id, imageOrder })
            .then((response) => {
                if(response.data.valid) {
                    this.setImages(response.data.product);
                    this.props.setProduct(response.data.product);
                    this.setState({ changedOrder: false, success: "Foto volgorde opgeslagen." });
                } else {
                    this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ savingOrder: false });
            });
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState(({images}) => {
            return { images: arrayMove(images, oldIndex, newIndex), changedOrder: true }
        });
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(!this.state.images) {
            return (
                <Loading/>
            )
        }
        return (
            <React.Fragment>
                <UploadProductImageModal
                    show={ this.state.showUploadImageModal }
                    handleClose={ () => { this.setState({ showUploadImageModal: false }) } }
                    product={ this.props.product }
                    imageUploaded={ this.props.getProduct }
                />

                { this.state.images.length === 0 && this.state.disabledImages.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-shopping-basket"/></h1>
                        <h4>Geen foto&apos;s</h4>
                        <p>Dit product heeft nog geen foto&apos;s.</p>
                        <Button variant="primary" onClick={ () => { this.setState({ showUploadImageModal: true }) } }>
                            <i className="fas fa-plus mr-2"/>
                            Eerste foto uploaden
                        </Button>
                    </div>
                ) : (
                    <React.Fragment>
                        { this.state.success && (
                            <Alert variant="success">{ this.state.success }</Alert>
                        )}
                        { this.state.savingError && (
                            <Alert variant="danger">{ this.state.savingError }</Alert>
                        )}
                        <Button variant="primary" className="mb-2 mr-2" onClick={ this.setImagesOrder } disabled={ this.state.savingOrder || !this.state.changedOrder }>
                            <i className="fas fa-save mr-2"/>
                            Volgorde opslaan
                        </Button>
                        <Button variant="secondary" className="mb-2 mr-2" onClick={ () => { this.setState({ showUploadImageModal: true }) } } disabled={ this.state.savingOrder }>
                            <i className="fas fa-plus mr-2"/>
                            Foto uploaden
                        </Button>
                        { this.state.images.length === 0 ? (
                            <p className="mt-3">Geen ingeschakelde afbeeldingen.</p>
                        ) : (
                            <SortableContainer onSortEnd={ this.onSortEnd }>
                                { this.state.images.map((image, index) => (
                                    <SortableItem key={ image.id } index={ index } disabled={ this.state.savingOrder }>
                                        <ProductImageCard
                                            image={ image }
                                            enabled={ true }
                                            setImageEnabled={ this.setImageEnabled }
                                        />
                                    </SortableItem>
                                ))}
                            </SortableContainer>
                        )}
                        { this.state.disabledImages.length > 0 && (
                            <React.Fragment>
                                <h4 className="mt-3">Uitgeschakelde foto&apos;s</h4>
                                { this.state.disabledImages.map((image) => (
                                    <ProductImageCard
                                        key={ image.id }
                                        image={ image }
                                        enabled={ false }
                                        setImageEnabled={ this.setImageEnabled }
                                    />
                                ))}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
ProductDetailImages.contextType = ShopsContext;
ProductDetailImages.propTypes = {
    product: PropTypes.object,
    setProduct: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired
}

export default ProductDetailImages;
