import React, {
    useContext
} from "react";
import PropTypes from "prop-types";
import {
    Link
} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/DetailOverviewRow";
import ShopsContext from "../../../context/ShopsContext";

function CourseDetailOverview({ course }) {
    const shopsContext = useContext(ShopsContext);
    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <table className="table table-borderless mb-0">
                        <tbody>
                        <DetailOverviewRow title="Titel">
                            { course ? course.title : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Beschrijving">
                            { course ? course.description : <Skeleton count={ 2 }/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Toegangsduur">
                            { course ? (
                                course.maxAccessDuration + " dagen"
                            ) : <Skeleton count={ 2 }/> }
                        </DetailOverviewRow>
                        </tbody>
                    </table>
                </div>
            </div>
            <h3 className="mt-4">Inhoud</h3>
            { !course ? (
                <Skeleton height={ 300 } count={ 3 }/>
            ) : course.chapters.length === 0 ? (
                <div className="text-center">
                    <h1><i className="fas fa-graduation-cap"/></h1>
                    <h4>Geen inhoud</h4>
                    <p>Deze cursus is nog leeg. Begin met vullen.</p>
                    <Link to={ "/shop/" + shopsContext.currentShop.codeName + "/course/" + course.id + "/content" } className="btn btn-primary">
                        <i className="fas fa-edit mr-2"/>
                        Cursus inhoud bewerken
                    </Link>
                </div>
            ) : course.chapters.map((chapter) => (
                <div className="card mb-3" key={ chapter.id }>
                    <div className="card-header">
                        <b>{ chapter.title }</b>
                    </div>
                    <div className="card-body px-2 py-1">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            { chapter.lessons.map((lesson) => (
                                <tr key={ lesson.id }>
                                    <td>
                                        { lesson.title }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </React.Fragment>
    )
}
CourseDetailOverview.propTypes = {
    course: PropTypes.object
}

export default React.memo(CourseDetailOverview);
