import React, {
    useEffect,
    useMemo
} from "react";
import moment from "moment";
import {
    Button,
    ButtonGroup
} from "react-bootstrap";
import {
    useLocalStorage,
    useWindowSize
} from "@zandor300/backoffice-framework";

import OrderRevenueChart from "../../../charts/OrderRevenueChart";
import ChartCard from "../../../components/charts/ChartCard";

const periodGroupings = {
    week: { name: "Week", titleString: "weken" },
    month: { name: "Maand", titleString: "maanden" },
    quarter: { name: "Kwartaal", titleString: "kwartalen" }
};

function DashboardOrderRevenueChart() {
    const windowSize = useWindowSize();
    const [periodGrouping, setPeriodGrouping] = useLocalStorage("dashboard-revenue-periodGrouping", "week");

    useEffect(() => {
        if(!periodGroupings[periodGrouping]) {
            setPeriodGrouping("week");
        }
    }, []);

    const currentPeriodGrouping = useMemo(() => {
        return periodGroupings[periodGrouping];
    }, [periodGrouping]);
    const chartPeriodAgo = useMemo(() => {
        const widthThreshold = 1100;
        const small = windowSize.width < widthThreshold;
        if(periodGrouping === "week") {
            return small ? 12 : 25;
        } else if(periodGrouping === "month") {
            return small ? 12 : 24;
        } else if(periodGrouping === "quarter") {
            return small ? 8 : 12;
        }
        return 12;
    }, [windowSize.width, periodGrouping]);
    const chartStartDate = useMemo(() => {
        if(periodGrouping === "week") {
            return moment().subtract(chartPeriodAgo, "weeks").startOf("week");
        } else if(periodGrouping === "month") {
            return moment().subtract(chartPeriodAgo, "months").startOf("month");
        } else if(periodGrouping === "quarter") {
            return moment().subtract(chartPeriodAgo, "quarters").startOf("quarter");
        }
        return moment().subtract(chartPeriodAgo, "weeks").startOf("week");
    }, [chartPeriodAgo, periodGrouping]);
    const chartEndDate = useMemo(() => {
        return moment();
    }, []);

    return (
        <ChartCard title={
            <div className="d-flex flex-column flex-md-row">
                <div className="flex-grow-1">
                    Omzet per { currentPeriodGrouping?.name.toLowerCase() } van de afgelopen { chartPeriodAgo } { currentPeriodGrouping?.titleString }{" "}
                    <small className="text-muted">excl. btw</small>
                </div>
                <div className="mt-2 mt-md-0 ml-md-3">
                    <ButtonGroup>
                        { Object.entries(periodGroupings).map(([key, value]) => (
                            <Button
                                key={ key }
                                variant={ key === periodGrouping ? "secondary" : "outline-secondary" }
                                size="sm"
                                onClick={ () => setPeriodGrouping(key) }
                            >
                                { value.name }
                            </Button>
                        ))}
                    </ButtonGroup>
                </div>
            </div>
        }>
            <OrderRevenueChart
                periodGrouping={ periodGrouping }
                startDate={ chartStartDate }
                endDate={ chartEndDate }
            />
        </ChartCard>
    );
}

export default React.memo(DashboardOrderRevenueChart);
