import React, {
    useCallback,
    useContext,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import ShopsContext from "../../../../context/ShopsContext";

function RemoveProductCourseModal({ show, handleClose, product, courseToRemove, onRemoved }) {
    const shopsContext = useContext(ShopsContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const reset = useCallback(() => {
        setLoading(false);
        setError(null);
    }, []);

    const onRemove = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            await axios.post("/removeCourseFromProduct", {
                shopId: shopsContext.currentShop.id,
                productId: product.id,
                courseId: courseToRemove.id
            });
            handleClose();
            onRemoved?.();
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        }
        setLoading(false);
    }, [shopsContext, product, courseToRemove, handleClose, onRemoved]);

    return (
        <Modal show={ show } onHide={ handleClose } onShow={ reset }>
            <Modal.Header closeButton>
                <Modal.Title className="text-danger">
                    Cursus verwijderen uit product
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p className="text-danger">
                    Weet je zeker dat je de cursus <b>{ courseToRemove?.title }</b> wilt verwijderen uit product <b>{ product?.name }</b>?
                </p>
                <p className="text-danger mb-0">
                    Alle klanten die dit product al hebben gekocht zullen toegang verliezen tot deze cursus.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                    disabled={ loading }
                >
                    Annuleren
                </Button>
                <Button
                    variant="danger"
                    onClick={ onRemove }
                    disabled={ loading }
                >
                    { loading && (
                        <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                    )}
                    Verwijderen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
RemoveProductCourseModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    product: PropTypes.object,
    courseToRemove: PropTypes.object,
    onRemoved: PropTypes.func
}

export default React.memo(RemoveProductCourseModal);
