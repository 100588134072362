import React, {
    useMemo
} from "react";
import PropTypes from "prop-types";
import {
    Button
} from "react-bootstrap";

import ProductTypePill from "./ProductTypePill";
import ProductImageDataCard from "./ProductImageDataCard";

function ProductListItemCard({ product, onClickRemove }) {
    const removeButton = useMemo(() => (
        <Button variant="secondary" size="sm" onClick={ () => onClickRemove(product) }>
            <i className="fas fa-minus mr-2"/>
            Verwijderen
        </Button>
    ), [onClickRemove]);
    const image = product.images.length === 0 ? null : product.images[0];
    return (
        <ProductImageDataCard image={ image }>
            <div className="d-flex">
                <div className="flex-grow-1">
                    <h5 className="card-title mb-1">
                        { product.name }
                    </h5>
                    <p className="mb-1">
                        <ProductTypePill product={ product }/>
                    </p>
                    <div className="d-lg-none">
                        { removeButton }
                    </div>
                </div>
                <div className="d-none d-lg-block">
                    { removeButton }
                </div>
            </div>
        </ProductImageDataCard>
    )
}
ProductListItemCard.propTypes = {
    product: PropTypes.object.isRequired,
    onClickRemove: PropTypes.func
}

export default React.memo(ProductListItemCard);
