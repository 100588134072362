import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import {
    AuthenticatedUserContextPropTypes,
    withAuthenticatedUserContext
} from "../../../context/AuthenticatedUserContext";

class DisableOTPModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false
        }
        this.onShow = this.onShow.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        this.setState({ loading: true, error: null });
        axios.post("/disableOTP")
            .then((response) => {
                if(response.data.valid) {
                    this.props.handleClose();
                    this.props.authenticatedUserContext.setUser(response.data.user);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    onShow() {
        this.setState({ error: null, loading: false });
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        2FA uitschakelen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.error && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}

                    <p className="text-danger mb-0">
                        Weet je zeker dat je 2 Factor Authenticatie wilt uitschakelen? Hierdoor zal je account minder
                        goed beveiligd zijn!
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                        Annuleer
                    </Button>
                    <Button variant="danger" onClick={ this.onSubmit } disabled={ this.state.loading }>
                        { this.state.loading && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                        )}
                        Uitschakelen
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
DisableOTPModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    authenticatedUserContext: AuthenticatedUserContextPropTypes
}

export default withAuthenticatedUserContext(DisableOTPModal);
