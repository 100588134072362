import React, {
    useMemo
} from "react";
import PropTypes from "prop-types";

function ModalSuccess({
    icon,
    message,
    button
}) {
    const selectedIcon = useMemo(() => {
        if(!icon) {
            return "fa-solid fa-check";
        }
        return icon;
    }, [icon]);

    return (
        <div className="text-center">
            <h1 className="text-success">
                <i className={ selectedIcon }/>
            </h1>
            <h4>
                { message }
            </h4>
            { button && (
                button
            )}
        </div>
    )
}

ModalSuccess.propTypes = {
    icon: PropTypes.string,
    message: PropTypes.string.isRequired,
    button: PropTypes.node
}

export default React.memo(ModalSuccess);
