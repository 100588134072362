import React, {
    useMemo
} from "react";
import PropTypes from "prop-types";

import logo from "../img/shopcrate-logo.svg";
import logoWhite from "../img/shopcrate-logo-white.svg";
import logoIcon from "../img/shopcrate-logo-icon.svg";

function Logo({ white = false, icon = false, ...props }) {
    const src = useMemo(() => {
        if(white) {
            return logoWhite;
        }
        if(icon) {
            return logoIcon;
        }
        return logo;
    }, [white, icon]);
    return (
        <img src={ src } alt="ShopCrate" {...props}/>
    )
}
Logo.propTypes = {
    white: PropTypes.bool,
    icon: PropTypes.bool
}

export default React.memo(Logo);
