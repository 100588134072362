import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";

import DetailOverviewRow from "../../../../../components/DetailOverviewRow";
import DateFormatter from "../../../../../components/DateFormatter";
import ShopsContext from "../../../../../context/ShopsContext";

function CourseShopUserAccessItemCard({ accessItem, onClickToggleRevoked, loading }) {
    const shopsContext = useContext(ShopsContext);
    return (
        <div className="card mb-3">
            <div className="card-body p-3">
                <table className="table table-borderless table-sm mb-0">
                    <tbody>
                    <DetailOverviewRow title="Toegang">
                        {accessItem.hasAccess ? (
                            <i className="fas fa-check text-success fa-fw"/>
                        ) : (
                            <React.Fragment>
                                <i className="fas fa-times text-danger fa-fw mr-1"/>
                                {accessItem.revoked ? (
                                    <span className="text-danger">Ingetrokken</span>
                                ) : (
                                    <span className="text-danger">Verlopen</span>
                                )}
                            </React.Fragment>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Toegang sinds">
                        <DateFormatter date={accessItem.firstAccessDate} format="DD-MM-YYYY"/>
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Verloopdatum">
                        {accessItem.expirationDate ? (
                            <DateFormatter date={accessItem.expirationDate} format="DD-MM-YYYY"/>
                        ) : (
                            <i className="text-muted">Nooit</i>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Toegang via">
                        {accessItem.orderId !== null ? (
                            <Link
                                to={`/shop/${shopsContext.currentShop.codeName}/order/${accessItem.orderId}`}
                            >
                                Bestelling #{accessItem.orderId}
                                <i className="fas fa-chevron-right ml-2"/>
                            </Link>
                        ) : (
                            "Handmatig"
                        )}
                    </DetailOverviewRow>
                    { accessItem.canToggleRevoked && onClickToggleRevoked && (
                        <DetailOverviewRow>
                            { accessItem.revoked ? (
                                <Button
                                    variant="success"
                                    size="sm"
                                    onClick={onClickToggleRevoked}
                                    disabled={loading}
                                >
                                    <i className="fas fa-check mr-2"/>
                                    Toegang teruggeven
                                </Button>
                            ) : (
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={onClickToggleRevoked}
                                    disabled={loading}
                                >
                                    <i className="fas fa-times mr-2"/>
                                    Toegang intrekken
                                </Button>
                            )}
                        </DetailOverviewRow>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
CourseShopUserAccessItemCard.propTypes = {
    accessItem: PropTypes.object.isRequired,
    onClickToggleRevoked: PropTypes.func,
    loading: PropTypes.bool
};

export default React.memo(CourseShopUserAccessItemCard);
