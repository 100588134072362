import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../../components/Helmet";
import ShopsContext from "../../../context/ShopsContext";
import OrderDetailOverview from "./OrderDetailOverview";
import OrderDetailShipments from "./OrderDetailShipments";
import ReactRouterPropTypes from "react-router-prop-types";
import Error404 from "../../error/Error404";

function OrderDetail(props) {
    const shopsContext = useContext(ShopsContext);
    const orderId = props.match.params.orderId;
    const baseUrl = "/shop/" + shopsContext.currentShop.codeName + "/order/" + orderId;

    const [order, setOrder] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshOrder = useCallback(() => {
        setOrder(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getOrder", { shopId: shopsContext.currentShop.id, orderId })
            .then((response) => {
                if(response.data.valid) {
                    setOrder(response.data.order);
                } else {
                    setOrder(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setOrder(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [orderId]);

    useEffect(() => {
        if(isNaN(parseInt(orderId, 10))) {
            return;
        }
        refreshOrder();
    }, [orderId, refreshOrder]);

    const childProps = {
        orderId: parseInt(orderId, 10),
        order,
        error,
        setOrder,
        refreshOrder
    };

    if(isNaN(parseInt(orderId, 10)) || orderId < 1 || errorCode === "DOESNT_EXIST") {
        return <Error404/>
    }

    return (
        <React.Fragment>
            <Helmet title={ "Bestelling #" + orderId }/>

            <Title preTitle="Detail" noBottom>
                Bestelling <small className="text-muted">#{orderId}</small>
            </Title>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    <TabBar>
                        <TabBarItem to={ baseUrl }>
                            Overzicht
                        </TabBarItem>
                        { shopsContext.currentShop.entitlementShipments && (
                            <TabBarItem to={ baseUrl + "/shipments" }>
                                Verzendingen
                            </TabBarItem>
                        )}
                    </TabBar>

                    <Switch>
                        <Route path={ baseUrl } exact>
                            <OrderDetailOverview { ...childProps }/>
                        </Route>
                        { shopsContext.currentShop.entitlementShipments && (
                            <Route path={ baseUrl + "/shipments" } exact>
                                <OrderDetailShipments { ...childProps }/>
                            </Route>
                        )}

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: baseUrl,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                </React.Fragment>
            )}

        </React.Fragment>
    );
}
OrderDetail.propTypes = {
    match: ReactRouterPropTypes.match
}

export default React.memo(OrderDetail);
