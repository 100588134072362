import React, {
    useContext,
    useMemo,
    useState
} from "react";
import {
    Button
} from "react-bootstrap";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import DisableOTPModal from "./modal/DisableOTPModal";
import SetupOTPModal from "./modal/SetupOTPModal";

function AccountAuthentication() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const [showDisableOTPModal, setShowDisableOTPModal] = useState(false);
    const [showSetupOTPModal, setShowSetupOTPModal] = useState(false);

    const handleCloseDisableOTPModal = useMemo(() => {
        return () => {
            setShowDisableOTPModal(false);
        }
    }, []);
    const openDisableOTPModal = useMemo(() => {
        return () => {
            setShowDisableOTPModal(true);
        }
    }, []);

    const handleCloseSetupOTPModal = useMemo(() => {
        return () => {
            setShowSetupOTPModal(false);
        }
    }, []);
    const openSetupOTPModal = useMemo(() => {
        return () => {
            setShowSetupOTPModal(true);
        }
    }, []);

    return (
        <React.Fragment>
            <DisableOTPModal
                show={ showDisableOTPModal }
                handleClose={ handleCloseDisableOTPModal }
            />
            <SetupOTPModal
                show={ showSetupOTPModal }
                handleClose={ handleCloseSetupOTPModal }
            />

            <div className="card mb-2">
                <div className="card-body">
                    <h5 className="card-title">Inloggegevens</h5>
                    <p className="card-text">Coming soon!</p>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <h5 className="card-title">2 Factor Authenticatie</h5>
                    { authenticatedUserContext.user.otpEnabled ? (
                        <React.Fragment>
                            <p className="text-success">
                                <i className="fas fa-check mr-2"/>
                                2 Factor Authenticatie is ingeschakeld op je account.
                            </p>
                            <p>
                                Schakel 2 Factor Authenticatie eerst uit om het opnieuw in te stellen.
                            </p>
                            <Button variant="danger" onClick={ openDisableOTPModal }>
                                2FA uitschakelen
                            </Button>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <p className="text-danger">
                                <i className="fas fa-times mr-2"/>
                                2 Factor Authenticatie is niet ingeschakeld op je account.
                            </p>
                            <Button variant="primary" onClick={ openSetupOTPModal }>
                                2FA instellen
                            </Button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(AccountAuthentication);
