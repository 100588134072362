import React, {
    useCallback,
    useContext,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import ShopsContext from "../../../../context/ShopsContext";

function CourseLessonDeleteModal({ lesson, show, onLessonDeleted, handleClose }) {
    const shopsContext = useContext(ShopsContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onShow = useCallback(() => {
        setError(null);
        setLoading(false);
    }, []);
    const handleDelete = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            await axios.post("/deleteCourseLesson", {
                shopId: shopsContext.currentShop.id,
                courseLessonId: lesson.id
            });
            onLessonDeleted(lesson);
            handleClose();
        } catch(requestError) {
            Sentry.captureException(requestError);
            setError("Er is een fout opgetreden. Probeer het later nog eens.");
        }
        setLoading(false);
    }, [lesson, onLessonDeleted, handleClose, shopsContext.currentShop]);

    return (
        <Modal show={ show } onHide={ handleClose } onShow={ onShow }>
            <Modal.Header closeButton>
                <Modal.Title className="text-danger">
                    Les verwijderen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p className="mb-0">
                    Weet je zeker dat je de les <strong>{ lesson?.title }</strong> wilt verwijderen?
                    <br/>
                    Dit kan niet teruggedraaid worden.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Annuleren
                </Button>
                <Button variant="danger" onClick={ handleDelete } disabled={ loading }>
                    { loading && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Verwijderen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
CourseLessonDeleteModal.propTypes = {
    lesson: PropTypes.object,
    show: PropTypes.bool,
    onLessonDeleted: PropTypes.func,
    handleClose: PropTypes.func
}

export default React.memo(CourseLessonDeleteModal);
