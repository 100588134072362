import React, {
    useContext
} from "react";
import {
    SidebarContext
} from "@zandor300/backoffice-framework";

function ShopCrateLogo() {
    const sidebarContext = useContext(SidebarContext);

    let svgClassNames = ["logo-shopcrate"];
    if(sidebarContext.collapsed && !sidebarContext.mobile) {
        svgClassNames.push("collapsed");
    }

    const pathStyle = { fill: "#FFFFFF" };

    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 422.6" className={ svgClassNames.join(" ") } preserveAspectRatio="xMinYMid slice">
            <g className="logo-shopcrate-crate">
                <path style={ pathStyle } d="M350.3,66.6c-14.9-2.5-42.7,5-59.7,14.5C276.5,73.2,255.3,67,240,66.6c1.7,1.7,3.3,3.3,4.6,5.4
                    c17.4,24.9,21.1,59.7,18.2,86.6h131.8C399.2,125.8,390.5,73.6,350.3,66.6L350.3,66.6z M173.7,41.7c9.1-9.9,12-26.5,10.8-41.5
                    C173.7-0.6,155,0.2,143,11c-13.7,13.7-11.2,38.1-10.8,41.5C148,53.3,163.7,50.8,173.7,41.7z M332,41.7c9.1-9.9,12-26.5,10.8-41.5
                    c-10.8-0.8-29.4,0-41.5,10.8c-13.7,13.7-11.2,38.1-10.8,41.5C305.9,53.3,322.1,50.8,332,41.7z M191.9,66.6
                    c-14.9-2.5-42.7,5-59.7,14.5c-17-9.5-44.8-17-59.7-14.5c-40.2,7-48.9,59.7-44.4,92h208.5C240.8,125.8,232.1,73.6,191.9,66.6
                    L191.9,66.6z"/>
            </g>
            <g className="logo-shopcrate-apples">
                <path style={ pathStyle } d="M409.5,184.7H13.3C5.8,184.7,0,190.5,0,198v92.4h422.8V198C422.8,190.9,417,184.7,409.5,184.7z M52.6,251
                    c-7.5,0-13.3-5.8-13.3-13.3s5.8-13.3,13.3-13.3s13.3,5.8,13.3,13.3S60.1,251,52.6,251z M369.7,251c-7.5,0-13.3-5.8-13.3-13.3
                    s5.8-13.3,13.3-13.3s13.3,5.8,13.3,13.3S377.2,251,369.7,251z M0,409.4c0,7.5,5.8,13.3,13.3,13.3h396.3c7.5,0,13.3-5.8,13.3-13.3
                    V317H0C0,316.9,0,409.4,0,409.4z M369.7,356.7c7.5,0,13.3,5.8,13.3,13.3s-5.8,13.3-13.3,13.3s-13.3-5.8-13.3-13.3
                    C356.9,362.5,362.7,356.7,369.7,356.7z M52.6,356.7c7.5,0,13.3,5.8,13.3,13.3s-5.8,13.3-13.3,13.3s-13.3-5.8-13.3-13.3
                    C39.8,362.5,45.6,356.7,52.6,356.7z"/>
            </g>
            <g className="logo-shopcrate-text">
                <path style={ pathStyle } d="M512.2,292.6l16.9-25c8.2,5.6,16.6,10.5,25.3,14.6s17.8,6.1,27.3,6.1c5.1,0,9.5-0.6,13.4-1.9s7.1-2.9,9.8-4.9
                    s4.7-4.3,6-6.9s2-5.2,2-7.7c0-3.3-1-6.1-3-8.4s-4.7-4.2-8-5.7s-7.3-2.7-11.8-3.8c-4.5-1-9.3-2-14.2-2.9c-3.3-0.6-7.1-1.3-11.4-2.1
                    c-4.3-0.8-8.8-1.9-13.4-3.3c-4.6-1.4-9.1-3.3-13.6-5.6s-8.5-5.2-12-8.7c-3.6-3.5-6.4-7.7-8.6-12.5c-2.2-4.8-3.2-10.5-3.2-17.1
                    c0-9.5,1.9-17.4,5.8-23.7c3.9-6.3,8.8-11.3,14.8-14.9c6-3.6,12.7-6.2,20.1-7.7c7.4-1.5,14.5-2.2,21.3-2.2s12.9,0.4,18.3,1.3
                    c5.4,0.9,10.3,2.2,14.9,3.9c4.6,1.7,9.1,3.9,13.5,6.5s9.1,5.7,14.2,9.3l-17.4,24.5c-7.7-5.4-15.2-9.4-22.3-12.3
                    c-7.1-2.8-14.6-4.2-22.5-4.2c-2.7,0-5.7,0.3-9.1,1c-3.4,0.7-6.7,1.7-9.7,3.2s-5.6,3.4-7.6,5.7s-3,5.1-3,8.4c0,3.3,1.2,6,3.6,8.2
                    c2.4,2.2,5.4,4.1,9.1,5.6c3.7,1.5,7.8,2.8,12.3,3.8s8.8,2.1,12.9,3.1c3.1,0.7,6.8,1.5,11,2.2s8.6,1.8,13.2,3.1
                    c4.5,1.3,9,3.1,13.5,5.4c4.5,2.2,8.4,5.1,11.9,8.6s6.3,7.8,8.5,12.9c2.2,5.1,3.2,11.3,3.2,18.6c0,8.5-1.5,15.8-4.5,22.1
                    c-3,6.2-7.2,11.4-12.7,15.5s-12,7.2-19.6,9.3s-16,3.1-25.2,3.1c-8.5,0-15.9-0.6-22.4-1.9s-12.4-3-17.7-5.1
                    c-5.4-2.2-10.4-4.7-15.2-7.7C522.2,299.4,517.3,296.2,512.2,292.6z"/>
                <path style={ pathStyle } d="M813.2,150.8V314H782v-64.9h-80.5V314h-31.2V150.8h31.2v70.7H782v-70.7H813.2z"/>
                <path style={ pathStyle } d="M924.9,317.1c-11.6,0-22.5-2.2-32.8-6.7s-19.2-10.5-26.9-18.2s-13.7-16.6-18.2-26.9c-4.5-10.3-6.7-21.3-6.7-33
                    c0-11.6,2.2-22.6,6.7-32.9c4.5-10.3,10.5-19.3,18.2-27s16.6-13.7,26.9-18.2c10.3-4.5,21.2-6.7,32.8-6.7c11.7,0,22.7,2.2,33,6.7
                    s19.2,10.5,26.9,18.2s13.7,16.6,18.2,27c4.5,10.3,6.7,21.3,6.7,32.9c0,11.7-2.2,22.7-6.7,33c-4.5,10.3-10.5,19.2-18.2,26.9
                    c-7.7,7.7-16.6,13.7-26.9,18.2S936.6,317.1,924.9,317.1z M925,176.9c-7.4,0-14.4,1.5-20.8,4.6c-6.5,3-12.1,7.1-16.9,12.2
                    c-4.8,5.1-8.6,11-11.5,17.7c-2.8,6.8-4.2,13.8-4.2,21.1c0,7.4,1.4,14.5,4.2,21.2s6.6,12.6,11.5,17.7c4.8,5.1,10.5,9.2,16.9,12.3
                    c6.5,3,13.4,4.6,20.8,4.6c7.4,0,14.3-1.5,20.8-4.6c6.5-3,12.1-7.1,16.9-12.3c4.8-5.1,8.6-11,11.5-17.7c2.8-6.7,4.2-13.7,4.2-21.2
                    c0-7.3-1.4-14.3-4.2-21.1s-6.6-12.7-11.5-17.7c-4.8-5.1-10.5-9.1-16.9-12.2C939.4,178.4,932.4,176.9,925,176.9z"/>
                <path style={ pathStyle } d="M1109.9,150.8c8,0,15.6,1.5,22.6,4.6c7.1,3,13.2,7.2,18.4,12.4c5.2,5.2,9.3,11.3,12.4,18.4
                    c3,7.1,4.6,14.6,4.6,22.6s-1.3,15.5-4,22.3s-6.6,12.8-11.8,17.9c-5.2,5.1-11.8,9.1-19.7,12c-8,2.9-17.1,4.3-27.5,4.3h-36.3V314
                    h-31.2V150.8H1109.9z M1109.9,237.3c4.6,0,8.6-0.8,12-2.3c3.4-1.6,6.2-3.7,8.4-6.4c2.2-2.7,3.8-5.7,4.8-9.1c1-3.4,1.6-7,1.6-10.7
                    s-0.7-7.4-2-11s-3.3-6.9-5.8-9.7s-5.7-5.1-9.4-6.9c-3.7-1.8-8-2.7-12.9-2.7h-38.1v58.9h41.4V237.3z"/>
                <path style={ pathStyle } d="M1337.9,273.9c-3.6,6.5-8,12.4-13.2,17.7c-5.2,5.3-11,9.8-17.3,13.6c-6.3,3.8-13.2,6.7-20.5,8.8
                    c-7.4,2.1-15,3.1-22.9,3.1c-11.6,0-22.5-2.2-32.8-6.7s-19.2-10.5-26.9-18.2c-7.7-7.7-13.7-16.6-18.2-26.9
                    c-4.5-10.3-6.7-21.2-6.7-32.8s2.2-22.5,6.7-32.8s10.5-19.2,18.2-26.9s16.6-13.7,26.9-18.2s21.2-6.7,32.8-6.7c7.9,0,15.5,1,22.9,3.1
                    s14.2,5,20.5,8.8s12.1,8.3,17.3,13.6c5.2,5.3,9.6,11.1,13.2,17.5l-29.2,11.8c-2.4-3.6-5.2-6.9-8.4-10c-3.2-3.1-6.7-5.9-10.6-8.2
                    c-3.9-2.4-8-4.2-12.3-5.6c-4.3-1.3-8.8-2-13.4-2c-7.4,0-14.4,1.5-20.8,4.6c-6.5,3-12.1,7.1-16.8,12.2c-4.8,5.1-8.5,11-11.4,17.7
                    c-2.8,6.8-4.2,13.8-4.2,21.1c0,7.4,1.4,14.5,4.2,21.2c2.8,6.7,6.6,12.6,11.4,17.6c4.8,5.1,10.4,9.1,16.8,12.1
                    c6.5,3,13.4,4.6,20.8,4.6c4.6,0,9.1-0.7,13.4-2s8.4-3.2,12.3-5.6s7.4-5.2,10.6-8.4c3.2-3.2,6-6.6,8.4-10.1L1337.9,273.9z"/>
                <path style={ pathStyle } d="M1393.2,314H1362V150.8h74.1c8.3,0,16,1.4,22.9,4.1c6.9,2.8,12.8,6.6,17.8,11.6c4.9,5,8.7,10.9,11.5,17.8
                    c2.7,6.9,4.1,14.5,4.1,22.6c0,12.3-3.1,23.1-9.3,32.3c-6.2,9.2-14.4,15.8-24.7,19.8c13.7,18.1,27.3,36.4,40.6,54.8h-37.2
                    c-6.5-8.5-12.9-17-19.1-25.6s-12.4-17.4-18.7-26.3h-30.9L1393.2,314L1393.2,314z M1393.2,234.4h42.9c2.8,0,5.7-0.6,8.8-1.7
                    c3-1.1,5.7-2.8,8.1-5.1s4.3-5.2,5.9-8.6c1.6-3.4,2.3-7.4,2.3-12c0-2.4-0.3-5.2-0.9-8.5s-1.8-6.4-3.6-9.4c-1.8-3-4.3-5.5-7.6-7.6
                    c-3.3-2.1-7.6-3.1-13.1-3.1h-42.9L1393.2,234.4L1393.2,234.4z"/>
                <path style={ pathStyle } d="M1503.6,314l83.4-163h3.1l83.4,163h-33.9l-12.7-25.6H1550l-12.5,25.6H1503.6z M1565.1,261.2h46.6l-23.2-49.3
                    L1565.1,261.2z"/>
                <path style={ pathStyle } d="M1778.4,178.5h-46.6V314h-31.2V178.5h-46.8v-27.6h124.6V178.5z"/>
                <path style={ pathStyle } d="M1831.1,178.5v40.8h78.9v28.3h-78.9v38.3h88.9V314h-120.2V150.8H1920v27.6L1831.1,178.5L1831.1,178.5z"/>
            </g>
        </svg>
    )
}

export default React.memo(ShopCrateLogo);
