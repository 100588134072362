import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import Loading from "../../components/Loading";
import TagPill from "../../components/pills/TagPill";
import ProductTypePill from "../../components/ProductTypePill";
import PriceFormatter from "../../components/priceFormatter";
import Helmet from "../../components/Helmet";

class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: null,
            error: null
        }
    }

    componentDidMount() {
        this.getProducts();
    }

    getProducts() {
        this.setState({ orders: null, error: null });
        axios.post("/getProducts", { shopId: this.context.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    const products = response.data.products.sort((product1, product2) => {
                        return product1.name > product2.name ? 1 : -1;
                    })
                    this.setState({ products });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    render() {
        const baseUrl = "/shop/" + this.context.currentShop.codeName;
        return (
            <React.Fragment>
                <Helmet title="Producten"/>
                <Title
                    preTitle="Overzicht"
                    preChildren={
                        <div className="float-right mt-2">
                            <OverlayTrigger overlay={
                                <Tooltip id="newProduct">Nieuw product</Tooltip>
                            }>
                                <Link
                                    to={ baseUrl + "/products/add" }
                                    className="btn btn-secondary float-right mt-2"
                                >
                                    <i className="fas fa-fw fa-plus"/>
                                </Link>
                            </OverlayTrigger>
                        </div>
                    }
                >
                    Producten
                    <small>
                        <TagPill className="ml-2">{ this.state.products ? this.state.products.length : 0 }</TagPill>
                    </small>
                </Title>
                <Table size="sm" className="results" hover={ this.state.products && this.state.products.length > 0 }>
                    <thead className="thead-light">
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-left">Naam</th>
                        <th className="text-left">Prijs</th>
                        <th className="text-right">BTW %</th>
                        <th className="text-left">Type</th>
                        <th className="text-center">Bestelbaar</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.state.error ? (
                        <tr>
                            <td colSpan={ 6 }>
                                <Alert variant="danger" className="mb-0">{ this.state.error }</Alert>
                            </td>
                        </tr>
                    ) : this.state.products === null ? (
                        <tr>
                            <td colSpan={ 6 }>
                                <Loading/>
                            </td>
                        </tr>
                    ) : this.state.products.length === 0 ? (
                        <tr>
                            <td colSpan={ 6 } className="text-center">
                                <h1><i className="fas fa-shopping-basket"/></h1>
                                <h4>Geen producten</h4>
                                <p>Er staan nog geen producten in de shop.</p>
                            </td>
                        </tr>
                    ) : this.state.products.map((product) => {
                        const url = baseUrl + "/product/" + product.id;
                        return (
                            <tr key={ product.id } className="table-clickable-row">
                                <th scope="row" className="align-middle text-center">
                                    <Link to={ url }>
                                        { product.id }
                                    </Link>
                                </th>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { product.name }
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { product.discountPrice !== null ? (
                                            <React.Fragment>
                                                <span style={{textDecoration: "line-through"}} className="text-muted">
                                                    <PriceFormatter price={product.price}/>
                                                </span>
                                                {" "}
                                                <PriceFormatter price={product.discountPrice}/>
                                            </React.Fragment>
                                        ) : (
                                            <PriceFormatter price={product.price}/>
                                        )}
                                    </Link>
                                </td>
                                <td className="align-middle text-right">
                                    <Link to={ url }>
                                        { Math.round(product.taxPercentage * 100) }%
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        <ProductTypePill product={ product }/>
                                    </Link>
                                </td>
                                <td className="align-middle text-center">
                                    <Link to={ url }>
                                        { product.orderable ? (
                                            <i className="fas fa-check fa-fw text-success"/>
                                        ) : (
                                            <i className="fas fa-times fa-fw text-danger"/>
                                        )}
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}
Products.contextType = ShopsContext;

export default Products;
