import React, {
    useCallback,
    useContext,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import ShopsContext from "../../../../context/ShopsContext";

function ManuallySendShipmentModal({ shipment, show, handleClose, onSaved }) {
    const shopsContext = useContext(ShopsContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const onShow = useCallback(() => {
        setLoading(false);
        setError(null);
    }, []);
    const onSave = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.post("/setShipmentAsSent", {
                shopId: shopsContext.currentShop.id,
                shipmentId: shipment.id,
                sent: !shipment.status.sent ? 1 : 0
            });
            onSaved(response.data.shipment);
            handleClose();
        } catch(requestError) {
            setError("Er is een fout opgetreden bij het handmatig verzenden van de zending. Probeer het later opnieuw.");
            setLoading(false);
        }
    }, [shopsContext, shipment, onSaved]);

    return (
        <Modal show={ show } onHide={ handleClose } onShow={ onShow }>
            <Modal.Header closeButton>
                <Modal.Title>
                    Verzending handmatig verzenden
                    { shipment?.status.sent && (
                        " ongedaan maken"
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p className="mb-0">
                    { shipment?.status.sent ? (
                        "Weet je zeker dat je het handmatig markeren als verzonden ongedaan wilt maken?"
                    ) : (
                        "Weet je zeker dat je de zending wilt markeren als handmatig verzonden?"
                    )}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={ onSave } disabled={ loading }>
                    { loading && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    { shipment?.status.sent ? (
                        "Ongedaan maken"
                    ) : (
                        "Markeer verzonden"
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
ManuallySendShipmentModal.propTypes = {
    shipment: PropTypes.object,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onSaved: PropTypes.func.isRequired
};

export default React.memo(ManuallySendShipmentModal);
