import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import PropTypes from "prop-types";

import ShopsContext from "../../../../context/ShopsContext";
import Loading from "../../../../components/Loading";
import CourseShopUserAccessItemCard from "./components/CourseShopUserAccessItemCard";
import AddCourseShopUserAccessItemCard from "./components/AddCourseShopUserAccessItemCard";

function EditShopUserCourseAccessModal({ show, handleClose, course, shopUser, onChange }) {
    const shopsContext = useContext(ShopsContext);
    const [access, setAccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const refreshAccess = useCallback(async () => {
        if(!shopUser || !course) {
            return;
        }
        setError(null);
        try {
            const response = await axios.post("/getShopUserCourseAccess", {
                shopId: shopsContext.currentShop.id,
                shopUserId: shopUser.id,
                courseId: course.id
            });
            setAccess(response.data.access);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        }
    }, [shopUser, course]);
    const onClickToggleRevoked = useCallback(async (accessItem) => {
        setError(null);
        setLoading(true);
        try {
            const response = await axios.post("/setCourseShopUserAccessRevoked", {
                shopId: shopsContext.currentShop.id,
                courseShopUserAccessId: accessItem.id,
                revoked: !accessItem.revoked ? 1 : 0
            });
            setAccess(response.data.access);
            onChange();
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        } finally {
            setLoading(false);
        }
    }, [onChange]);

    useEffect(() => {
        if(!show) {
            return;
        }
        setAccess(null);
        setLoading(false);
        setError(null);
        refreshAccess();
    }, [show, refreshAccess]);
    const onSaved = useCallback(() => {
        refreshAccess();
        onChange();
    }, [onChange, refreshAccess]);

    return (
        <Modal show={ show } onHide={ handleClose } size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Bewerk cursustoegang</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p>
                    Beheer de toegang tot cursus <b>{ course?.title }</b> voor gebruiker <b>{ shopUser?.name }</b>.
                </p>
                { !access ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <AddCourseShopUserAccessItemCard
                            course={ course }
                            shopUser={ shopUser }
                            onSaved={ onSaved }
                        />

                        <h4>Huidige toegang</h4>
                        { access.map((accessItem, index) => (
                            <CourseShopUserAccessItemCard
                                key={ index }
                                accessItem={ accessItem }
                                onClickToggleRevoked={ () => onClickToggleRevoked(accessItem) }
                                loading={ loading }
                            />
                        ))}
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
EditShopUserCourseAccessModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    course: PropTypes.object,
    shopUser: PropTypes.object,
    onChange: PropTypes.func.isRequired
};

export default React.memo(EditShopUserCourseAccessModal);
