import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    Alert,
    Button,
    Form,
    Modal
} from "react-bootstrap";

import ShopsContext from "../../../../context/ShopsContext";
import Loading from "../../../../components/Loading";
import ShipmentPlatformLogo from "../../../../components/integration/ShipmentPlatformLogo";

class RegisterMyParcelConsignment extends React.PureComponent {
    getDefaultState() {
        return {
            error: null,
            errorCode: null,
            loading: false,

            shipmentType: 1,

            largeFormat: false,
            onlyRecipient: false,
            signatureRequired: false,
            ageCheck: false,
            returnToSender: false,

            weight: 1
        }
    }

    constructor(props) {
        super(props);
        this.state = this.getDefaultState();
    }

    createMyParcelConsignment() {
        this.setState({ loading: true, error: null });
        axios.post("/createMyParcelConsignment", {
            shopId: this.context.currentShop.id,
            shipmentId: this.props.shipment.id,

            shipmentType: this.state.shipmentType,

            largeFormat: this.state.largeFormat ? 1 : 0,
            onlyRecipient: this.state.onlyRecipient ? 1 : 0,
            signatureRequired: this.state.signatureRequired ? 1 : 0,
            ageCheck: this.state.ageCheck ? 1 : 0,
            returnToSender: this.state.returnToSender ? 1 : 0,

            weight: this.state.weight
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.consignmentCreated(response.data.shipment);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            }).finally(() => {
            this.setState({ loading: false });
        });
    }

    onShow() {
        this.setState(this.getDefaultState());
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow.bind(this) } size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Verzending registreren</Modal.Title>
                </Modal.Header>
                { this.state.shipment === null || this.state.loading ? (
                    <Modal.Body>
                        <Loading/>
                    </Modal.Body>
                ) : (
                    <form className="mb-0">
                        <Modal.Body>
                            <div className="d-flex justify-content-center mb-3 py-2">
                                <ShipmentPlatformLogo
                                    shipmentPlatform="myparcel"
                                    style={{width: "250px"}}
                                />
                            </div>
                            {this.state.error && (
                                <React.Fragment>
                                    { this.state.errorCode === "MYPARCEL_ACCOUNT_NOT_ACTIVE" ? (
                                        <Alert variant="danger">MyParcel account is niet geactiveerd. Alleen dan kunnen verzendingen geregistreerd worden.</Alert>
                                    ) : this.state.errorCode === "MYPARCEL_NOT_SETUP" ? (
                                        <Alert variant="danger">MyParcel API key is niet ingesteld.</Alert>
                                    ) : (
                                        <Alert variant="danger">{ this.state.error }</Alert>
                                    )}
                                </React.Fragment>
                            )}
                            <Form.Group controlId="shipmentType">
                                <Form.Label>Soort zending</Form.Label>
                                <Form.Control as="select" value={ this.state.shipmentType }
                                              onChange={ (event) => this.setState({ shipmentType: event.target.value })}>
                                    <option value={ 1 }>Pakket</option>
                                    <option value={ 2 }>Brievenbuspakje</option>
                                </Form.Control>
                            </Form.Group>

                            { (this.state.shipmentType === 1 || this.state.shipmentType === "1") && (
                                <React.Fragment>
                                    <Form.Group controlId="largeFormat">
                                        <Form.Check
                                            type="checkbox" custom checked={ this.state.largeFormat }
                                            label="Groot pakket"
                                            onChange={ (event) => this.setState({ largeFormat: event.target.checked })}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="onlyRecipient">
                                        <Form.Check
                                            type="checkbox" custom checked={ this.state.onlyRecipient } disabled={ this.state.ageCheck }
                                            label="Alleen afleveren bij ontvanger"
                                            onChange={ (event) => this.setState({ onlyRecipient: event.target.checked })}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="signatureRequired">
                                        <Form.Check
                                            type="checkbox" custom checked={ this.state.signatureRequired } disabled={ this.state.ageCheck }
                                            label="Handtekening voor ontvangst"
                                            onChange={ (event) => this.setState({ signatureRequired: event.target.checked })}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="ageCheck">
                                        <Form.Check
                                            type="checkbox" custom checked={ this.state.ageCheck }
                                            label="Leeftijdscheck 18+"
                                            onChange={ (event) => {
                                                this.setState({ ageCheck: event.target.checked });
                                                if(event.target.checked) {
                                                    this.setState({
                                                        onlyRecipient: true,
                                                        signatureRequired: true
                                                    });
                                                }
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="returnToSender">
                                        <Form.Check
                                            type="checkbox" custom checked={ this.state.returnToSender }
                                            label="Retour bij geen gehoor"
                                            onChange={ (event) => this.setState({ returnToSender: event.target.checked })}
                                        />
                                    </Form.Group>

                                    { this.state.largeFormat && (
                                        <Form.Group controlId="weight">
                                            <Form.Label>Gewicht (gram)</Form.Label>
                                            <Form.Control type="number" value={ this.state.weight }
                                                          onChange={ (event) => this.setState({ weight: Math.max(1, Math.min(30000, event.target.value)) })}
                                            />
                                            <Form.Text muted>
                                                Minimaal 1 gram - maximaal 30.000 gram
                                            </Form.Text>
                                        </Form.Group>
                                    )}
                                </React.Fragment>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={ this.props.handleClose }>
                                Annuleer
                            </Button>
                            <Button variant="primary" onClick={ this.createMyParcelConsignment.bind(this) }>
                                Registreren
                            </Button>
                        </Modal.Footer>
                    </form>
                ) }
            </Modal>
        )
    }
}
RegisterMyParcelConsignment.contextType = ShopsContext;
RegisterMyParcelConsignment.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    shipment: PropTypes.object.isRequired,
    consignmentCreated: PropTypes.func.isRequired
}

export default RegisterMyParcelConsignment;
