import React from "react";
import {
    BrowserRouter as Router
} from "react-router-dom";

import BackofficeManager from "./BackofficeManager";
import AuthenticationManager from "./AuthenticationManager";

function App() {
    return (
        <Router>
            <BackofficeManager>
                <AuthenticationManager/>
            </BackofficeManager>
        </Router>
    );
}

export default App;
