/* eslint-disable max-lines */
import React from "react";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import Loading from "../Loading";
import ShopsContext from "../../context/ShopsContext";

class BaseSelectObjectModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            objects: null,
            filteredObjects: null,
            saving: false,
            error: null,
            errorSave: null,

            searchValue: "",
            selectedObject: null
        }
        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onObjectSelected = this.onObjectSelected.bind(this);
        this.onSearchValueChange = this.onSearchValueChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDidPressEnter = this.onDidPressEnter.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onFocus = this.onFocus.bind(this);

        this.searchInput = React.createRef();
        this.activeObjectListItem = React.createRef();
    }

    // eslint-disable-next-line require-await
    async abstractLoadObjects() {
        throw new Error("abstractLoadObjects not implemented.");
    }

    // eslint-disable-next-line no-unused-vars,require-await
    async abstractSave(selectedObject) {
        throw new Error("abstractSave not implemented.");
    }

    abstractGetTitle() {
        console.error("abstractGetTitle from BaseSelectObjectModal not implemented!");
        return null;
    }

    abstractGetExistingObjects() {
        return [];
    }

    // eslint-disable-next-line no-unused-vars
    abstractGetObjectListItemContent(object) {
        return null;
    }

    abstractGetNoObjectsMessage() {
        return "Geen items gevonden.";
    }

    abstractAlreadyAddedMessage() {
        return "Dit item is al toegevoegd.";
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((!prevProps.show && this.props.show) || (!prevState.objects && this.state.objects)) {
            setTimeout(() => {
                if(this.searchInput.current) {
                    this.searchInput.current.focus();
                }
            }, 100);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    async onShow() {
        document.addEventListener("keydown", this.onKeyDown);
        this.setState({ saving: false, selectedObject: null, searchValue: "" });
        if(!this.state.objects) {
            const objects = await this.abstractLoadObjects();
            this.setState({ objects });
        }
        this.setState((prevState) => {
            return { filteredObjects: this.filterObjects(prevState.objects, null) }
        })
    }

    onHide() {
        document.removeEventListener("keydown", this.onKeyDown);
        this.props.handleClose();
    }

    onKeyDown(event) {
        if(event.keyCode === 38) {
            // ArrowUp
            event.preventDefault();
            this.handleArrowKeyPress("up");
        } else if(event.keyCode === 40) {
            // ArrowDown
            event.preventDefault();
            this.handleArrowKeyPress("down");
        }
    }

    onFocus() {
        if(this.state.selectedObject === null) {
            return;
        }
        this.setState({ selectedObject: null });
    }

    handleArrowKeyPress(direction) {
        this.setState((prevState) => {
            const objects = prevState.filteredObjects;
            if(objects.length === 0) {
                return {};
            }
            const selectedObjectId = prevState.selectedObject;
            if(selectedObjectId == null && direction === "down") {
                if(direction === "down" && this.searchInput.current && this.searchInput.current === document.activeElement) {
                    this.searchInput.current.blur();
                    return { selectedObject: objects[0].id };
                }
                return {};
            }
            const index = objects.findIndex((findObject) => findObject.id === selectedObjectId);

            if(direction === "up") {
                if(index <= 0) {
                    if(this.searchInput.current) {
                        this.searchInput.current.focus();
                    }
                    return { selectedObject: null };
                }
                const newSelectedObject = objects[index - 1];
                return { selectedObject: newSelectedObject.id };
            } else if(direction === "down") {
                if(index + 1 >= objects.length) {
                    return {};
                }
                const newSelectedObject = objects[index + 1];
                return { selectedObject: newSelectedObject.id };
            }
            return {};
        }, () => {
            if(this.activeObjectListItem.current) {
                this.activeObjectListItem.current.scrollIntoView({ block: "center" });
            }
        });
    }

    onDidPressEnter(event) {
        if(event.keyCode === 13) {
            this.onSave(event);
        }
    }

    async onSave(event) {
        if(event) {
            event.preventDefault();
        }
        if(!this.state.selectedObject) {
            this.setState({ errorSave: "Selecteer een item." });
            return;
        }
        this.setState({ saving: true, errorSave: null });
        try {
            await this.abstractSave(this.state.selectedObject);
            this.props.handleClose();
        } catch(error) {
            this.setState({ saving: false, errorSave: error });
        }
    }

    filterObjects(objects, searchValue) {
        if(!objects) {
            return objects;
        }
        if(!searchValue || searchValue.trim().length === 0) {
            return objects;
        }
        const searchValueParts = searchValue.trim().toLowerCase().split(" ");
        return objects.filter((object) => {
            for(const part of searchValueParts) {
                if(!this.matchObject(object, part)) {
                    return false;
                }
            }
            return true;
        });
    }

    matchObject(object, filter) {
        return object.name.toLowerCase().includes(filter);
    }

    onObjectSelected(objectId) {
        this.setState({ selectedObject: objectId });
    }

    onSearchValueChange(event) {
        const searchValue = event.target.value;
        this.setState((prevState) => {
            return {
                searchValue,
                filteredObjects: this.filterObjects(prevState.objects, searchValue)
            }
        });
    }

    doesObjectExistAlready(object) {
        const existingObjects = this.abstractGetExistingObjects();
        if(!existingObjects || !object) {
            return false;
        }
        return existingObjects.find((existingObject) => existingObject.id === object.id) !== undefined;
    }

    getSelectedObject() {
        const objects = this.state.objects;
        const selectedObjectId = this.state.selectedObject;
        if(!objects || !selectedObjectId) {
            return null;
        }
        return objects.find((object) => object.id === selectedObjectId);
    }

    canSubmit() {
        const selectedObject = this.getSelectedObject();
        return selectedObject !== null && !this.doesObjectExistAlready(selectedObject);
    }

    render() {
        const {
            objects,
            filteredObjects,
            saving,
            error,
            errorSave,
            searchValue,
            selectedObject
        } = this.state;
        return (
            <Modal show={ this.props.show } onHide={ this.onHide } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>{ this.abstractGetTitle() }</Modal.Title>
                </Modal.Header>
                <form className="mb-0" onSubmit={ this.onSave }>
                    <Modal.Body>
                        { error ? (
                            <Alert variant="danger">{ error }</Alert>
                        ) : !objects || !filteredObjects ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                { errorSave && (
                                    <Alert variant="danger">{ errorSave }</Alert>
                                )}
                                <Form.Control
                                    ref={ this.searchInput }
                                    type="search"
                                    value={ searchValue }
                                    onChange={ this.onSearchValueChange }
                                    className="mb-3"
                                    placeholder="Zoeken"
                                    disabled={ saving }
                                    onClick={ this.onFocus }
                                />
                                <ul className="list-group list-group-border list-group-scroll" style={{
                                    height: "500px"
                                }}>
                                    { filteredObjects.map((object) => {
                                        const active = object.id === selectedObject
                                        let listGroupItemClassNames = ["list-group-item", "list-group-item-hover", "pointer-cursor"];
                                        if(active) {
                                            listGroupItemClassNames.push("active");
                                        }
                                        return (
                                            <li
                                                key={ object.id }
                                                className={ listGroupItemClassNames.join(" ") }
                                                onClick={ () => this.onObjectSelected(object.id) }
                                                ref={ active ? this.activeObjectListItem : undefined }
                                            >
                                                { this.abstractGetObjectListItemContent(object) }
                                            </li>
                                        )
                                    } )}
                                    { filteredObjects.length === 0 && (
                                        <li className="list-group-item text-center d-flex justify-content-center text-muted flex-column h-100">
                                            <h4><i className="fas fa-search"/></h4>
                                            <p>
                                                { this.abstractGetNoObjectsMessage() }
                                            </p>
                                        </li>
                                    )}
                                </ul>
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                    <Modal.Footer>
                        { this.doesObjectExistAlready(this.getSelectedObject()) && (
                            <p className="text-danger mt-0">{ this.abstractAlreadyAddedMessage() }</p>
                        )}
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ saving }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ this.onSave } disabled={ saving || !this.canSubmit() }>
                            { saving && (
                                <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                            )}
                            Toevoegen
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

}
BaseSelectObjectModal.contextType = ShopsContext;
BaseSelectObjectModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default BaseSelectObjectModal;
