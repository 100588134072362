import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/DetailOverviewRow";
import ProductDescriptionMarkdown from "../../../components/ProductDescriptionMarkdown";

function CategoryDetailOverview({ category }) {
    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-borderless mb-0">
                    <tbody>
                    <DetailOverviewRow title="Naam">
                        {category ? (
                            category.name
                        ) : (
                            <Skeleton width={150}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="URL">
                        {category ? (
                            category.url
                        ) : (
                            <Skeleton width={100}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Beschrijving">
                        {category ? (
                            <ProductDescriptionMarkdown>
                                {category.description}
                            </ProductDescriptionMarkdown>
                        ) : (
                            <Skeleton count={5}/>
                        )}
                    </DetailOverviewRow>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

CategoryDetailOverview.propTypes = {
    category: PropTypes.object
}

export default CategoryDetailOverview;
