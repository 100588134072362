import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import Loading from "../../components/Loading";
import TagPill from "../../components/pills/TagPill";
import Helmet from "../../components/Helmet";

class Categories extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            categories: null,
            error: null
        }
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories() {
        this.setState({ categories: null, error: null });
        axios.post("/getCategories", { shopId: this.context.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    const categories = response.data.categories.sort((product1, product2) => {
                        return product1.name > product2.name ? 1 : -1;
                    });
                    this.setState({ categories });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    render() {
        const baseUrl = "/shop/" + this.context.currentShop.codeName;
        return (
            <React.Fragment>
                <Helmet title="Categorieën"/>
                <Title
                    preTitle="Overzicht"
                    // preChildren={
                    //     <div className="float-right mt-2">
                    //         <OverlayTrigger overlay={
                    //             <Tooltip id="newProduct">Nieuwe categorie</Tooltip>
                    //         }>
                    //             <Link
                    //                 to={ baseUrl + "/categories/add" }
                    //                 className="btn btn-secondary float-right mt-2"
                    //             >
                    //                 <i className="fas fa-fw fa-plus"/>
                    //             </Link>
                    //         </OverlayTrigger>
                    //     </div>
                    // }
                >
                    Categorieën
                    <small>
                        <TagPill className="ml-2">{ this.state.categories ? this.state.categories.length : 0 }</TagPill>
                    </small>
                </Title>
                <Table size="sm" className="results" hover={ this.state.categories && this.state.categories.length > 0 }>
                    <thead className="thead-light">
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-left">Naam</th>
                        <th className="text-right">Aantal producten</th>
                        <th className="text-left">URL</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.state.error ? (
                        <tr>
                            <td colSpan={ 5 }>
                                <Alert variant="danger" className="mb-0">{ this.state.error }</Alert>
                            </td>
                        </tr>
                    ) : this.state.categories === null ? (
                        <tr>
                            <td colSpan={ 5 }>
                                <Loading/>
                            </td>
                        </tr>
                    ) : this.state.categories.length === 0 ? (
                        <tr>
                            <td colSpan={ 5 } className="text-center">
                                <h1><i className="fas fa-th-list"/></h1>
                                <h4>Geen categorieën</h4>
                                <p>Er staan nog geen categorieën in de shop.</p>
                            </td>
                        </tr>
                    ) : this.state.categories.map((category) => {
                        const url = baseUrl + "/category/" + category.id;
                        return (
                            <tr key={ category.id } className="table-clickable-row">
                                <th scope="row" className="align-middle text-center">
                                    <Link to={ url }>
                                        { category.id }
                                    </Link>
                                </th>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { category.name }
                                    </Link>
                                </td>
                                <td className="align-middle text-right">
                                    <Link to={ url }>
                                        { category.products ? category.products.length : 0 }
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { category.url }
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}
Categories.contextType = ShopsContext;

export default Categories;
