import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner,
    Table
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import ReactRouterPropTypes from "react-router-prop-types";

import ShopsContext from "../../context/ShopsContext";
import Loading from "../../components/Loading";
import TagPill from "../../components/pills/TagPill";
import Helmet from "../../components/Helmet";
import OrderRow from "./components/OrderRow";

function Orders({ match }) {
    const shopsContext = useContext(ShopsContext);
    const [orders, setOrders] = useState(null);
    const [totalOrderCount, setTotalOrderCount] = useState(null);
    const [error, setError] = useState(null);
    const [loadingNext, setLoadingNext] = useState(false);

    const prevFilter = useRef(null);
    const filter = useMemo(() => {
        const filters = [
            "all",
            "running",
            "finished",
            "canceled"
        ];
        if(!match.params.filter) {
            return "running";
        }
        if(!filters.includes(match.params.filter)) {
            return null;
        }
        return match.params.filter.toLowerCase();
    }, [match.params.filter]);

    const getOrders = useCallback(async (requestFilter, previousOrder) => {
        if(previousOrder === null || requestFilter !== prevFilter.current) {
            setOrders(null);
            setTotalOrderCount(null);
        }
        prevFilter.current = requestFilter;
        setError(null);
        setLoadingNext(true);
        try {
            const response = await axios.post("/getOrders", {
                shopId: shopsContext.currentShop.id,
                filter: requestFilter,
                orderBy: "id",
                orderDirection: "desc",
                limit: 50,
                loadNext: 1,
                previousOrderId: previousOrder?.id ?? undefined,
            });
            setTotalOrderCount(response.data.totalCount);
            setOrders((previousOrders) => {
                if(previousOrders === null) {
                    return response.data.orders;
                }
                return previousOrders.concat(response.data.orders);
            });
        } catch(requestError) {
            setError("Er ging iets fout. Probeer het later opnieuw.");
        } finally {
            setLoadingNext(false);
        }
    }, [shopsContext.currentShop.id]);

    const onLoadNext = useCallback(async () => {
        if(loadingNext) {
            return;
        }
        const lastOrder = orders?.at(-1);
        await getOrders(filter, lastOrder);
    }, [loadingNext, orders, filter, getOrders]);
    useEffect(() => {
        if(!filter) {
            return;
        }
        getOrders(filter, null);
    }, [filter, getOrders]);

    const shopUrl = "/shop/" + shopsContext.currentShop.codeName;
    const baseUrl = shopUrl + "/orders";

    const accounting = shopsContext.currentShop.entitlementAccountingApplication && shopsContext.currentShop.accountingApplications.length > 0;
    return (
        <React.Fragment>
            <Helmet title="Bestellingen"/>
            <Title preTitle="Overzicht" noBottom>
                Bestellingen
                <small>
                    <TagPill className="ml-2">{ totalOrderCount ?? 0 }</TagPill>
                </small>
            </Title>
            <TabBar noBottom>
                <TabBarItem to={ baseUrl }>
                    Lopende
                </TabBarItem>
                <TabBarItem to={ baseUrl + "/finished" }>
                    Voltooid
                </TabBarItem>
                <TabBarItem to={ baseUrl + "/canceled" }>
                    Geannuleerd
                </TabBarItem>
                <TabBarItem to={ baseUrl + "/all" }>
                    Alle
                </TabBarItem>
            </TabBar>
            <Table size="sm" className="results" hover={ orders && orders.length > 0 }>
                <thead className="thead-light">
                    <tr className="tr-sticky">
                        <th className="text-center">#</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Naam</th>
                        <th className="text-left">Adres</th>
                        { shopsContext.currentShop.shippingCosts && (
                            <th className="text-left">Land</th>
                        )}
                        <th className="text-right">Aantal producten</th>
                        <th className="text-right">Totaal</th>
                        <th className="text-left" style={{ minWidth: "110px" }}>Datum</th>
                        { accounting && (
                            <th className="text-center">Factuur</th>
                        )}
                        { shopsContext.currentShop.entitlementAuthentication && (
                            <th className="text-center">Account</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                { error ? (
                    <tr>
                        <td colSpan={ 10 }>
                            <Alert variant="danger" className="mb-0">{ error }</Alert>
                        </td>
                    </tr>
                ) : !orders ? (
                    <tr>
                        <td colSpan={10}>
                            <Loading/>
                        </td>
                    </tr>
                ) : orders.length === 0 ? (
                    <tr>
                        <td colSpan={10} className="text-center">
                            <h1><i className="fas fa-cash-register"/></h1>
                            <h4>Geen bestellingen</h4>
                            <p>Er zijn nog geen bestellingen binnengekomen.</p>
                        </td>
                    </tr>
                ) : orders.map((order) => (
                    <OrderRow
                        key={ order.id }
                        order={ order }
                    />
                ))}
                </tbody>
            </Table>
            { orders && totalOrderCount > orders.length && (
                <div className="d-flex justify-content-center">
                    <div>
                        <Button
                            variant="outline-primary"
                            onClick={onLoadNext}
                            disabled={loadingNext}
                        >
                            { loadingNext && (
                                <Spinner animation="border" size="sm" className="mr-2"/>
                            )}
                            Meer laden
                        </Button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

Orders.propTypes = {
    match: ReactRouterPropTypes.match
}

export default React.memo(Orders);
