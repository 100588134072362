import React from "react";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import AccountNavbar from "../../components/navbar/AccountNavbar";

import AccountOverview from "./AccountOverview";
import AccountAuthentication from "./AccountAuthentication";

function Account() {
    const urlPrefix = "/account";
    return (
        <div className="container" style={{ marginTop: "80px" }}>
            <Helmet title="Account"/>
            <AccountNavbar/>
            <Title preTitle="Overzicht" noBottom>
                Account
            </Title>
            <TabBar>
                <TabBarItem to={ urlPrefix }>
                    Overzicht
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/authentication" }>
                    Authenticatie
                </TabBarItem>
            </TabBar>
            <Switch>
                <Route path={ urlPrefix } exact>
                    <AccountOverview/>
                </Route>
                <Route path={ urlPrefix + "/authentication" }>
                    <AccountAuthentication/>
                </Route>
                <Route path={ urlPrefix }>
                    <Redirect to={ urlPrefix + "/authentication" }/>
                </Route>
            </Switch>
        </div>
    )
}

export default React.memo(Account);
