import React from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import ReactRouterPropTypes from "react-router-prop-types";

import ShopsContext from "../../../context/ShopsContext";
import Helmet from "../../../components/Helmet";

import CategoryDetailOverview from "./CategoryDetailOverview";
import CategoryDetailProducts from "./CategoryDetailProducts";

class CategoryDetail extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            category: null,
            error: null
        };
        this.setCategory = this.setCategoryState.bind(this);
    }

    componentDidMount() {
        this.getCategory();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.categoryId !== this.props.match.params.categoryId) {
            this.getCategory();
        }
    }

    setCategoryState(category) {
        this.setState({ category });
    }

    getCategory() {
        this.setState({ category: null, error: null });
        axios.post("/getCategory", { shopId: this.context.currentShop.id, categoryId: this.props.match.params.categoryId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ category: response.data.category });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    getCategoryUrl() {
        const category = this.state.category;
        if(!category) {
            return "";
        }
        return this.context.currentShop.url + "/category/" + category.id + "/" + category.url;
    }

    render() {
        const categoryId = parseInt(this.props.match.params.categoryId, 10);
        const baseUrl = "/shop/" + this.context.currentShop.codeName + "/category/" + categoryId;
        const category = this.state.category;
        return (
            <React.Fragment>
                <Helmet title={ category ? category.name : "Categorie" }/>
                <Title preTitle="Detail" noBottom preChildren={
                    <React.Fragment>
                        { category && (
                            <a href={ this.getCategoryUrl() } className="btn btn-secondary btn-sm float-right mt-3" target="_blank" rel="noopener noreferrer">
                                Open categorie pagina <i className="fas fa-external-link-square ml-2"/>
                            </a>
                        )}
                    </React.Fragment>
                }>
                    Categorie <small className="text-muted">#{categoryId}</small>
                </Title>

                <TabBar>
                    <TabBarItem to={baseUrl}>
                        Overzicht
                    </TabBarItem>
                    <TabBarItem to={baseUrl + "/products"}>
                        Producten
                    </TabBarItem>
                    {/*<TabBarItem to={baseUrl + "/edit"}>*/}
                    {/*    Bewerken*/}
                    {/*</TabBarItem>*/}
                </TabBar>

                { this.state.error ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : (
                    <Switch>
                        <Route path={ baseUrl } exact>
                            <CategoryDetailOverview category={ category }/>
                        </Route>
                        <Route path={ baseUrl + "/products" } exact>
                            <CategoryDetailProducts category={ category } setCategory={ this.setCategory }/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: baseUrl,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                )}

            </React.Fragment>
        );
    }
}
CategoryDetail.contextType = ShopsContext;
CategoryDetail.propTypes = {
    match: ReactRouterPropTypes.match
}

export default CategoryDetail;
