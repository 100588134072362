import React from "react";
import PropTypes from "prop-types";

function TagPill({ children, className = "", color, variant = "primary" }) {
    return (
        <span className={ "badge badge-pill badge-" + variant + " " + className }
              style={{ backgroundColor: color }}>
            { children }
        </span>
    );
}
TagPill.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
    ]),
    className: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string
}

export default React.memo(TagPill);
