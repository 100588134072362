import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../context/AuthenticatedUserContext";

import Logo from "../components/Logo";
import Helmet from "../components/Helmet";
import Copyright from "../components/Copyright";

function Login() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const emailInputRef = useRef(null);

    const onError = useCallback((errorMessage) => {
        setError(errorMessage);
        setLoading(false);
    }, []);
    const onSubmit = useCallback((event) => {
        event.preventDefault();
        setError(null);
        setLoading(true);
        authenticatedUserContext.loginFunction({ email, password }, onError);
    }, [authenticatedUserContext, email, password, onError]);

    useEffect(() => {
        emailInputRef.current?.focus();
    }, [emailInputRef]);

    return (
        <AuthenticationPage>
            <Helmet title="Login"/>
            <AuthenticationPageColumn>
                <Logo className="login-logo" style={{ maxWidth: "100%" }}/>
                <h3 className="mt-0 text-center text-muted mb-4">Login</h3>
                { error !== null && (
                    <div className="alert alert-danger">{ error }</div>
                )}
                <form onSubmit={ onSubmit }>
                    <div className="form-group">
                        <input
                            ref={ emailInputRef }
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                            disabled={ loading }
                            value={ email }
                            onChange={(event) => setEmail(event.target.value) }
                            autoComplete="email"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Wachtwoord"
                            disabled={ loading }
                            value={ password }
                            onChange={(event) => setPassword(event.target.value) }
                            autoComplete="current-password"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary float-right">
                        Inloggen
                    </button>
                </form>
            </AuthenticationPageColumn>
            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    )
}

export default React.memo(Login);
