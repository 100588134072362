import React, {
    useContext,
    useEffect,
    useState
} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";

import ShopsContext from "../../../context/ShopsContext";
import Loading from "../../../components/Loading";
import DateFormatter from "../../../components/DateFormatter";

function CourseDetailParticipants({ course }) {
    const shopsContext = useContext(ShopsContext);
    const [shopUsers, setShopUsers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!course) {
            return;
        }
        const loadParticipants = async () => {
            setError(null);
            setShopUsers(null);
            try {
                const response = await axios.post("/getCourseShopUsersWithAccess", {
                    shopId: shopsContext.currentShop.id,
                    courseId: course.id,
                });
                setShopUsers(response.data.shopUsers);
            } catch(requestError) {
                console.error(requestError);
                setError("Er ging iets fout. Probeer het later opnieuw.");
            }
        }
        loadParticipants();
    }, [course]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        );
    }
    if(!shopUsers) {
        return (
            <Loading/>
        );
    }
    if(shopUsers.length === 0) {
        return (
            <div className="d-flex flex-column align-items-center text-center">
                <h1><i className="fas fa-graduation-cap"/></h1>
                <h4>Geen deelnemers</h4>
                <p>Deze cursus heeft nog geen deelnemers.</p>
            </div>
        )
    }
    const baseUrl = "/shop/" + shopsContext.currentShop.codeName;
    return (
        <React.Fragment>
            <p className="mb-4">
                Deze cursus heeft { shopUsers.length } deelnemer{ shopUsers.length === 1 ? "" : "s" }.
            </p>
            <Table size="sm" hover>
                <thead className="thead-light">
                <tr className="tr-sticky">
                    <th className="text-center">#</th>
                    <th className="text-left">Naam</th>
                    <th className="text-left">Email</th>
                    <th className="text-center">Toegang</th>
                    <th className="text-right">Toegang sinds</th>
                    <th className="text-right">Verloopdatum</th>
                </tr>
                </thead>
                <tbody>
                { shopUsers.map((shopUser) => {
                    const url = baseUrl + "/shop-user/" + shopUser.id;
                    return (
                        <tr key={ course.id } className="table-clickable-row">
                            <th className="text-center">
                                <Link to={ url }>
                                    { shopUser.id }
                                </Link>
                            </th>
                            <td className="text-left">
                                <Link to={ url }>
                                    { shopUser.name }
                                </Link>
                            </td>
                            <td className="text-left">
                                <Link to={ url }>
                                    { shopUser.email }
                                </Link>
                            </td>
                            <td className="text-center">
                                <Link to={ url }>
                                    { shopUser.courseAccess?.hasAccess ? (
                                        <i className="fa-solid fa-check fa-fw text-success"/>
                                    ) : (
                                        <i className="fa-solid fa-times fa-fw text-danger"/>
                                    )}
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link to={ url }>
                                    <DateFormatter date={ shopUser.courseAccess?.firstAccessDate }/>
                                </Link>
                            </td>
                            <td className="text-right">
                                <Link to={ url }>
                                    { shopUser.courseAccess?.expirationDate ? (
                                        <DateFormatter date={ shopUser.courseAccess?.expirationDate }/>
                                    ) : (
                                        "Nooit"
                                    )}
                                </Link>
                            </td>
                        </tr>
                    )
                }) }
                </tbody>
            </Table>
        </React.Fragment>
    )
}
CourseDetailParticipants.propTypes = {
    course: PropTypes.object,
}

export default React.memo(CourseDetailParticipants);
