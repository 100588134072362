import React, {
    useContext
} from "react";
import {
    Link
} from "react-router-dom";
import PropTypes from "prop-types";

import ShopsContext from "../../../context/ShopsContext";
import StatusPill from "../../../components/pills/StatusPill";
import PriceFormatter from "../../../components/priceFormatter";
import DateFormatter from "../../../components/DateFormatter";

function OrderRow({ order }) {
    const shopsContext = useContext(ShopsContext);
    const shopUrl = "/shop/" + shopsContext.currentShop.codeName;
    const url = shopUrl + "/order/" + order.id;
    const accounting = shopsContext.currentShop.entitlementAccountingApplication && shopsContext.currentShop.accountingApplications.length > 0;
    return (
        <tr className="table-clickable-row">
            <th scope="row" className="align-middle text-center">
                <Link to={url}>
                    {order.id}
                </Link>
            </th>
            <td className="align-middle">
                <Link to={url}>
                    <StatusPill status={order.status}/>
                </Link>
            </td>
            <td className="align-middle">
                <Link to={url}>
                    {order.deliveryFirstName + " " + order.deliveryLastName}
                </Link>
            </td>
            <td className="align-middle">
                <Link to={url}>
                    {order.deliveryStreet + " " + order.deliveryHouseNumber + ", " + order.deliveryCity}
                </Link>
            </td>
            {shopsContext.currentShop.shippingCosts && (
                <td className="align-middle">
                    <Link to={url}>
                        {order.deliveryCountry ? order.deliveryCountry.iso : "Onbekend"}
                    </Link>
                </td>
            )}
            <td className="align-middle text-right">
                <Link to={url}>
                    {order.products.length === 0 ? 0 : order.products.map((product) => product.amount).reduce((amount1, amount2) => amount1 + amount2)}
                </Link>
            </td>
            <td className="align-middle text-right" style={{whiteSpace: "nowrap"}}>
                <Link to={url}>
                    <PriceFormatter price={order.totalPrice}/>
                </Link>
            </td>
            <td className="align-middle">
                <Link to={url}>
                    <DateFormatter date={order.orderDate}/>
                </Link>
            </td>
            {accounting && (
                <td className="align-middle text-center">
                    <Link to={url}>
                        {order.invoiceId ? (
                            <i className="fa-solid fa-check text-success"/>
                        ) : (
                            <i className="fa-solid fa-times"/>
                        )}
                    </Link>
                </td>
            )}
            {shopsContext.currentShop.entitlementAuthentication && (
                <td className="align-middle text-center">
                    <Link to={url}>
                        {order.shopUser ? (
                            <i className="fa-solid fa-check text-success"/>
                        ) : (
                            <i className="fa-solid fa-times"/>
                        )}
                    </Link>
                </td>
            )}
        </tr>
    );
}

OrderRow.propTypes = {
    order: PropTypes.object.isRequired
}

export default React.memo(OrderRow);
