import React from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import ReactRouterPropTypes from "react-router-prop-types";

import ShopsContext from "../../../context/ShopsContext";
import Helmet from "../../../components/Helmet";

import CollectionDetailOverview from "./CollectionDetailOverview";
import CollectionDetailProducts from "./CollectionDetailProducts";

class CollectionDetail extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collection: null,
            error: null
        };
        this.setCollection = this.setCollectionState.bind(this);
    }

    componentDidMount() {
        this.getCollection();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.collectionId !== this.props.match.params.collectionId) {
            this.getCollection();
        }
    }

    setCollectionState(collection) {
        this.setState({ collection });
    }

    getCollection() {
        this.setState({ collection: null, error: null });
        axios.post("/getCollection", { shopId: this.context.currentShop.id, collectionId: this.props.match.params.collectionId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ collection: response.data.collection });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    render() {
        const collectionId = parseInt(this.props.match.params.collectionId, 10);
        const baseUrl = "/shop/" + this.context.currentShop.codeName + "/collection/" + collectionId;
        const collection = this.state.collection;
        return (
            <React.Fragment>
                <Helmet title={ collection ? collection.name : "Collectie" }/>
                <Title preTitle="Detail" noBottom>
                    Collectie <small className="text-muted">#{collectionId}</small>
                </Title>

                <TabBar>
                    <TabBarItem to={baseUrl}>
                        Overzicht
                    </TabBarItem>
                    <TabBarItem to={baseUrl + "/products"}>
                        Producten
                    </TabBarItem>
                    {/*<TabBarItem to={baseUrl + "/edit"}>*/}
                    {/*    Bewerken*/}
                    {/*</TabBarItem>*/}
                </TabBar>

                { this.state.error ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : (
                    <Switch>
                        <Route path={ baseUrl } exact>
                            <CollectionDetailOverview collection={ collection }/>
                        </Route>
                        <Route path={ baseUrl + "/products" } exact>
                            <CollectionDetailProducts collection={ collection } setCollection={ this.setCollection }/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: baseUrl,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                )}
            </React.Fragment>
        );
    }
}
CollectionDetail.contextType = ShopsContext;
CollectionDetail.propTypes = {
    match: ReactRouterPropTypes.match
}

export default CollectionDetail;
