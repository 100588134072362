import React from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import ReactRouterPropTypes from "react-router-prop-types";

import ShopsContext from "../../../context/ShopsContext";
import Helmet from "../../../components/Helmet";

import ProductDetailOverview from "./ProductDetailOverview";
import ProductDetailImages from "./ProductDetailImages";
import ProductDetailDigitalLinks from "./ProductDetailDigitalLinks";
import ProductDetailCourses from "./ProductDetailCourses";
import ProductDetailOrders from "./ProductDetailOrders";
import ProductDetailEdit from "./ProductDetailEdit";

class ProductDetail extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            error: null
        };
        this.setProduct = this.setProductState.bind(this);
        this.refreshProduct = this.refreshProduct.bind(this);
    }

    componentDidMount() {
        this.getProduct(this.props.match.params.productId);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.params.productId !== this.props.match.params.productId) {
            this.getProduct(this.props.match.params.productId);
        }
    }

    setProductState(product) {
        this.setState({ product });
    }

    refreshProduct() {
        this.getProduct(this.props.match.params.productId);
    }

    getProduct(productId) {
        this.setState({ product: null, error: null });
        axios.post("/getProduct", { shopId: this.context.currentShop.id, productId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({
                        product: response.data.product
                    });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    getProductUrl() {
        if(!this.state.product) {
            return "";
        }
        const folder = this.state.product.rental ? "rental" : "product";
        return this.context.currentShop.url + "/" + folder + "/" + this.state.product.id + "/" + this.state.product.url;
    }

    render() {
        const productId = parseInt(this.props.match.params.productId, 10);
        const baseUrl = "/shop/" + this.context.currentShop.codeName + "/product/" + productId;
        const product = this.state.product;
        return (
            <React.Fragment>
                <Helmet title={ product ? product.name : "Product" }/>
                <Title preTitle="Detail" noBottom preChildren={
                    <React.Fragment>
                        { product && (
                            <a href={ this.getProductUrl() } className="btn btn-secondary btn-sm float-right mt-3" target="_blank" rel="noopener noreferrer">
                                Open product pagina <i className="fas fa-external-link-square ml-2"/>
                            </a>
                        )}
                    </React.Fragment>
                }>
                    Product <small className="text-muted">#{productId}</small>
                </Title>

                <TabBar>
                    <TabBarItem to={baseUrl}>
                        Overzicht
                    </TabBarItem>
                    <TabBarItem to={baseUrl + "/images"}>
                        Foto&apos;s
                    </TabBarItem>
                    { product && product.type.value === "digital" && (
                        <TabBarItem to={baseUrl + "/links"}>
                            Links
                        </TabBarItem>
                    )}
                    { product && product.type.value === "course" && (
                        <TabBarItem to={baseUrl + "/courses"}>
                            Cursussen
                        </TabBarItem>
                    )}
                    { product && ["physical", "digital", "course"].includes(product.type.value) && (
                        <TabBarItem to={baseUrl + "/orders"}>
                            Bestellingen
                        </TabBarItem>
                    )}
                    <TabBarItem to={baseUrl + "/edit"}>
                        Bewerken
                    </TabBarItem>
                </TabBar>

                { this.state.error ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : (
                    <Switch>
                        <Route path={ baseUrl } exact>
                            <ProductDetailOverview product={ product }/>
                        </Route>
                        <Route path={ baseUrl + "/images" } exact>
                            <ProductDetailImages product={ product } setProduct={ this.setProduct } getProduct={ this.refreshProduct }/>
                        </Route>
                        <Route path={ baseUrl + "/links" } exact>
                            <ProductDetailDigitalLinks product={ product } baseUrl={ baseUrl }/>
                        </Route>
                        <Route path={ baseUrl + "/courses" } exact>
                            <ProductDetailCourses product={ product }/>
                        </Route>
                        <Route path={ baseUrl + "/orders" } exact>
                            <ProductDetailOrders product={ product }/>
                        </Route>
                        <Route path={ baseUrl + "/edit" } exact>
                            <ProductDetailEdit product={ product } setProduct={ this.setProduct }/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: baseUrl,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                )}

            </React.Fragment>
        );
    }
}
ProductDetail.contextType = ShopsContext;
ProductDetail.propTypes = {
    match: ReactRouterPropTypes.match
}

export default ProductDetail;
