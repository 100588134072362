import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    FormControl,
    InputGroup,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import {
    AuthenticatedUserContextPropTypes,
    withAuthenticatedUserContext
} from "../../../context/AuthenticatedUserContext";
import Loading from "../../../components/Loading";

class SetupOTPModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            secret: null,
            qrcode: null,
            activated: false,
            error: null,
            loading: false,

            otp: ""
        }
        this.onShow = this.onShow.bind(this);
        this.activateOTP = this.activateOTP.bind(this);
    }

    onShow() {
        this.setState({ secret: null, qrcode: null, activated: null, error: null, loading: false, otp: "" });
        this.setupOTP();
    }

    setupOTP() {
        this.setState({ loading: true, error: null });
        axios.get("/setupOTP")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({
                        secret: response.data.secret,
                        qrcode: response.data.qrcode
                    });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    activateOTP() {
        this.setState({ loading: true, error: null });
        axios.post("/activateOTP", { otp: this.state.otp })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ activated: true });
                    this.props.authenticatedUserContext.setUser(response.data.user);
                } else {
                    if(response.data.error === "INVALID_OTP") {
                        this.setState({ error: "Ongeldige 2FA code. Probeer het opnieuw." });
                    } else {
                        this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                    }
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        const { secret, qrcode, activated, error } = this.state;
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        2FA instellen
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { error && (
                        <Alert variant="danger">{ error }</Alert>
                    )}

                    { !secret || !qrcode ? (
                        <Loading/>
                    ) : !activated ? (
                        <div className="text-center">
                            <p className="mb-0">
                                Scan de QR Code met je 2FA app of gebruik in plaats daarvan de onderstaande code.
                            </p>

                            <img src={ qrcode } alt="2FA Setup QR Code"/>

                            <p className="mb-4 text-muted">
                                { secret }
                            </p>

                            <label htmlFor="otp">Vul de 2FA code in</label>
                            <InputGroup className="mb-0">
                                <FormControl
                                    id="otp"
                                    value={ this.state.otp }
                                    placeholder="000000"
                                    className="text-center"
                                    onChange={ (event) => this.setState({ otp: event.target.value }) }
                                />
                            </InputGroup>
                        </div>
                    ) : (
                        <p className="text-success mb-0">2 Factor Authenticatie is nu ingesteld op je account!</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                        { activated ? "Sluiten" : "Annuleer"}
                    </Button>
                    { !activated && (
                        <Button variant="primary" onClick={ this.activateOTP } disabled={ this.state.loading }>
                            { this.state.loading && (
                                <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                            )}
                            Verifiëren
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        )
    }
}
SetupOTPModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    authenticatedUserContext: AuthenticatedUserContextPropTypes
}

export default withAuthenticatedUserContext(SetupOTPModal);
