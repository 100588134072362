import React, {
    createContext
} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    matchPath,
    withRouter
} from "react-router-dom";

const ShopsContext = createContext(null);

class ShopsManagerInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shops: null,
            currentShop: undefined,
            error: null,
            refreshShops: this.getShops.bind(this),
            refreshShopsInitial: this.getShopsInitial.bind(this),
            refreshShopsNoClear: this.refreshShopsNoClear.bind(this)
        }
    }

    componentDidMount() {
        this.getShops();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.updateCurrentShop();
        }
    }

    getCurrentShop(shops) {
        if(!shops) {
            return undefined;
        }
        const match = matchPath(this.props.location.pathname, {
            path: "/shop/:shopCodeName"
        });
        if(match === null) {
            return undefined;
        }
        const currentCodeName = match.params.shopCodeName;
        const currentShop = shops.find((shop) => shop.codeName === currentCodeName);
        if(!currentShop) {
            return null;
        }
        return currentShop;
    }

    updateCurrentShop() {
        console.log("updateCurrentShop");
        const shops = this.state.shops;
        this.setState({ currentShop: this.getCurrentShop(shops) });
    }

    getShopsInitial() {
        if(!this.state.shops) {
            this.getShops();
        }
    }

    refreshShopsNoClear() {
        this.getShops(false);
    }

    getShops(clear = true) {
        if(clear) {
            this.setState({ shops: null });
        }
        this.setState({ error: null });
        axios.get("/getShops")
            .then((response) => {
                if(response.data.valid) {
                    const shops = response.data.shops.sort((shop1, shop2) => {
                        if(shop1.name < shop2.name) return -1;
                        if(shop1.name > shop2.name) return 1;
                        return 0;
                    });
                    this.setState({ shops, currentShop: this.getCurrentShop(shops) });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")", errorCode: response.data.error });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data." });
            })
    }

    render() {
        return (
            <ShopsContext.Provider value={ this.state }>
                { this.props.children }
            </ShopsContext.Provider>
        )
    }
}
ShopsManagerInternal.propTypes = {
    children: PropTypes.element,
    location: PropTypes.object
}
export const ShopsManager = withRouter(ShopsManagerInternal);

export function withShopsContext(Component) {
    return function contextComponent(props) {
        return (
            <ShopsContext.Consumer>
                {context => <Component {...props} shopsContext={context} />}
            </ShopsContext.Consumer>
        )
    }
}

export const ShopPropType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    codeName: PropTypes.string,
    url: PropTypes.string,
    shippingCosts: PropTypes.bool,
    entitlementOrders: PropTypes.bool,
    entitlementShipments: PropTypes.bool,
    entitlementRentals: PropTypes.bool
});
export const ShopsContextPropTypes = PropTypes.shape({
    shops: PropTypes.arrayOf(ShopPropType),
    currentShop: ShopPropType,
    error: PropTypes.string,
    refreshShops: PropTypes.func,
    refreshShopsInitial: PropTypes.func
});

export default ShopsContext;
