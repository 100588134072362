import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import ShopsContext from "../../../context/ShopsContext";
import CourseForm from "../components/CourseForm";
import Loading from "../../../components/Loading";
import validateCourseFormInput from "../functions/validateCourseFormInput";

function CourseDetailEdit({ course, setCourse }) {
    const shopsContext = useContext(ShopsContext);
    const [editedCourse, setEditedCourse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        setEditedCourse(course);
    }, [course]);

    const onSave = useCallback(async () => {
        setError(null);
        setSuccess(null);
        const validationError = validateCourseFormInput(course);
        if(validationError) {
            setError(validationError);
            return;
        }
        setLoading(true);
        try {
            const response = await axios.post("/setCourse", {
                shopId: shopsContext.currentShop.id,
                courseId: editedCourse.id,
                title: editedCourse.title,
                maxAccessDuration: parseInt(editedCourse.maxAccessDuration, 10),
                description: editedCourse.description,
            });
            setCourse(response.data.course);
            setSuccess("Cursus opgeslagen.");
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        }
        setLoading(false);
    }, [editedCourse, setCourse]);

    if(!editedCourse) {
        return (
            <Loading/>
        );
    }
    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { success && (
                <Alert variant="success">{ success }</Alert>
            )}
            <CourseForm
                course={ editedCourse }
                setCourse={ setEditedCourse }
                loading={ loading }
                onSubmit={ onSave }
            />
        </React.Fragment>
    )
}
CourseDetailEdit.propTypes = {
    course: PropTypes.object,
    setCourse: PropTypes.func.isRequired
};

export default React.memo(CourseDetailEdit);
