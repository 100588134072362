import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import ReactRouterPropTypes from "react-router-prop-types";

import ShopsContext from "../../../context/ShopsContext";
import Helmet from "../../../components/Helmet";

import ShopUserDetailOverview from "./ShopUserDetailOverview";
import ShopUserDetailOrders from "./ShopUserDetailOrders";
import ShopUserDetailCourses from "./ShopUserDetailCourses";
import ShopUserDetailEdit from "./ShopUserDetailEdit";

function ShopUserDetail({ match }) {
    const shopsContext = useContext(ShopsContext);
    const [shopUser, setShopUser] = useState(null);
    const [error, setError] = useState(null);
    const prevShopUserId = useRef(null);

    const shopUserId = useMemo(() => {
        return parseInt(match.params.shopUserId, 10);
    }, [match.params.shopUserId]);
    const refreshShopUser = useCallback(async (shopUserIdLocal) => {
        setShopUser(null);
        setError(null);
        try {
            const response = await axios.post("/getShopUser", {
                shopId: shopsContext.currentShop.id,
                shopUserId: shopUserIdLocal
            });
            setShopUser(response.data.shopUser);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        }
    }, [shopsContext]);
    useEffect(() => {
        if(shopUserId === prevShopUserId.current) {
            return;
        }
        refreshShopUser(shopUserId);
        prevShopUserId.current = shopUserId;
    }, [refreshShopUser, shopUserId]);

    const baseUrl = `/shop/${shopsContext.currentShop.codeName}/shop-user/${shopUserId}`;

    return (
        <React.Fragment>
            <Helmet title={ shopUser?.name ?? "Gebruiker" }/>
            <Title preTitle="Detail" noBottom>
                { shopUser?.name ?? "Gebruiker" } <small className="text-muted">#{shopUserId}</small>
            </Title>

            <TabBar>
                <TabBarItem to={baseUrl}>
                    Overzicht
                </TabBarItem>
                <TabBarItem to={`${baseUrl}/orders`}>
                    Bestellingen
                </TabBarItem>
                { shopsContext.currentShop.entitlementCourses && (
                    <TabBarItem to={`${baseUrl}/courses`}>
                        Cursussen
                    </TabBarItem>
                )}
                <TabBarItem to={`${baseUrl}/edit`}>
                    Bewerken
                </TabBarItem>
            </TabBar>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <Switch>
                    <Route path={ baseUrl } exact>
                        <ShopUserDetailOverview shopUser={ shopUser }/>
                    </Route>
                    <Route path={ `${baseUrl}/orders` } exact>
                        <ShopUserDetailOrders shopUser={ shopUser }/>
                    </Route>
                    { shopsContext.currentShop.entitlementCourses && (
                        <Route path={ `${baseUrl}/courses` } exact>
                            <ShopUserDetailCourses shopUser={ shopUser }/>
                        </Route>
                    )}
                    <Route path={ `${baseUrl}/edit` }>
                        <ShopUserDetailEdit
                            shopUser={ shopUser }
                            refreshShopUser={ () => refreshShopUser(shopUserId) }
                        />
                    </Route>

                    <Route path="/">
                        <Redirect
                            to={{
                                pathname: baseUrl,
                                state: {from: "/"}
                            }}
                        />
                    </Route>
                </Switch>
            )}
        </React.Fragment>
    );
}
ShopUserDetail.propTypes = {
    match: ReactRouterPropTypes.match
}


export default React.memo(ShopUserDetail);
