import React, {
    useContext,
    useEffect
} from "react";
import {
    Redirect
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import Logo from "../components/Logo";
import Loading from "../components/Loading";
import AuthenticatedUserContext from "../context/AuthenticatedUserContext";
import ShopsContext from "../context/ShopsContext";
import Helmet from "../components/Helmet";
import Copyright from "../components/Copyright";

function Shops() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);
    const shopsContext = useContext(ShopsContext);

    useEffect(() => {
        shopsContext.refreshShopsInitial();
    }, [shopsContext.refreshShopsInitial]);

    return (
        <AuthenticationPage>
            <Helmet title="Shops"/>
            <AuthenticationPageColumn>
                <Logo className="login-logo" style={{ maxWidth: "100%" }}/>
                <h3 className="mt-0 text-center text-muted mb-4">Shops</h3>
                <div className="text-center mb-3">
                    <p className="mb-0">
                        Welkom { authenticatedUserContext.user.name }
                    </p>
                    <button
                        onClick={ authenticatedUserContext.logoutFunction }
                        className="btn btn-link btn-sm"
                    >
                        Uitloggen
                    </button>
                </div>
                { shopsContext.error ? (
                    <Alert variant="danger">{ shopsContext.error }</Alert>
                ) : shopsContext.shops === null ? (
                    <Loading/>
                ) : (
                    <div className="list-group">
                        { shopsContext.shops.length === 1 && (
                            <Redirect to={ "/shop/" + shopsContext.shops[0].codeName + "/dashboard" }/>
                        )}
                        { shopsContext.shops.map((shop) => {
                            const onClick = () => {
                                authenticatedUserContext.navigationTransition("/shop/" + shop.codeName + "/dashboard");
                            }
                            return (
                                <button
                                    key={ shop.id }
                                    className="list-group-item list-group-item-action"
                                    onClick={ onClick }
                                >
                                    { shop.name }
                                </button>
                            );
                        })}
                    </div>
                )}
            </AuthenticationPageColumn>
            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    )
}

export default React.memo(Shops);
