import React, {
    useContext,
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import {
    NavDropdown
} from "react-bootstrap";
import {
    Navbar,
    SidebarContext
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import ShopsContext from "../../context/ShopsContext";
import NavbarAccountDropdown from "./NavbarAccountDropdown";

function BackofficeNavbar() {
    const userContext = useContext(AuthenticatedUserContext);
    const shopsContext = useContext(ShopsContext);
    const sidebarContext = useContext(SidebarContext);

    const goToAllShops = useMemo(() => {
        return () => {
            userContext.navigationTransition("/shops");
        }
    }, [userContext.navigationTransition]);

    const shopsDropdownTitle = useMemo(() => {
        if(sidebarContext.mobile || !shopsContext.currentShop) {
            return "Shops";
        }
        return shopsContext.currentShop.name;
    }, [sidebarContext.mobile, shopsContext.currentShop]);

    return (
        <Navbar>
            { shopsContext.shops !== null && shopsContext.shops.length > 0 && (
                <NavDropdown title={ shopsDropdownTitle } id="shop" align="right" className="mr-4">
                    { shopsContext.shops.map((shop) => (
                        <Link
                            to={ "/shop/" + shop.codeName + "/dashboard" }
                            key={ shop.id }
                            className= { "dropdown-item" + (shopsContext.currentShop && shop.id === shopsContext.currentShop.id ? " active" : "")}
                        >
                            { shop.name }
                        </Link>
                    ))}
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={ goToAllShops }>
                        Alle shops
                    </NavDropdown.Item>
                </NavDropdown>
            )}
            <NavbarAccountDropdown/>
        </Navbar>
    )

}

export default React.memo(BackofficeNavbar);
