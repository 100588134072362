import React from "react";
import Skeleton from "react-loading-skeleton";
import PropTypes from "prop-types";

function DashboardCard({ title, content, subline, error }) {
    return (
        <div className="col-md mb-3">
            <div className="card h-100">
                <div className="card-body text-center d-flex flex-column justify-content-center">
                    <h5 className="card-title">{ title }</h5>
                    <h3 className="my-0">
                        { error ? (
                            <span className="text-danger">Error</span>
                        ) : content !== undefined && content !== null ? (
                            content
                        ) : (
                            <Skeleton width={ 75 }/>
                        )}
                    </h3>
                    { subline && (
                        <p className="mt-2 mb-0 text-muted">
                            { subline }
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}
DashboardCard.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element
    ]),
    subline: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element
    ]),
    error: PropTypes.number
}

export default React.memo(DashboardCard);
