import React, {
    useContext,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import ShopsContext from "../../../context/ShopsContext";
import OAuthConnectionCard from "../modal/OAuthConnectionCard";

function SettingsMailchimp() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const shops = useContext(ShopsContext);

    const mailchimpEnabled = useMemo(() => {
        const currentShop = shops.currentShop;
        if(!currentShop) {
            return false;
        }
        return currentShop.mailingListPlatforms.includes("mailchimp");
    }, [shops]);

    const didClickSetup = useMemo(() => {
        return () => {
            setLoading(true);
            axios.post("/oauthMailchimpSetup", { shopId: shops.currentShop.id })
                .then((response) => {
                    if(response.data.valid) {
                        window.location = response.data.oauthUrl;
                    } else {
                        setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                        setLoading(false);
                    }
                })
                .catch((setupError) => {
                    console.error(setupError);
                    setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                    setLoading(false);
                });
        }
    }, []);
    const didClickRemove = useMemo(() => {
        return (completionHandler) => {
            setError(null);
            setLoading(true);
            axios.post("/oauthMailchimpSetupRemove", { shopId: shops.currentShop.id })
                .then((response) => {
                    if(response.data.valid) {
                        shops.refreshShopsNoClear();
                    } else {
                        setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                    }
                })
                .catch((setupError) => {
                    console.error(setupError);
                    setError("Er is een fout opgetreden. Probeer het later opnieuw.");
                })
                .finally(() => {
                    setLoading(false);
                    completionHandler();
                });
        }
    }, []);

    return (
        <React.Fragment>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <OAuthConnectionCard
                service="Mailchimp"
                enabled={ mailchimpEnabled }
                onClickSetup={ didClickSetup }
                onClickRemove={ didClickRemove }
                loading={ loading }
            />
        </React.Fragment>
    )
}

export default React.memo(SettingsMailchimp);
