import React from "react";
import PropTypes from "prop-types";

import TagPill from "./pills/TagPill";

function getProductTypeName(type) {
    switch(type.value) {
        case "physical": return "Koop fysiek";
        case "digital": return "Koop digitaal";
        case "rental": return "Huur";
        case "course": return "Cursus";
        default: return "Onbekend";
    }
}

function getProductTypeColor(type) {
    switch(type.value) {
        case "physical": return "#1F6BDE";
        case "digital": return "#00B506";
        case "rental": return "#FF5000";
        case "course": return "#d9b00b";
        default: return "#595959";
    }
}

function ProductTypePill({ product, light = false }) {
    const name = getProductTypeName(product.type);
    if(light) {
        return (
            <TagPill variant="light">{ name }</TagPill>
        )
    }
    const color = getProductTypeColor(product.type);
    return (
        <TagPill color={ color } >{ name }</TagPill>
    )
}
ProductTypePill.propTypes = {
    product: PropTypes.object.isRequired,
    light: PropTypes.bool
}

export default React.memo(ProductTypePill);
