import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import PropTypes from "prop-types";

import ShopsContext from "../../../context/ShopsContext";
import Loading from "../../../components/Loading";
import ProductForm from "../components/ProductForm";

class ProductDetailEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: null,
            error: null,
            saving: false,
            success: false
        }
        this.setProductState = this.setProductState.bind(this);
        this.updateProduct = this.updateProduct.bind(this);
    }

    componentDidMount() {
        if(this.props.product) {
            this.updateProductFromProps();
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.product !== this.props.product) {
            this.updateProductFromProps();
        }
    }

    updateProductFromProps() {
        const product = this.props.product;
        this.setProductState({
            ...product,
            enableDiscountPrice: product.discountPrice !== null,
            discountPrice: product.discountPrice !== null ? product.discountPrice : ""
        });
    }

    setProductState(state) {
        this.setState((prevState) => {
            return { product: { ...prevState.product, ...state } }
        })
    }

    getProductUrl() {
        if(!this.state.product) {
            return "";
        }
        const folder = this.state.product.rental ? "rental" : "product";
        return this.context.currentShop.url + "/" + folder + "/" + this.state.product.id + "/" + this.state.product.url;
    }

    updateProduct() {
        this.setState({ success: false });
        const {
            product
        } = this.state;
        if(product.name.trim().length === 0) {
            this.setState({ error: "Naam is vereist." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(product.url.trim().length === 0) {
            this.setState({ error: "Product URL is vereist." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(product.taxPercentage < 0 || product.taxPercentage >= 100) {
            this.setState({ error: "Ongeldig BTW percentage." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(product.enableDiscountPrice && parseFloat(product.discountPrice) >= parseFloat(product.price)) {
            this.setState({ error: "Kortingsprijs kan niet hoger zijn dan de reguliere prijs." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        this.setState({ error: null, saving: true });
        axios.post("/updateProduct", {
            shopId: this.context.currentShop.id,
            productId: this.props.product.id,
            name: product.name,
            subtitle: product.subtitle,
            url: product.url,
            orderable: product.orderable ? 1 : 0,
            preorder: product.preorder ? 1 : 0,
            visible: product.visible ? 1 : 0,
            releaseDate: product.releaseDate,
            price: parseFloat(product.price),
            discountPrice: product.enableDiscountPrice ? parseFloat(product.discountPrice) : "null",
            taxPercentage: parseFloat(product.taxPercentage),
            description: product.description,
            summary: product.summary
        })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ product: response.data.product, success: true });
                    this.props.setProduct(response.data.product);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                window.scroll({ top: 0, behavior: "smooth" });
                this.setState({ saving: false });
            });
    }

    render() {
        if(this.state.product == null) {
            return (
                <Loading/>
            )
        }
        return (
            <React.Fragment>
                { this.state.error ? (
                    <Alert variant="danger">{ this.state.error }</Alert>
                ) : this.state.success && (
                    <Alert variant="success">
                        Wijzigingen opgeslagen!{" "}
                        <a href={ this.getProductUrl() } target="_blank" rel="noreferrer noopener">Product pagina bekijken</a>.
                    </Alert>
                )}
                <ProductForm
                    state={ this.state.product }
                    originalState={ this.props.product }
                    onChange={ this.setProductState }
                    loading={ this.state.saving }
                    onSave={ this.updateProduct }
                />
            </React.Fragment>
        )
    }
}
ProductDetailEdit.contextType = ShopsContext;
ProductDetailEdit.propTypes = {
    product: PropTypes.object,
    setProduct: PropTypes.func.isRequired
}

export default ProductDetailEdit;
