import React, {
    useContext
} from "react";
import {
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";

function Error404() {
    const shopsContext = useContext(ShopsContext);
    return (
        <React.Fragment>
            <Title preTitle="Error">
                Geen toegang
            </Title>
            <p>
                { shopsContext.currentShop.name } heeft geen toegang tot deze module. Neem contact op met ShopCrate voor meer informatie.
            </p>
        </React.Fragment>
    )
}

export default React.memo(Error404);
