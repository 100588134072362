import React, {
    useContext
} from "react";
import ReactMarkdown from "react-markdown";

import ShopsContext from "../context/ShopsContext";

function ProductDescriptionMarkdown(props) {
    const { children, ...restProps } = props;
    const shopContext = useContext(ShopsContext);
    return (
        <ReactMarkdown
            components={{
                // eslint-disable-next-line
                a: (linkProps) => {
                    const addShopUrl = linkProps.href.startsWith("/");
                    return (
                        <a
                            href={ (addShopUrl ? shopContext.currentShop.url : "") + linkProps.href }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            { linkProps.children }
                        </a>
                    )
                }
            }}
            { ...restProps }
        >
            { children }
        </ReactMarkdown>
    )
}
ProductDescriptionMarkdown.propTypes = ReactMarkdown.propTypes;

export default React.memo(ProductDescriptionMarkdown);
