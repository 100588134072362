import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import ShopsContext from "../../../../context/ShopsContext";

function CourseChapterEditModal({ show, course, chapter, handleClose, onChapterAdded, onChapterUpdated, createNew = false }) {
    const shopsContext = useContext(ShopsContext);
    const [editedChapter, setEditedChapter] = useState(null);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(!show || (!chapter && !course)) {
            return;
        }
        if(createNew) {
            setEditedChapter({
                title: ""
            });
        } else {
            setEditedChapter(chapter);
        }
        setError(null);
        setSaving(false);
    }, [chapter, course, show, createNew]);

    const handleSave = useCallback(async () => {
        setError(null);
        setSaving(true);
        try {
            if(createNew) {
                const response = await axios.post("/addCourseChapter", {
                    shopId: shopsContext.currentShop.id,
                    courseId: course.id,
                    title: editedChapter.title
                });
                onChapterAdded(response.data.courseChapter);
            } else {
                const response = await axios.post("/setCourseChapter", {
                    shopId: shopsContext.currentShop.id,
                    courseChapterId: editedChapter.id,
                    title: editedChapter.title
                });
                onChapterUpdated(response.data.courseChapter);
            }
            handleClose();
        } catch(requestError) {
            console.error(requestError);
            Sentry.captureException(requestError);
            if(requestError.response.data.error) {
                setError("Er is iets fout gegaan, probeer het later opnieuw. (" + requestError.response.data.error + ")");
            } else {
                setError("Er is iets fout gegaan, probeer het later opnieuw.");
            }
            setSaving(false);
        }
    }, [editedChapter, handleClose, onChapterAdded, onChapterUpdated, shopsContext.currentShop, chapter, createNew]);

    return (
        <Modal
            show={ show }
            onHide={ handleClose }
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    { createNew ? "Nieuw hoofdstuk toevoegen" : "Hoofdstuk bewerken" }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <div className="form-group mb-0">
                    <label htmlFor="title">Titel</label>
                    <input
                        type="text"
                        className="form-control"
                        id="title"
                        value={ editedChapter ? editedChapter.title : "" }
                        onChange={ (event) => setEditedChapter({ ...editedChapter, title: event.target.value }) }
                        disabled={ saving }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ saving }>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={ handleSave } disabled={ saving }>
                    { saving && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
CourseChapterEditModal.propTypes = {
    show: PropTypes.bool,
    course: PropTypes.object,
    chapter: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    onChapterUpdated: PropTypes.func,
    onChapterAdded: PropTypes.func,
    createNew: PropTypes.bool
}

export default React.memo(CourseChapterEditModal);
