import React, {
    useContext
} from "react";

import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";
import DetailOverviewRow from "../../components/DetailOverviewRow";

function AccountOverview() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);
    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-borderless mb-0">
                    <tbody>
                        <DetailOverviewRow title="Naam">
                            { authenticatedUserContext.user.name }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Email">
                            { authenticatedUserContext.user.email }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="2 Factor Authenticatie">
                            { authenticatedUserContext.user.otpEnabled ? (
                                <i className="fas fa-check text-success"/>
                            ) : (
                                <i className="fas fa-times text-danger"/>
                            )}
                        </DetailOverviewRow>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default React.memo(AccountOverview);
