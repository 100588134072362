import React from "react";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import ShopsContext from "../../../../context/ShopsContext";
import axios from "axios";

class ResendDigitalProductsLinkEmailModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            success: false,
            loading: false
        }
        this.onShow = this.onShow.bind(this);
        this.onSend = this.onSend.bind(this);
    }

    onShow() {
        this.setState({ error: null, success: false });
    }

    onSend() {
        this.setState({ loading: true, error: null, success: false });
        axios.post("/resendDigitalProductsLinkEmail", { shopId: this.context.currentShop.id, orderId: this.props.orderId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ success: true });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>Digitale link opnieuw verzenden</Modal.Title>
                </Modal.Header>
                <form className="mb-0">
                    <Modal.Body>
                        { this.state.error && (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        )}
                        { this.state.success ? (
                            <div className="text-center">
                                <h1 className="text-success"><i className="fas fa-paper-plane"/></h1>
                                <h4 className="mb-0">Digitale link verzonden!</h4>
                            </div>
                        ) : (
                            <p className="mb-0">
                                Weet je zeker dat je de digitale producten link opnieuw wilt versturen?
                            </p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        { this.state.success ? (
                            <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                Sluiten
                            </Button>
                        ) : (
                            <React.Fragment>
                                <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                    Annuleer
                                </Button>
                                <Button variant="primary" onClick={ this.onSend } disabled={ this.state.loading }>
                                    { this.state.loading && (
                                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                                    )}
                                    Versturen
                                </Button>
                            </React.Fragment>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
ResendDigitalProductsLinkEmailModal.contextType = ShopsContext;
ResendDigitalProductsLinkEmailModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired
}

export default ResendDigitalProductsLinkEmailModal;
