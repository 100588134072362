import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import Loading from "../../../components/Loading";
import ShopsContext from "../../../context/ShopsContext";

function ShopUserDetailEdit({ shopUser, refreshShopUser }) {
    const shopsContext = useContext(ShopsContext);
    const [editedShopUser, setEditedShopUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [madeChanges, setMadeChanges] = useState(false);

    useEffect(() => {
        setEditedShopUser(shopUser);
        setMadeChanges(false);
    }, [shopUser]);

    const onSave = useCallback(async () => {
        setError(null);
        setSuccess(false);
        setLoading(true);
        try {
            await axios.post("/setShopUser", {
                shopId: shopsContext.currentShop.id,
                shopUserId: shopUser.id,
                name: editedShopUser.name,
                email: editedShopUser.email
            });
            setSuccess(true);
            setMadeChanges(false);
            refreshShopUser();
        } catch(requestError) {
            console.error(requestError);
            const errorCode = requestError.response?.data?.error;
            switch(errorCode) {
                case "INVALID_NAME":
                    setError("Vul een naam in.");
                    break;
                case "INVALID_EMAIL":
                    setError("Vul een geldig e-mailadres in.");
                    break;
                case "EMAIL_ALREADY_IN_USE":
                    setError("Dit e-mailadres is al in gebruik door een ander account.");
                    break;
                default:
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                    break;
            }
        } finally {
            setLoading(false);
        }
    }, [shopUser, editedShopUser, shopsContext, refreshShopUser]);
    const onChange = useCallback((key, value) => {
        setEditedShopUser({
            ...editedShopUser,
            [key]: value
        });
        setSuccess(false);
        setMadeChanges(true);
    }, [editedShopUser]);

    if(!editedShopUser) {
        return (
            <Loading/>
        );
    }
    return (
        <React.Fragment>
            { success && (
                <Alert variant="success">
                    De wijzigingen zijn opgeslagen.
                </Alert>
            )}
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div className="card mb-3">
                <div className="card-body">
                    <h5>Gebuikersgegevens</h5>
                    <div className="form-group">
                        <label htmlFor="name">Naam</label>
                        <input
                            type="text"
                            id="name"
                            className="form-control"
                            value={editedShopUser.name}
                            onChange={(event) => onChange("name", event.target.value)}
                            disabled={loading}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">E-mailadres</label>
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            value={editedShopUser.email}
                            onChange={(event) => onChange("email", event.target.value)}
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
                <div>
                    <Button variant="primary" onClick={onSave} disabled={!madeChanges || loading}>
                        { loading && (
                            <Spinner animation="border" size="sm" className="mr-2"/>
                        )}
                        Opslaan
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}
ShopUserDetailEdit.propTypes = {
    shopUser: PropTypes.object,
    refreshShopUser: PropTypes.func.isRequired
};

export default React.memo(ShopUserDetailEdit);
