import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import {
    AuthenticationPage,
    AuthenticationPageColumn,
    AuthenticationPageFooter
} from "@zandor300/backoffice-framework";

import AuthenticatedUserContext from "../context/AuthenticatedUserContext";

import Logo from "../components/Logo";
import Helmet from "../components/Helmet";
import Copyright from "../components/Copyright";

function OTPVerification() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);

    const [otp, setOTP] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    const onError = useCallback((errorMessage) => {
        setError(errorMessage);
        setLoading(false);
    }, []);
    const onSubmit = useCallback((event) => {
        event.preventDefault();
        setError(null);
        setLoading(true);
        authenticatedUserContext.otpVerification(otp, onError);
    }, [authenticatedUserContext.otpVerification, onError, otp]);

    const onChange = useCallback((event) => {
        setOTP(event.target.value);
    }, []);

    useEffect(() => {
        inputRef.current?.focus();
    }, [inputRef]);

    return (
        <AuthenticationPage>
            <Helmet title="2 Factor Authenticatie"/>
            <AuthenticationPageColumn>
                <Logo className="login-logo" style={{ maxWidth: "100%" }}/>
                <h3 className="mt-0 text-center text-muted mb-4">2 Factor Authenticatie</h3>
                <div className="text-center mb-3">
                    <button onClick={ authenticatedUserContext.logout } className="btn btn-link btn-sm">
                        Uitloggen
                    </button>
                </div>
                { error !== null && (
                    <div className="alert alert-danger">{ error }</div>
                )}
                <form onSubmit={ onSubmit }>
                    <div className="form-group">
                        <input
                            ref={ inputRef }
                            type="text"
                            className="form-control"
                            id="otp"
                            placeholder="000000"
                            disabled={ loading }
                            value={ otp }
                            onChange={ onChange }
                            autoComplete="one-time-code"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary float-right">
                        Inloggen
                    </button>
                </form>
            </AuthenticationPageColumn>
            <AuthenticationPageFooter>
                <Copyright/>
            </AuthenticationPageFooter>
        </AuthenticationPage>
    )
}

export default React.memo(OTPVerification);
