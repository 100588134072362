import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import SimpleMDEEditor from "react-simplemde-editor";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";

import ShopsContext from "../../../../context/ShopsContext";

function CourseLessonEditModal({ show, lesson, chapter, handleClose, onLessonUpdated, onLessonAdded, createNew = false }) {
    const shopsContext = useContext(ShopsContext);
    const [editedLesson, setEditedLesson] = useState(null);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(!show || (!lesson && !chapter)) {
            return;
        }
        if(createNew) {
            setEditedLesson({
                visible: false,
                title: "",
                content: ""
            });
        } else {
            setEditedLesson(lesson);
        }
        setError(null);
        setSaving(false);
    }, [lesson, show, createNew, chapter]);

    const handleSave = useCallback(async () => {
        setError(null);
        setSaving(true);
        try {
            if(createNew) {
                const response = await axios.post("/addCourseLesson", {
                    shopId: shopsContext.currentShop.id,
                    courseChapterId: chapter.id,
                    visible: editedLesson.visible ? 1 : 0,
                    title: editedLesson.title,
                    content: editedLesson.content
                });
                onLessonAdded(response.data.courseLesson);
            } else {
                const response = await axios.post("/setCourseLesson", {
                    shopId: shopsContext.currentShop.id,
                    courseLessonId: editedLesson.id,
                    visible: editedLesson.visible ? 1 : 0,
                    title: editedLesson.title,
                    content: editedLesson.content
                });
                onLessonUpdated(response.data.courseLesson);
            }
            handleClose();
        } catch(requestError) {
            console.error(requestError);
            Sentry.captureException(requestError);
            if(requestError.response.data.error) {
                setError("Er is iets fout gegaan, probeer het later opnieuw. (" + requestError.response.data.error + ")");
            } else {
                setError("Er is iets fout gegaan, probeer het later opnieuw.");
            }
            setSaving(false);
        }
    }, [editedLesson, handleClose, onLessonUpdated, onLessonAdded, shopsContext.currentShop, chapter, createNew]);

    const simpleMdeOptions = useMemo(() => {
        return {
            spellChecker: false,
            autoDownloadFontAwesome: false,
            toolbar: ["bold", "italic", "heading", "|", "quote", "unordered-list", "ordered-list", "|", "link", "horizontal-rule", "|", "guide"]
        }
    }, []);
    const onContentChange = useMemo(() => {
        return (value) => {
            setEditedLesson((prevEditedLesson) => ({ ...prevEditedLesson, content: value }));
        }
    }, []);

    return (
        <Modal
            show={ show }
            onHide={ handleClose }
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    { createNew ? "Nieuwe les toevoegen" : "Les bewerken" }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <div className="custom-control custom-switch mb-2">
                    <input
                        type="checkbox"
                        className="custom-control-input"
                        id="visible"
                        checked={ editedLesson ? editedLesson.visible : false }
                        onChange={ (event) => setEditedLesson({ ...editedLesson, visible: event.target.checked }) }
                        disabled={ saving }
                    />
                    <label className="custom-control-label" htmlFor="visible">Zichtbaar maken voor cursisten</label>
                </div>
                <div className="form-group">
                    <label htmlFor="title">Titel</label>
                    <input
                        type="text"
                        className="form-control"
                        id="title"
                        value={ editedLesson ? editedLesson.title : "" }
                        onChange={ (event) => setEditedLesson({ ...editedLesson, title: event.target.value }) }
                        disabled={ saving }
                    />
                </div>
                <SimpleMDEEditor
                    value={ editedLesson ? editedLesson.content : "" }
                    onChange={ onContentChange }
                    options={ simpleMdeOptions }
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ saving }>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={ handleSave } disabled={ saving }>
                    { saving && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
CourseLessonEditModal.propTypes = {
    show: PropTypes.bool,
    lesson: PropTypes.object,
    chapter: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    onLessonUpdated: PropTypes.func,
    onLessonAdded: PropTypes.func,
    createNew: PropTypes.bool
}

export default React.memo(CourseLessonEditModal);
