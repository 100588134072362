import React from "react";
import axios from "axios";
import {
    Redirect
} from "react-router-dom";
import {
    Alert,
    Button
} from "react-bootstrap";
import PropTypes from "prop-types";
import {
    sortableContainer,
    sortableElement
} from "react-sortable-hoc";
import arrayMove from "array-move";
import {
    NewTabOpener
} from "new-tab-opener";

import {
    ShopsContextPropTypes,
    withShopsContext
} from "../../../context/ShopsContext";
import Loading from "../../../components/Loading";
import ProductLinkCard from "./components/ProductLinkCard";
import AddEditProductDigitalLinkModal from "./modal/AddEditProductDigitalLinkModal";

const SortableItem = sortableElement(({children}) => (
    <div>{children}</div>
));

const SortableContainer = sortableContainer(({children}) => (
    <div>{children}</div>
));

class ProductDetailDigitalLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            links: null,
            disabledLinks: null,
            error: null,
            success: null,
            savingError: null,
            changedOrder: false,
            savingOrder: false,

            showAddLinkModal: false,

            showEditLinkModal: false,
            editLinkModalLink: null
        }
        this.openDemo = this.openDemo.bind(this);
        this.getLinks = this.getLinks.bind(this);
        this.setLinkEnabled = this.setLinkEnabled.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this.setLinksOrder = this.setLinksOrder.bind(this);
    }

    componentDidMount() {
        if(this.props.product) {
            this.getLinks();
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((!prevProps.product && this.props.product) || (prevProps.product && this.props.product && prevProps.product.id !== this.props.product.id)) {
            this.getLinks();
        }
    }

    getLinks() {
        const currentShop = this.props.shopsContext.currentShop;
        this.setState({ error: null, success: null });
        axios.post("/getProductLinks", { shopId: currentShop.id, productId: this.props.product.id })
            .then((response) => {
                if(response.data.valid) {
                    this.setLinks(response.data.productLinks);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    openDemo() {
        const currentShop = this.props.shopsContext.currentShop;
        this.setState({ error: null });
        const newTab = new NewTabOpener();
        axios.post("/getDigitalProductDemoToken", { shopId: currentShop.id, productId: this.props.product.id })
            .then((response) => {
                if(response.data.valid) {
                    const token = response.data.token;
                    const url = currentShop.url + "/digital-product-demo/" + token;
                    newTab.open(url);
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setLinks(links) {
        let enabledLinks = links.filter((link) => {
            return link.enabled;
        });
        let disabledLinks = links.filter((link) => {
            return !link.enabled;
        });
        this.setState({
            links: enabledLinks,
            disabledLinks
        });
    }

    setLinkEnabled(productLinkId, enabled) {
        const currentShop = this.props.shopsContext.currentShop;
        this.setState({ savingError: null, success: null });
        axios.post("/setProductLinkEnabled", { shopId: currentShop.id, productId: this.props.product.id, productLinkId, state: enabled ? 1 : 0 })
            .then((response) => {
                if(response.data.valid) {
                    this.setLinks(response.data.productLinks);
                    this.setState({ success: "Link " + (enabled ? "ingeschakeld" : "uitgeschakeld") + "." });
                } else {
                    this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                    window.scroll({ top: 0, behavior: "smooth" });
                }
            })
            .catch(() => {
                this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw." });
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    setLinksOrder() {
        const currentShop = this.props.shopsContext.currentShop;
        this.setState({ savingOrder: true, savingError: null, success: null });
        const productLinkOrder = this.state.links.map((image) => image.id).join(",");
        axios.post("/setProductLinksOrder", { shopId: currentShop.id, productId: this.props.product.id, productLinkOrder })
            .then((response) => {
                if(response.data.valid) {
                    this.setLinks(response.data.productLinks);
                    this.setState({ changedOrder: false, success: "Link volgorde opgeslagen." });
                } else {
                    this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                    window.scroll({ top: 0, behavior: "smooth" });
                }
            })
            .catch(() => {
                this.setState({ savingError: "Er ging iets fout. Probeer het later opnieuw." });
                window.scroll({ top: 0, behavior: "smooth" });
            })
            .finally(() => {
                this.setState({ savingOrder: false });
            });
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState(({links}) => {
            return { links: arrayMove(links, oldIndex, newIndex), changedOrder: true }
        });
    }

    render() {
        const {
            shopsContext,
            product,
            baseUrl
        } = this.props;
        const {
            links,
            disabledLinks,
            error,
            success,
            savingError,
            savingOrder,
            changedOrder,
            showAddLinkModal,
            showEditLinkModal,
            editLinkModalLink
        } = this.state;
        const currentShop = shopsContext.currentShop;
        if(product && !product.digital) {
            return (
                <Redirect to={ baseUrl }/>
            )
        }
        if(error) {
            return (
                <Alert variant="danger">{ error }</Alert>
            )
        }
        if(!links) {
            return (
                <Loading/>
            )
        }
        return (
            <React.Fragment>
                <AddEditProductDigitalLinkModal
                    show={ showAddLinkModal }
                    handleClose={ () => { this.setState({ showAddLinkModal: false }) } }
                    product={ product }
                    linkAdded={ this.getLinks }
                />
                <AddEditProductDigitalLinkModal
                    show={ showEditLinkModal }
                    handleClose={ () => { this.setState({ showEditLinkModal: false }) } }
                    product={ product }
                    productLink={ editLinkModalLink }
                    linkEdited={ this.getLinks }
                />
                { links.length === 0 && disabledLinks.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-shopping-basket"/></h1>
                        <h4>Geen links</h4>
                        <p>Dit product heeft nog geen links.</p>
                        <Button variant="primary" onClick={ () => { this.setState({ showAddLinkModal: true }) } }>
                            <i className="fas fa-plus mr-2"/>
                            Eerste link toevoegen
                        </Button>
                    </div>
                ) : (
                    <React.Fragment>
                        { success && (
                            <Alert variant="success">{ success }</Alert>
                        )}
                        { savingError && (
                            <Alert variant="danger">{ savingError }</Alert>
                        )}
                        <div className="d-flex flex-row mb-2">
                            <Button variant="primary" className="mr-2" onClick={ this.setLinksOrder } disabled={ savingOrder || !changedOrder }>
                                <i className="fas fa-save mr-2"/>
                                Volgorde opslaan
                            </Button>
                            <Button variant="secondary" className="mr-2" onClick={ () => this.setState({ showAddLinkModal: true }) } disabled={ savingOrder }>
                                <i className="fas fa-plus mr-2"/>
                                Link toevoegen
                            </Button>
                            <div className="flex-grow-1"/>
                            { currentShop.entitlementDigitalProductDemo && (
                                <Button variant="secondary" onClick={ this.openDemo } disabled={ savingOrder }>
                                    Demo openen
                                    <i className="fas fa-external-link-square ml-2"/>
                                </Button>
                            )}
                        </div>
                        { links.length === 0 ? (
                            <p className="mt-3">Geen ingeschakelde links.</p>
                        ) : (
                            <SortableContainer onSortEnd={ this.onSortEnd }>
                                { links.map((link, index) => (
                                    <SortableItem key={ link.id } index={ index } disabled={ savingOrder }>
                                        <ProductLinkCard
                                            link={ link }
                                            style={{ cursor: "grab" }}
                                            setLinkEnabled={ this.setLinkEnabled }
                                            onEdit={ () => this.setState({ showEditLinkModal: true, editLinkModalLink: link }) }
                                        />
                                    </SortableItem>
                                ))}
                            </SortableContainer>
                        )}
                        { disabledLinks.length > 0 && (
                            <React.Fragment>
                                <h4 className="mt-3">Uitgeschakelde links</h4>
                                { disabledLinks.map((link, index) => (
                                    <ProductLinkCard
                                        link={ link }
                                        key={ index }
                                        setLinkEnabled={ this.setLinkEnabled }
                                        onEdit={ () => this.setState({ showEditLinkModal: true, editLinkModalLink: link }) }
                                    />
                                ))}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
ProductDetailDigitalLinks.propTypes = {
    product: PropTypes.object,
    baseUrl: PropTypes.string,
    shopsContext: ShopsContextPropTypes
}

export default withShopsContext(ProductDetailDigitalLinks);
