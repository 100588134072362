import React, {
    useState
} from "react";
import PropTypes from "prop-types";
import {
    Modal
} from "react-bootstrap";

function ChartModal({ show, handleClose, title, children }) {
    const [showing, setShowing] = useState(false);
    return (
        <Modal size="fullscreen" show={ show } onHide={ handleClose } onShow={ () => setShowing(true) } onExited={ () => setShowing(false) }>
            <Modal.Header closeButton>
                <Modal.Title className="d-flex flex-row flex-grow-1">
                    <div className="ml-2 mr-3">
                        <i className="fas fa-chart-bar"/>
                    </div>
                    <div className="flex-grow-1">
                        { title }
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-3">
                <div className="h-100">
                    { showing && children }
                </div>
            </Modal.Body>
        </Modal>
    )
}
ChartModal.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
    title: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.object
    ]),
    children: PropTypes.element
}

export default React.memo(ChartModal);
