/* eslint-disable max-lines */
import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";

import Loading from "../Loading";
import ProductTypePill from "../ProductTypePill";
import ShopsContext from "../../context/ShopsContext";

class BaseAddProductModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            products: null,
            filteredProducts: null,
            saving: false,
            error: null,
            errorSave: null,

            searchValue: "",
            selectedProduct: null
        }
        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onProductSelected = this.onProductSelected.bind(this);
        this.onSearchValueChange = this.onSearchValueChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDidPressEnter = this.onDidPressEnter.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        this.searchInput = React.createRef();
        this.activeProductListItem = React.createRef();
    }

    // eslint-disable-next-line no-unused-vars
    abstractSave(productId, onComplete, onError) {
        console.error("abstractSave from BaseAddProductModal not implemented!");
    }

    abstractGetTitle() {
        console.error("abstractGetTitle from BaseAddProductModal not implemented!");
        return null;
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if((!prevProps.show && this.props.show) || (!prevState.products && this.state.products)) {
            setTimeout(() => {
                if(this.searchInput.current) {
                    this.searchInput.current.focus();
                }
            }, 100);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onShow() {
        this.setState({ saving: false, selectedProduct: null, searchValue: "" });
        if(!this.state.products) {
            this.getProducts();
        } else {
            this.setState((prevState) => {
                return { filteredProducts: this.filterProducts(prevState.products, null) }
            })
        }
        document.addEventListener("keydown", this.onKeyDown);
    }

    onHide() {
        this.props.handleClose();
        document.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown(event) {
        if(event.keyCode === 38) {
            // ArrowUp
            event.preventDefault();
            this.handleArrowKeyPress("up");
        } else if(event.keyCode === 40) {
            // ArrowDown
            event.preventDefault();
            this.handleArrowKeyPress("down");
        }
    }

    handleArrowKeyPress(direction) {
        this.setState((prevState) => {
            const products = prevState.filteredProducts;
            if(products.length === 0) {
                return {};
            }
            const selectedProductId = prevState.selectedProduct;
            if(selectedProductId == null && direction === "down") {
                if(direction === "down" && this.searchInput.current && this.searchInput.current === document.activeElement) {
                    this.searchInput.current.blur();
                    return { selectedProduct: products[0].id };
                }
                return {};
            }
            const index = products.findIndex((findProduct) => findProduct.id === selectedProductId);

            if(direction === "up") {
                if(index <= 0) {
                    if(this.searchInput.current) {
                        this.searchInput.current.focus();
                    }
                    return { selectedProduct: null };
                }
                const newSelectedProduct = products[index - 1];
                return { selectedProduct: newSelectedProduct.id };
            } else if(direction === "down") {
                if(index + 1 >= products.length) {
                    return {};
                }
                const newSelectedProduct = products[index + 1];
                return { selectedProduct: newSelectedProduct.id };
            }
            return {};
        }, () => {
            if(this.activeProductListItem.current) {
                this.activeProductListItem.current.scrollIntoView({ block: "center" });
            }
        });
    }

    onDidPressEnter(event) {
        if(event.keyCode === 13) {
            this.onSave(event);
        }
    }

    getProducts() {
        this.setState({ products: null, filteredProducts: null });
        axios.post("/getProducts", { shopId: this.context.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    const products = response.data.products;
                    this.setState({ products, filteredProducts: this.filterProducts(products, this.state.searchValue) });
                } else {
                    this.setState({ error: "Er ging iets fout bij het laden van de producten. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het laden van de producten." });
                console.error(error);
            });
    }

    onSave(event) {
        if(event) {
            event.preventDefault();
        }
        if(!this.state.selectedProduct) {
            this.setState({ errorSave: "Selecteer een product." });
            return;
        }
        this.setState({ saving: true, errorSave: null });
        this.abstractSave(this.state.selectedProduct, () => {
            this.props.handleClose();
        }, (error) => {
            this.setState({ saving: false, errorSave: error });
        })
    }

    filterProducts(products, searchValue) {
        if(!products) {
            return products;
        }
        if(!searchValue || searchValue.trim().length === 0) {
            return products;
        }
        const searchValueParts = searchValue.trim().toLowerCase().split(" ");
        return products.filter((product) => {
            for(const part of searchValueParts) {
                if(!this.matchProduct(product, part)) {
                    return false;
                }
            }
            return true;
        });
    }

    matchProduct(product, filter) {
        return product.name.toLowerCase().includes(filter);
    }

    onProductSelected(productId) {
        this.setState({ selectedProduct: productId });
    }

    onSearchValueChange(event) {
        const searchValue = event.target.value;
        this.setState({ searchValue, filteredProducts: this.filterProducts(this.state.products, searchValue) });
    }

    doesProductExistAlready(product) {
        const existingProducts = this.props.existingProducts;
        if(!existingProducts || !product) {
            return false;
        }
        return existingProducts.find((existingProduct) => existingProduct.id === product.id) !== undefined;
    }

    getSelectedProduct() {
        const products = this.state.products;
        const selectedProductId = this.state.selectedProduct;
        if(!products || !selectedProductId) {
            return null;
        }
        return products.find((product) => product.id === selectedProductId);
    }

    canSubmit() {
        const selectedProduct = this.getSelectedProduct();
        return this.getSelectedProduct() !== null && !this.doesProductExistAlready(selectedProduct);
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.onHide } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>{ this.abstractGetTitle() }</Modal.Title>
                </Modal.Header>
                <form className="mb-0" onSubmit={ this.onSave }>
                    <Modal.Body>
                        { this.state.error ? (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        ) : !this.state.products || !this.state.filteredProducts ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                { this.state.errorSave && (
                                    <Alert variant="danger">{ this.state.errorSave }</Alert>
                                )}
                                <Form.Control
                                    ref={ this.searchInput }
                                    type="search"
                                    value={ this.state.searchValue }
                                    onChange={ this.onSearchValueChange }
                                    className="mb-3"
                                    placeholder="Zoeken"
                                    disabled={ this.state.saving }
                                />
                                <ul className="list-group list-group-border list-group-scroll" style={{
                                    height: "500px"
                                }}>
                                    { this.state.filteredProducts.map((product) => {
                                        const active = product.id === this.state.selectedProduct
                                        let listGroupItemClassNames = ["list-group-item", "list-group-item-hover", "pointer-cursor"];
                                        if(active) {
                                            listGroupItemClassNames.push("active");
                                        }
                                        return (
                                            <li
                                                key={ product.id }
                                                className={ listGroupItemClassNames.join(" ") }
                                                onClick={ this.onProductSelected.bind(this, product.id) }
                                                ref={ active ? this.activeProductListItem : undefined }
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                        { product.name }
                                                    </div>
                                                    <div>
                                                        <ProductTypePill product={ product } light={ active }/>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    } )}
                                    { this.state.filteredProducts.length === 0 && (
                                        <li className="list-group-item text-center d-flex justify-content-center text-muted flex-column h-100">
                                            <h4><i className="fas fa-search"/></h4>
                                            <p>
                                                Geen producten die matchen met je zoekopdracht.
                                            </p>
                                        </li>
                                    )}
                                </ul>
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                    <Modal.Footer>
                        { this.doesProductExistAlready(this.getSelectedProduct()) && (
                            <p className="text-danger mt-0">Dit product is al toegevoegd</p>
                        )}
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.saving }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ this.onSave } disabled={ this.state.saving || !this.canSubmit() }>
                            { this.state.saving && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Toevoegen
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

}
BaseAddProductModal.contextType = ShopsContext;
BaseAddProductModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    existingProducts: PropTypes.array.isRequired
}

export default BaseAddProductModal;
