import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    OverlayTrigger,
    Spinner,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import ReactRouterPropTypes from "react-router-prop-types";

import ShopsContext from "../../context/ShopsContext";
import Loading from "../../components/Loading";
import TagPill from "../../components/pills/TagPill";
import DateFormatter from "../../components/DateFormatter";
import StatusPill from "../../components/pills/StatusPill";
import Helmet from "../../components/Helmet";

function Shipments({ match }) {
    const shopsContext = useContext(ShopsContext);
    const [shipments, setShipments] = useState(null);
    const [totalShipmentCount, setTotalShipmentCount] = useState(null);
    const [error, setError] = useState(null);
    const [loadingNext, setLoadingNext] = useState(false);

    const [selectedShipments, setSelectedShipments] = useState([]);
    const [exporting, setExporting] = useState(false);
    const [exportError, setExportError] = useState(null);

    const prevFilter = useRef(null);
    const filter = useMemo(() => {
        const filters = [
            "all",
            "running",
            "finished"
        ];
        if(!match.params.filter) {
            return "running";
        }
        if(!filters.includes(match.params.filter)) {
            return null;
        }
        return match.params.filter.toLowerCase();
    }, [match.params.filter]);

    const getShipments = useCallback(async (requestFilter, previousShipment) => {
        if(previousShipment === null || requestFilter !== prevFilter.current) {
            setShipments(null);
            setTotalShipmentCount(null);
            setSelectedShipments([]);
        }
        prevFilter.current = requestFilter;
        setError(null);
        setLoadingNext(true);
        try {
            const response = await axios.post("/getShipments", {
                shopId: shopsContext.currentShop.id,
                filter: requestFilter,
                orderBy: "id",
                orderDirection: "desc",
                limit: 50,
                loadNext: 1,
                previousShipmentId: previousShipment?.id ?? undefined,
            });
            setTotalShipmentCount(response.data.totalCount);
            setShipments((prevShipments) => {
                if(prevShipments === null) {
                    return response.data.shipments;
                }
                return prevShipments.concat(response.data.shipments);
            });
        } catch(requestError) {
            setError("Er ging iets fout. Probeer het later opnieuw.");
        } finally {
            setLoadingNext(false);
        }
    }, [shopsContext.currentShop.id]);

    const onLoadNext = useCallback(async () => {
        if(loadingNext) {
            return;
        }
        const lastOrder = shipments?.at(-1);
        await getShipments(filter, lastOrder);
    }, [getShipments, filter, shipments, loadingNext]);
    useEffect(() => {
        if(!filter) {
            return;
        }
        getShipments(filter, null);
    }, [filter, getShipments]);

    const didClickShipmentCheckbox = useCallback((shipment) => {
        setSelectedShipments((prevSelectedShipments) => {
            if(prevSelectedShipments.includes(shipment.id)) {
                return prevSelectedShipments.filter((selectedShipmentId) => selectedShipmentId !== shipment.id);
            }
            return prevSelectedShipments.concat(shipment.id);
        });
    }, []);
    const exportShipmentProductAmounts = useCallback(async () => {
        setExporting(true);
        setExportError(null);
        try {
            const response = await axios.post("/exportShipmentProductAmounts", {
                shopId: shopsContext.currentShop.id,
                shipmentIds: selectedShipments.join(",")
            });
            fileDownload(response.data, shopsContext.currentShop.name + " Verzendingen Export " + moment().format("YYYY-MM-DD HH:mm:ss") + ".csv");
        } catch(requestError) {
            setExportError("Er ging iets fout. Probeer het later opnieuw.");
        } finally {
            setExporting(false);
        }
    }, [selectedShipments, shopsContext.currentShop.id]);

    const shopUrl = "/shop/" + shopsContext.currentShop.codeName;
    const baseUrl = shopUrl + "/shipments";

    return (
        <React.Fragment>
            <Helmet title="Verzendingen"/>
            <Title
                preTitle="Overzicht"
                noBottom
                preChildren={selectedShipments.length === 0 ? null : (
                    <div className="float-right mt-2">
                        <OverlayTrigger overlay={
                            <Tooltip id="reset">Download CSV export</Tooltip>
                        }>
                            <Button
                                variant="secondary"
                                onClick={ exportShipmentProductAmounts }
                                disabled={ exporting }
                            >
                                <i className="fas fa-fw fa-file-csv"/>
                            </Button>
                        </OverlayTrigger>
                    </div>
                )}
            >
                Verzendingen
                <small>
                    <TagPill className="ml-2">{ totalShipmentCount ?? 0 }</TagPill>
                </small>
            </Title>
            <TabBar noBottom={ !exportError }>
                <TabBarItem to={ baseUrl }>
                    Lopende
                </TabBarItem>
                <TabBarItem to={ baseUrl + "/finished" }>
                    Afgerond
                </TabBarItem>
                <TabBarItem to={ baseUrl + "/all" }>
                    Alle
                </TabBarItem>
            </TabBar>
            { exportError && (
                <Alert variant="danger" className="mb-3">{ exportError }</Alert>
            )}
            <Table size="sm" className="results" hover={ shipments && shipments.length > 0 }>
                <thead className="thead-light">
                <tr className="tr-sticky">
                    <th/>
                    <th className="text-center">#</th>
                    <th className="text-left">Status</th>
                    <th className="text-left">Naam</th>
                    <th className="text-left">Adres</th>
                    <th className="text-right">Aantal producten</th>
                    <th className="text-left">Datum</th>
                </tr>
                </thead>
                <tbody>
                { error ? (
                    <tr>
                        <td colSpan={ 8 }>
                            <Alert variant="danger" className="mb-0">{ error }</Alert>
                        </td>
                    </tr>
                ) : !shipments ? (
                    <tr>
                        <td colSpan={ 8 }>
                            <Loading/>
                        </td>
                    </tr>
                ) : shipments.length === 0 ? (
                    <tr>
                        <td colSpan={ 8 } className="text-center">
                            <h1><i className="fas fa-box-open"/></h1>
                            <h4>Geen verzendingen</h4>
                            <p>Er zijn nog geen verzendingen aangemaakt.</p>
                        </td>
                    </tr>
                ) : shipments.map((shipment) => {
                    const url = shopUrl + "/shipment/" + shipment.id;
                    return (
                        <tr key={shipment.id} className="table-clickable-row">
                            <th scope="row" className="align-middle text-center">
                                <div className="custom-control custom-checkbox">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={"shipment" + shipment.id}
                                        onChange={didClickShipmentCheckbox.bind(this, shipment)}
                                    />
                                    <label className="custom-control-label" htmlFor={"shipment" + shipment.id}/>
                                </div>
                            </th>
                            <th scope="row" className="align-middle text-center">
                                <Link to={url}>
                                    {shipment.id}
                                </Link>
                            </th>
                            <td className="align-middle">
                                <Link to={url}>
                                    <StatusPill status={shipment.status}/>
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={url}>
                                    {shipment.order.deliveryFirstName + " " + shipment.order.deliveryLastName}
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={url}>
                                    {shipment.order.deliveryStreet + " " + shipment.order.deliveryHouseNumber + ", " + shipment.order.deliveryCity}
                                </Link>
                            </td>
                            <td className="align-middle text-right">
                                <Link to={url}>
                                    {shipment.products.length === 0 ? 0 : shipment.products.map((product) => product.amount).reduce((amount1, amount2) => amount1 + amount2)}
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={url}>
                                    <DateFormatter date={shipment.createdDate}/>
                                </Link>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
            { shipments && totalShipmentCount > shipments.length && (
                <div className="d-flex justify-content-center">
                    <div>
                        <Button
                            variant="outline-primary"
                            onClick={onLoadNext}
                            disabled={loadingNext}
                        >
                            { loadingNext && (
                                <Spinner animation="border" size="sm" className="mr-2"/>
                            )}
                            Meer laden
                        </Button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

Shipments.propTypes = {
    match: ReactRouterPropTypes.match
};

export default React.memo(Shipments);
