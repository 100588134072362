import React from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/DetailOverviewRow";
import ProductDescriptionMarkdown from "../../../components/ProductDescriptionMarkdown";
import ProductTypePill from "../../../components/ProductTypePill";
import PriceFormatter from "../../../components/priceFormatter";

function ProductDetailOverview({ product }) {
    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <table className="table table-borderless mb-0">
                        <tbody>
                        <DetailOverviewRow title="Naam">
                            {product ? (
                                product.name
                            ) : (
                                <Skeleton width={150}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Type">
                            {product ? (
                                <ProductTypePill product={product}/>
                            ) : (
                                <Skeleton width={100}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Bestelbaar">
                            {product ? (
                                product.orderable ? (
                                    <i className="fas fa-check text-success"/>
                                ) : (
                                    <i className="fas fa-times text-danger"/>
                                )
                            ) : (
                                <Skeleton width={30}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Prijs">
                            {product ? (
                                product.discountPrice !== null ? (
                                    <React.Fragment>
                                        <span style={{textDecoration: "line-through"}} className="text-muted">
                                            <PriceFormatter price={product.price}/>
                                        </span>
                                        {" "}
                                        <PriceFormatter price={product.discountPrice}/>
                                    </React.Fragment>
                                ) : (
                                    <PriceFormatter price={product.price}/>
                                )
                            ) : (
                                <Skeleton width={75}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="BTW percentage">
                            {product ? (
                                <span>{Math.round(product.taxPercentage * 100)} %</span>
                            ) : (
                                <Skeleton width={75}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Beschrijving">
                            {product ? (
                                <ProductDescriptionMarkdown>
                                    {product.description}
                                </ProductDescriptionMarkdown>
                            ) : (
                                <Skeleton count={5}/>
                            )}
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Samenvatting">
                            {product ? (
                                <ProductDescriptionMarkdown>
                                    {product.summary}
                                </ProductDescriptionMarkdown>
                            ) : (
                                <Skeleton count={2}/>
                            )}
                        </DetailOverviewRow>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

ProductDetailOverview.propTypes = {
    product: PropTypes.object
}

export default ProductDetailOverview;
