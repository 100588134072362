import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import ReactRouterPropTypes from "react-router-prop-types";

import ShopsContext from "../../context/ShopsContext";
import Loading from "../../components/Loading";
import TagPill from "../../components/pills/TagPill";
import DateFormatter from "../../components/DateFormatter";
import StatusPill from "../../components/pills/StatusPill";
import Error404 from "../error/Error404";
import Helmet from "../../components/Helmet";

class RentalOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rentalOrders: null,
            error: null
        }
    }

    componentDidMount() {
        const filter = this.getFilter();
        if(filter) {
            this.getRentalOrders(filter);
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.filter !== prevProps.match.params.filter) {
            const filter = this.getFilter();
            if(filter) {
                this.getRentalOrders(filter);
            }
        }
    }

    getFilter() {
        const filters = [
            "all",
            "running",
            "finished",
            "canceled"
        ];
        if(!this.props.match.params.filter) {
            return "running";
        }
        if(!filters.includes(this.props.match.params.filter)) {
            return null;
        }
        return this.props.match.params.filter;
    }

    getRentalOrders(filter) {
        this.setState({ rentalOrders: null, error: null });
        axios.post("/getRentalOrders", { shopId: this.context.currentShop.id, filter })
            .then((response) => {
                if(response.data.valid) {
                    const rentalOrders = response.data.rentalOrders.sort((order1, order2) => {
                        return moment(order1.orderDate) < moment(order2.orderDate) ? 1 : -1;
                    })
                    this.setState({ rentalOrders });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch(() => {
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    render() {
        if(!this.getFilter()) {
            return (
                <Error404/>
            )
        }
        const baseUrl = "/shop/" + this.context.currentShop.codeName + "/rental-orders";
        return (
            <React.Fragment>
                <Helmet title="Huurbestellingen"/>
                <Title preTitle="Overzicht" noBottom>
                    Huurbestellingen
                    <small>
                        <TagPill className="ml-2">{ this.state.rentalOrders ? this.state.rentalOrders.length : 0 }</TagPill>
                    </small>
                </Title>
                <TabBar noBottom>
                    <TabBarItem to={ baseUrl }>
                        Lopende
                    </TabBarItem>
                    <TabBarItem to={ baseUrl + "/finished" }>
                        Voltooid
                    </TabBarItem>
                    <TabBarItem to={ baseUrl + "/canceled" }>
                        Geannuleerd
                    </TabBarItem>
                    <TabBarItem to={ baseUrl + "/all" }>
                        Alle
                    </TabBarItem>
                </TabBar>
                <Table size="sm" className="results" hover={ this.state.rentalOrders && this.state.rentalOrders.length > 0 }>
                    <thead className="thead-light">
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Naam</th>
                        <th className="text-left">Product</th>
                        <th className="text-left">Startdatum</th>
                        <th className="text-left">Einddatum</th>
                        <th className="text-left">Datum</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.state.error ? (
                        <tr>
                            <td colSpan={ 7 }>
                                <Alert variant="danger" className="mb-0">{ this.state.error }</Alert>
                            </td>
                        </tr>
                    ) : this.state.rentalOrders === null ? (
                        <tr>
                            <td colSpan={ 7 }>
                                <Loading/>
                            </td>
                        </tr>
                    ) : this.state.rentalOrders.length === 0 ? (
                        <tr>
                            <td colSpan={ 7 } className="text-center">
                                <h1><i className="fas fa-cash-register"/></h1>
                                <h4>Geen huurbestellingen</h4>
                                <p>Er zijn nog geen huurbestellingen binnengekomen.</p>
                            </td>
                        </tr>
                    ) : this.state.rentalOrders.map((rentalOrder) => {
                        const url = "/shop/" + this.context.currentShop.codeName + "/rental-order/" + rentalOrder.id;
                        return (
                            <tr key={ rentalOrder.id } className="table-clickable-row">
                                <th scope="row" className="align-middle text-center">
                                    <Link to={ url }>
                                        { rentalOrder.id }
                                    </Link>
                                </th>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        <StatusPill status={ rentalOrder.status }/>
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { rentalOrder.invoiceFirstName + " " + rentalOrder.invoiceLastName }
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { rentalOrder.product.name }
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        <DateFormatter date={ rentalOrder.rentalStartDate } format="DD-MM-YYYY"/>
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        <DateFormatter date={ rentalOrder.rentalEndDate } format="DD-MM-YYYY"/>
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        <DateFormatter date={ rentalOrder.orderDate }/>
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}
RentalOrders.contextType = ShopsContext;
RentalOrders.propTypes = {
    match: ReactRouterPropTypes.match
}

export default RentalOrders;
