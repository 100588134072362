import React from "react";
import PropTypes from "prop-types";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import ShopsContext from "../../../../context/ShopsContext";
import axios from "axios";

class CategoryRemoveProductModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null
        }
        this.onShow = this.onShow.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onShow() {
        this.setState({ loading: false, error: null });
    }

    onRemove() {
        this.setState({ loading: true, error: null });
        axios.post("/removeProductFromCategory", {
            shopId: this.context.currentShop.id,
            categoryId: this.props.category.id,
            productId: this.props.product.id
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setCategory(response.data.category);
                    this.props.handleClose();
                } else {
                    this.setState({ loading: false, error: "Er ging iets fout bij het laden van de producten. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ loading: false, error: "Er ging iets fout bij het laden van de producten." });
                console.error(error);
            });
    }

    render() {
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>Product verwijderen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { this.state.error && (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    )}
                    <p className="mb-0">
                        Weet je zeker dat je dit product wil verwijderen uit deze categorie?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                        Annuleer
                    </Button>
                    <Button variant="danger" onClick={ this.onRemove } disabled={ this.state.loading }>
                        { this.state.loading && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                        )}
                        Verwijderen
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
CategoryRemoveProductModal.contextType = ShopsContext;
CategoryRemoveProductModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    setCategory: PropTypes.func.isRequired,
    category: PropTypes.object.isRequired,
    product: PropTypes.object
}

export default CategoryRemoveProductModal;
