import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import ReactRouterPropTypes from "react-router-prop-types";
import {
    Alert,
    Button
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import Skeleton from "react-loading-skeleton";
import {
    NewTabOpener
} from "new-tab-opener";

import Helmet from "../../../components/Helmet";
import ShopsContext from "../../../context/ShopsContext";
import Error404 from "../../error/Error404";

import CourseDetailOverview from "./CourseDetailOverview";
import CourseDetailContent from "./CourseDetailContent";
import CourseDetailParticipants from "./CourseDetailParticipants";
import CourseDetailEdit from "./CourseDetailEdit";

function CourseDetail({ match }) {
    const shopsContext = useContext(ShopsContext);
    const courseId = match.params.courseId;
    const baseUrl = "/shop/" + shopsContext.currentShop.codeName + "/course/" + courseId;

    const [course, setCourse] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [demoError, setDemoError] = useState(null);
    const [demoLoading, setDemoLoading] = useState(false);

    const refreshCourse = useCallback(() => {
        setCourse(null);
        setError(null);
        setErrorCode(null);
        setDemoError(null);
        axios.post("/getCourse", { shopId: shopsContext.currentShop.id, courseId })
            .then((response) => {
                if(response.data.valid) {
                    setCourse(response.data.course);
                } else {
                    setCourse(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setCourse(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [courseId]);
    const openCourseDemo = useCallback(async () => {
        setDemoLoading(true);
        setDemoError(null);
        const newTab = new NewTabOpener();
        try {
            const response = await axios.post("/getCourseDemoToken", { shopId: shopsContext.currentShop.id, courseId });
            newTab.open(shopsContext.currentShop.url + "/course-demo/" + response.data.token);
        } catch(requestError) {
            console.error(requestError);
            setDemoError("Er is een fout opgetreden.");
        }
        setDemoLoading(false);
    }, []);

    useEffect(() => {
        if(isNaN(parseInt(courseId, 10))) {
            return;
        }
        refreshCourse();
    }, [courseId, refreshCourse]);

    const childProps = {
        courseId: parseInt(courseId, 10),
        course,
        error,
        setCourse,
        refreshCourse
    };

    if(isNaN(parseInt(courseId, 10)) || courseId < 1 || errorCode === "DOESNT_EXIST") {
        return <Error404/>
    }

    return (
        <React.Fragment>
            <Helmet title={ course ? course.title : ("Cursus #" + courseId) }/>

            <Title
                preTitle="Detail"
                noBottom={ !error }
                id={ course?.id }
                preChildren={
                    <div className="float-right d-none d-md-block">
                        { course && (
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={ openCourseDemo }
                                disabled={ demoLoading }
                                className="mt-2 ml-2"
                            >
                                { demoLoading && (
                                    <i className="fa-solid fa-circle-notch fa-spin mr-2"/>
                                )}
                                Open cursus demo <i className="fas fa-external-link-square ml-2"/>
                            </Button>
                        )}
                    </div>
                }
            >
                { error ? (
                    "Cursus"
                ) : course ? (
                    course.title
                ) : (
                    <Skeleton width={ 200 }/>
                )}
            </Title>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    <TabBar>
                        <TabBarItem to={ baseUrl }>
                            Overzicht
                        </TabBarItem>
                        <TabBarItem to={ baseUrl + "/content" }>
                            Inhoud
                        </TabBarItem>
                        <TabBarItem to={ baseUrl + "/participants" }>
                            Deelnemers
                        </TabBarItem>
                        <TabBarItem to={ baseUrl + "/edit" }>
                            Bewerken
                        </TabBarItem>
                    </TabBar>

                    { demoError && (
                        <Alert variant="danger">{ demoError }</Alert>
                    )}

                    <Switch>
                        <Route path={ baseUrl } exact>
                            { course && (
                                <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={ openCourseDemo }
                                    disabled={ demoLoading }
                                    className="mb-3 d-md-none"
                                >
                                    Open cursus demo <i className="fas fa-external-link-square ml-2"/>
                                </Button>
                            )}
                            <CourseDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ baseUrl + "/content" }>
                            <CourseDetailContent { ...childProps }/>
                        </Route>
                        <Route path={ baseUrl + "/participants" }>
                            <CourseDetailParticipants { ...childProps }/>
                        </Route>
                        <Route path={ baseUrl + "/edit" }>
                            <CourseDetailEdit { ...childProps }/>
                        </Route>

                        <Route path="/">
                            <Redirect
                                to={{
                                    pathname: baseUrl,
                                    state: {from: "/"}
                                }}
                            />
                        </Route>
                    </Switch>
                </React.Fragment>
            )}

        </React.Fragment>
    );
}
CourseDetail.propTypes = {
    match: ReactRouterPropTypes.match
}

export default React.memo(CourseDetail);
