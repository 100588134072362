import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import PropTypes from "prop-types";

import ShopsContext from "../../../context/ShopsContext";
import Loading from "../../../components/Loading";
import AddProductCourseModal from "./modal/AddProductCourseModal";
import RemoveProductCourseModal from "./modal/RemoveProductCourseModal";

function ProductDetailCourses({ product }) {
    const shopsContext = useContext(ShopsContext);
    const [courses, setCourses] = useState(null);
    const [error, setError] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [courseToRemove, setCourseToRemove] = useState(null);

    const loadCourses = useCallback(async () => {
        if(!product) {
            return;
        }
        try {
            setCourses(null);
            setError(null);
            const response = await axios.post("/getProductCourses", {
                shopId: shopsContext.currentShop.id,
                productId: product.id
            });
            setCourses(response.data.courses);
        } catch(requestError) {
            setError("Er ging iets fout. Probeer het later opnieuw.");
        }
    }, [product]);
    useEffect(() => {
        loadCourses();
    }, [loadCourses]);

    if(error) {
        return (
            <Alert variant="danger">
                { error }
            </Alert>
        );
    }
    return (
        <React.Fragment>
            <AddProductCourseModal
                product={ product }
                onSave={ loadCourses }
                show={ showAddModal }
                handleClose={ () => setShowAddModal(false) }
                existingCourses={ courses }
            />
            <RemoveProductCourseModal
                product={ product }
                courseToRemove={ courseToRemove }
                onRemoved={ loadCourses }
                show={ showRemoveModal }
                handleClose={ () => setShowRemoveModal(false) }
            />
            <div className="mb-3">
                <Button
                    variant="primary"
                    onClick={ () => setShowAddModal(true) }
                >
                    <i className="fas fa-plus mr-2"/>
                    Cursus toevoegen
                </Button>
            </div>
            <Table hover>
                <thead>
                <tr>
                    <th className="text-center">#</th>
                    <th className="text-left">Titel</th>
                    <th className="text-right d-none d-md-table-cell">Toegangsduur</th>
                    <th style={{ width: "120px" }}/>
                </tr>
                </thead>
                <tbody>
                { !courses ? (
                    <tr>
                        <td colSpan={ 4 }>
                            <Loading/>
                        </td>
                    </tr>
                ) : courses.length === 0 ? (
                    <tr>
                        <td colSpan={ 4 }>
                            Nog geen cursussen toegevoegd aan dit product.
                        </td>
                    </tr>
                ) : courses.map((course) => (
                    <tr key={ course.id }>
                        <th className="text-center align-middle">{ course.id }</th>
                        <td className="text-left align-middle">{ course.title }</td>
                        <td className="text-right align-middle d-none d-md-table-cell">
                            { course.maxAccessDuration === 1 ? (
                                "1 dag"
                            ) : (
                                course.maxAccessDuration + " dagen"
                            )}
                        </td>
                        <td className="text-right align-middle">
                            <OverlayTrigger overlay={
                                <Tooltip id="removeCourse">Cursus bekijken</Tooltip>
                            }>
                                <Link
                                    to={`/shop/${shopsContext.currentShop.codeName}/course/${course.id}`}
                                    className="btn btn-primary btn-sm ml-2"
                                >
                                    <i className="fa-solid fa-square-arrow-up-right fa-fw"/>
                                </Link>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={
                                <Tooltip id="removeCourse">Cursus verwijderen</Tooltip>
                            }>
                                <Button
                                    variant="danger"
                                    size="sm"
                                    className="ml-2"
                                    onClick={ () => {
                                        setCourseToRemove(course);
                                        setShowRemoveModal(true);
                                    } }
                                >
                                    <i className="fas fa-trash-alt fa-fw"/>
                                </Button>
                            </OverlayTrigger>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </React.Fragment>
    )
}
ProductDetailCourses.propTypes = {
    product: PropTypes.object
}

export default React.memo(ProductDetailCourses);
