import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import ReactRouterPropTypes from "react-router-prop-types";

import ShopsContext from "../../../context/ShopsContext";
import Helmet from "../../../components/Helmet";
import Error404 from "../../error/Error404";

import ShipmentDetailOverview from "./ShipmentDetailOverview";

function ShipmentDetail({ match }) {
    const shopsContext = useContext(ShopsContext);
    const shipmentId = match.params.shipmentId;
    const baseUrl = "/shop/" + shopsContext.currentShop.codeName + "/shipment/" + shipmentId;

    const [shipment, setShipment] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshShipment = useCallback(async () => {
        setShipment(null);
        setError(null);
        setErrorCode(null);
        try {
            const response = await axios.post("/getShipment", {
                shopId: shopsContext.currentShop.id,
                shipmentId: parseInt(match.params.shipmentId, 10)
            });
            setShipment(response.data.shipment);
        } catch(requestError) {
            setError("Er is een fout opgetreden bij het ophalen van de verzending. Probeer het later opnieuw.");
            setErrorCode(requestError.response?.data?.error);
        }
    }, [shopsContext, match.params.shipmentId]);
    useEffect(() => {
        if(isNaN(parseInt(shipmentId, 10))) {
            return;
        }
        refreshShipment();
    }, [shipmentId, refreshShipment]);

    const childProps = {
        shipmentId: parseInt(shipmentId, 10),
        shipment,
        error,
        setShipment,
        refreshShipment
    }

    if(isNaN(parseInt(shipmentId, 10)) || shipmentId < 1 || errorCode === "DOESNT_EXIST") {
        return <Error404/>
    }

    return (
        <React.Fragment>
            <Helmet title="Verzending"/>
            <Title preTitle="Detail" noBottom>
                Verzending <small className="text-muted">#{shipmentId}</small>
            </Title>

            <TabBar>
                <TabBarItem to={ baseUrl }>
                    Overzicht
                </TabBarItem>
            </TabBar>

            <Switch>
                <Route path={ baseUrl } exact>
                    <ShipmentDetailOverview { ...childProps }/>
                </Route>

                <Route path="/">
                    <Redirect
                        to={{
                            pathname: baseUrl,
                            state: {from: "/"}
                        }}
                    />
                </Route>
            </Switch>

        </React.Fragment>
    );
}
ShipmentDetail.propTypes = {
    match: ReactRouterPropTypes.match
}

export default React.memo(ShipmentDetail);
