import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import PropTypes from "prop-types";

import ShopsContext from "../../../../context/ShopsContext";
import ObjectSelector from "../../../../components/modal/ObjectSelector";
import AddCourseShopUserAccessItemCard from "./components/AddCourseShopUserAccessItemCard";

function AddShopUserCourseAccessModal({ show, handleClose, shopUser, onAdd }) {
    const shopsContext = useContext(ShopsContext);
    const [courses, setCourses] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [error, setError] = useState(null);

    const refreshCourses = useCallback(async () => {
        setCourses(null);
        setError(null);
        setSelectedCourse(null);
        try {
            const response = await axios.post("/getCourses", {
                shopId: shopsContext.currentShop.id
            });
            setCourses(response.data.courses);
        } catch(requestError) {
            console.error(requestError);
            setError("Er ging iets fout. Probeer het later opnieuw.");
        }
    }, [shopsContext]);
    useEffect(() => {
        if(!show) {
            return;
        }
        refreshCourses();
    }, [show, refreshCourses]);

    const onSaved = useCallback(() => {
        onAdd();
        handleClose();
    }, [onAdd, handleClose]);

    return (
        <Modal show={ show } onHide={ handleClose } size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Cursus toegang verlenen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <div className="row">
                    <div className="col-xl-5 mb-3 mb-xl-0">
                        <ObjectSelector
                            objects={ courses }
                            selectedObject={ selectedCourse }
                            setSelectedObject={ setSelectedCourse }
                            searchMatch={ (searchQuery) => (course) => course.title.toLowerCase().includes(searchQuery.toLowerCase()) }
                            objectListItemContent={ (course) => course.title }
                            emptyState={ <p>Geen cursussen gevonden</p> }
                        />
                    </div>
                    <div className="col">
                        { !selectedCourse ? (
                            <div className="d-flex flex-column h-100 text-center text-muted">
                                <div className="flex-grow-1 d-flex flex-column justify-content-center">
                                    <h3>
                                        <i className="fas fa-graduation-cap"/>
                                    </h3>
                                    <h5>
                                        Selecteer een cursus
                                    </h5>
                                </div>
                            </div>
                        ) : (
                            <React.Fragment>
                                <p>
                                    Verleen toegang tot cursus <b>{selectedCourse.title}</b> voor
                                    gebruiker <b>{shopUser.name}</b>.
                                </p>
                                <AddCourseShopUserAccessItemCard
                                    course={selectedCourse}
                                    shopUser={ shopUser }
                                    onSaved={ onSaved }
                                />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={ handleClose }
                >
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
AddShopUserCourseAccessModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    shopUser: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired
};

export default React.memo(AddShopUserCourseAccessModal);
