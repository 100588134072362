import React from "react";
import PropTypes from "prop-types";
import {
    useSortable
} from "@dnd-kit/sortable";
import {
    CSS
} from "@dnd-kit/utilities";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function CourseLessonCard({ lesson, onEdit, onDelete }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({
        id: "lesson-" + lesson.id,
        transition: {
            duration: 150,
            easing: "cubic-bezier(0.25, 1, 0.5, 1)",
        }
    });

    const style = {
        transform: transform ? CSS.Transform.toString(transform) : undefined,
        transition,
        zIndex: isDragging ? 1550 : undefined
    };
    const buttonStyle = {
        cursor: isDragging ? "grabbing" : "grab"
    }

    return (
        <div
            className="card card-course-lesson"
            ref={ setNodeRef }
            style={ style }
        >
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div className="mr-2">
                        <Button
                            variant="light"
                            { ...attributes }
                            { ...listeners }
                            style={ buttonStyle }
                        >
                            <i className="fas fa-grip-lines"/>
                        </Button>
                    </div>
                    <div className="d-flex flex-row align-items-center flex-grow-1">
                        { !lesson.visible && (
                            <div className="mr-2">
                                <OverlayTrigger overlay={
                                    <Tooltip id="visible">Les is niet zichtbaar voor cursisten</Tooltip>
                                }>
                                    <i className="fas fa-eye-slash text-muted"/>
                                </OverlayTrigger>
                            </div>
                        )}
                        { lesson.title }
                    </div>
                    { onEdit && (
                        <div className="ml-1">
                            <Button
                                variant="light"
                                onClick={ onEdit }
                            >
                                <i className="fas fa-edit"/>
                            </Button>
                        </div>
                    )}
                    { onDelete && (
                        <div className="ml-1">
                            <Button
                                variant="light"
                                className="text-danger"
                                onClick={ onDelete }
                            >
                                <i className="fas fa-trash"/>
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
CourseLessonCard.propTypes = {
    lesson: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
}

export default React.memo(CourseLessonCard);
