import React, {
    useCallback,
    useContext,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import TagPill from "../../../../components/pills/TagPill";
import ShopsContext from "../../../../context/ShopsContext";
import Loading from "../../../../components/Loading";

function ShipmentStatusHelpModal({ show, handleClose }) {
    const shopsContext = useContext(ShopsContext);
    const [statuses, setStatuses] = useState(null);
    const [error, setError] = useState(null);

    const onOpen = useCallback(async () => {
        try {
            const response = await axios.post("/getShipmentStatuses", {
                shopId: shopsContext.currentShop.id
            });
            setStatuses(response.data.shipmentStatuses);
        } catch(requestError) {
            setError("Er is een fout opgetreden bij het ophalen van de statussen. Probeer het later opnieuw.");
        }
    }, [shopsContext]);

    return (
        <Modal show={ show } onHide={ handleClose } onShow={ onOpen } size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Verzending status uitleg</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !statuses ? (
                    <Loading/>
                ) : (
                    <table className="table table-bordered mb-0">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Beschijving</th>
                            </tr>
                        </thead>
                        <tbody>
                            { statuses.map((status) => (
                                <tr key={ status.id }>
                                    <td className="text-center">
                                        <TagPill color={ status.color }>
                                            { status.name }
                                        </TagPill>
                                    </td>
                                    <td className="mb-0-last">
                                        <ReactMarkdown>
                                            { status.description }
                                        </ReactMarkdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
ShipmentStatusHelpModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
}

export default React.memo(ShipmentStatusHelpModal);
