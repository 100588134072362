import React from "react";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";

import MailchimpOAuth from "./MailchimpOAuth";
import MollieOAuth from "./MollieOAuth";
import MoneybirdOAuth from "./MoneybirdOAuth";

function OAuthSwitch() {
    return (
        <Switch>
            <Route path="/oauth/mailchimp" component={ MailchimpOAuth }/>
            <Route path="/oauth/mollie" component={ MollieOAuth }/>
            <Route path="/oauth/moneybird" component={ MoneybirdOAuth }/>
            <Route paht="/oauth">
                <Redirect to="/"/>
            </Route>
        </Switch>
    )
}

export default React.memo(OAuthSwitch);
