import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import {
    Alert,
    Button
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import CourseContentSorting from "./components/CourseContentSorting";
import CourseLessonEditModal from "./modal/CourseLessonEditModal";
import ShopsContext from "../../../context/ShopsContext";
import CourseLessonDeleteModal from "./modal/CourseLessonDeleteModal";
import CourseChapterEditModal from "./modal/CourseChapterEditModal";
import CourseChapterDeleteModal from "./modal/CourseChapterDeleteModal";

function CourseDetailContent({ course, setCourse }) {
    const shopsContext = useContext(ShopsContext);
    const [chapters, setChapters] = useState(null);
    const [error, setError] = useState(null);
    const [savingOrdering, setSavingOrdering] = useState(false);
    const [success, setSuccess] = useState(null);

    const [showChapterEditModal, setShowChapterEditModal] = useState(false);
    const [chapterEditModalChapter, setChapterEditModalChapter] = useState(null);

    const [showChapterDeleteModal, setShowChapterDeleteModal] = useState(false);
    const [chapterDeleteModalChapter, setChapterDeleteModalChapter] = useState(null);

    const [showLessonEditModal, setShowLessonEditModal] = useState(false);
    const [lessonEditModalLesson, setLessonEditModalLesson] = useState(null);
    const [lessonAddModalChapter, setLessonAddModalChapter] = useState(null);

    const [showLessonDeleteModal, setShowLessonDeleteModal] = useState(false);
    const [lessonDeleteModalLesson, setLessonDeleteModalLesson] = useState(null);

    useEffect(() => {
        if(!course) {
            return;
        }
        setChapters(course.chapters);
    }, [course]);

    const onEditChapter = useCallback((chapter) => {
        setChapterEditModalChapter(chapter);
        setShowChapterEditModal(true);
    }, []);
    const onAddChapter = useCallback(() => {
        setChapterEditModalChapter(null);
        setShowChapterEditModal(true);
    }, []);
    const onDeleteChapter = useCallback((chapter) => {
        setShowChapterDeleteModal(true);
        setChapterDeleteModalChapter(chapter);
    }, []);

    const onEditLesson = useCallback((lesson) => {
        setLessonEditModalLesson(lesson);
        setLessonAddModalChapter(null);
        setShowLessonEditModal(true);
    }, []);
    const onAddLesson = useCallback((chapter) => {
        setLessonEditModalLesson(null);
        setLessonAddModalChapter(chapter);
        setShowLessonEditModal(true);
    }, []);
    const onDeleteLesson = useCallback((lesson) => {
        setLessonDeleteModalLesson(lesson);
        setShowLessonDeleteModal(true);
    }, []);

    const handleChapterSaved = useCallback((chapter) => {
        setSuccess(null);
        setChapters((prevChapters) => {
            return prevChapters.map((prevChapter) => {
                if(prevChapter.id === chapter.id) {
                    return {
                        ...prevChapter,
                        title: chapter.title
                    };
                }
                return prevChapter;
            });
        })
    }, []);
    const handleChapterAdded = useCallback((chapter) => {
        setSuccess(null);
        setChapters((prevChapters) => {
            return [
                ...prevChapters,
                chapter
            ];
        });
    }, []);
    const handleChapterDeleted = useCallback((chapter) => {
        setSuccess(null);
        setChapters((prevChapters) => {
            return prevChapters.filter((prevChapter) => prevChapter.id !== chapter.id);
        });
    }, []);

    const handleLessonSaved = useCallback((lesson) => {
        setSuccess(null);
        setChapters((prevChapters) => {
            return prevChapters.map((prevChapter) => {
                // Find the lesson since lesson.chapterId might not match the current ordering state. The user might have moved the lesson to a different chapter.
                const foundLesson = prevChapter.lessons.find((prevLesson) => prevLesson.id === lesson.id);
                if(!foundLesson) {
                    return prevChapter;
                }
                return {
                    ...prevChapter,
                    lessons: prevChapter.lessons.map((prevLesson) => {
                        if(prevLesson.id === lesson.id) {
                            // Only update values that could have changed and keep the rest the same (Ordering)
                            return {
                                ...prevLesson,
                                visible: lesson.visible,
                                title: lesson.title,
                                content: lesson.content
                            };
                        }
                        return prevLesson;
                    })
                };
            });
        });
    }, []);
    const handleLessonAdded = useCallback((lesson) => {
        setSuccess(null);
        setChapters((prevChapters) => {
            return prevChapters.map((prevChapter) => {
                if(prevChapter.id !== lesson.chapterId) {
                    return prevChapter;
                }
                return {
                    ...prevChapter,
                    lessons: [
                        ...prevChapter.lessons,
                        lesson
                    ]
                };
            });
        })
    }, []);
    const handleLessonDeleted = useCallback((lesson) => {
        setSuccess(null);
        setChapters((prevChapters) => {
            return prevChapters.map((prevChapter) => {
                // Find the lesson since lesson.chapterId might not match the current ordering state. The user might have moved the lesson to a different chapter.
                const foundLesson = prevChapter.lessons.find((prevLesson) => prevLesson.id === lesson.id);
                if(!foundLesson) {
                    return prevChapter;
                }
                return {
                    ...prevChapter,
                    lessons: prevChapter.lessons.filter((prevLesson) => prevLesson.id !== lesson.id)
                };
            });
        });
    }, []);

    const saveSorting = useCallback(async () => {
        setError(null);
        setSuccess(null);
        setSavingOrdering(true);
        try {
            const ordering = chapters.map((chapter, index) => ({
                chapterId: chapter.id,
                ordering: index,
                lessons: chapter.lessons.map((lesson, lessonIndex) => ({
                    lessonId: lesson.id,
                    ordering: lessonIndex
                }))
            }));
            const response = await axios.post("/setCourseOrdering", {
                shopId: shopsContext.currentShop.id,
                courseId: course.id,
                ordering: JSON.stringify(ordering)
            });
            setCourse(response.data.course);
            setSuccess("Volgorde opgeslagen!");
        } catch(requestError) {
            console.error(requestError);
            Sentry.captureException(requestError);
            if(requestError.response.data.error) {
                setError("Er is iets fout gegaan, probeer het later opnieuw. (" + requestError.response.data.error + ")");
            } else {
                setError("Er is iets fout gegaan, probeer het later opnieuw.");
            }
        } finally {
            setSavingOrdering(false);
        }
    }, [chapters, shopsContext.currentShop, course]);

    if(!chapters) {
        return (
            <Loading/>
        );
    }
    return (
        <React.Fragment>
            <CourseChapterEditModal
                show={ showChapterEditModal }
                chapter={ chapterEditModalChapter }
                course={ course }
                createNew={ !chapterEditModalChapter }
                handleClose={ () => setShowChapterEditModal(false) }
                onChapterUpdated={ handleChapterSaved }
                onChapterAdded={ handleChapterAdded }
            />
            <CourseChapterDeleteModal
                show={ showChapterDeleteModal }
                chapter={ chapterDeleteModalChapter }
                handleClose={ () => setShowChapterDeleteModal(false) }
                onChapterDeleted={ handleChapterDeleted }
            />
            <CourseLessonEditModal
                show={ showLessonEditModal }
                lesson={ lessonEditModalLesson }
                chapter={ lessonAddModalChapter }
                createNew={ !lessonEditModalLesson }
                handleClose={ () => setShowLessonEditModal(false) }
                onLessonUpdated={ handleLessonSaved }
                onLessonAdded={ handleLessonAdded }
            />
            <CourseLessonDeleteModal
                show={ showLessonDeleteModal }
                lesson={ lessonDeleteModalLesson }
                handleClose={ () => setShowLessonDeleteModal(false) }
                onLessonDeleted={ handleLessonDeleted }
            />

            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            { success && (
                <Alert variant="success">{ success }</Alert>
            )}
            <Button
                variant="primary"
                onClick={ saveSorting }
                disabled={ savingOrdering }
                className="mb-3"
            >
                <i className="fas fa-save mr-2"/>
                Volgorde opslaan
            </Button>
            <CourseContentSorting
                chapters={ chapters }
                setChapters={ setChapters }
                onEditChapter={ onEditChapter }
                onAddChapter={ onAddChapter }
                onDeleteChapter={ onDeleteChapter }
                onEditLesson={ onEditLesson }
                onAddLesson={ onAddLesson }
                onDeleteLesson={ onDeleteLesson }
            />
        </React.Fragment>
    );
}
CourseDetailContent.propTypes = {
    course: PropTypes.object,
    setCourse: PropTypes.func.isRequired,
}

export default React.memo(CourseDetailContent);
