import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import PropTypes from "prop-types";

import {
    ShopsContextPropTypes,
    withShopsContext
} from "../../../context/ShopsContext";
import Loading from "../../../components/Loading";
import OrderCard from "./components/OrderCard";

function ShopUserDetailOrders({ shopUser, shopsContext }) {
    const [orders, setOrders] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!shopUser) {
            return;
        }
        setOrders(null);
        setError(null);
        axios.post("/getShopUserOrders", { shopId: shopsContext.currentShop.id, shopUserId: shopUser.id })
            .then((response) => {
                if(response.data.valid) {
                    setOrders(response.data.orders);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.");
            })
    }, [shopUser]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!orders) {
        return (
            <Loading/>
        )
    }
    if(orders.length === 0) {
        return (
            <div className="text-center">
                <h1><i className="fas fa-cash-register"/></h1>
                <h4>Geen bestellingen</h4>
                <p>Deze gebruiker heeft nog geen bestellingen geplaatst.</p>
            </div>
        )
    }
    return orders.map((order) => (
        <OrderCard order={ order } key={ order.id }/>
    ));
}

ShopUserDetailOrders.propTypes = {
    shopUser: PropTypes.object,
    shopsContext: ShopsContextPropTypes
}

export default withShopsContext(React.memo(ShopUserDetailOrders));
