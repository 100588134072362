import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

import {
    ShopsContextPropTypes,
    withShopsContext
} from "../../../../context/ShopsContext";
import AccountingApplicationLogo from "../../../../components/integration/AccountingApplicationLogo";

class CreateInvoiceModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,
            success: false,

            selectedAccountingApplication: null,
            sendInvoice: false,
            invoiceUrl: null
        }
        this.createInvoice = this.createInvoice.bind(this);
        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onShow() {
        this.setState({ error: null, loading: false, success: false, invoiceUrl: null, sendInvoice: false });
        const currentShop = this.props.shopsContext.currentShop;
        if(currentShop.accountingApplications.length === 1) {
            this.setState({ selectedAccountingApplication: currentShop.accountingApplications[0] });
        }
    }

    onHide() {
        if(this.state.success) {
            this.props.refreshOrder();
        }
        this.props.handleClose();
    }

    createInvoice() {
        this.setState({ loading: true, error: null, success: false });
        axios.post("/createOrderInvoice", {
            orderId: this.props.orderId,
            shopId: this.props.shopsContext.currentShop.id,
            accountingApplication: this.state.selectedAccountingApplication,
            send: this.state.sendInvoice ? 1 : 0
        })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ success: true, invoiceUrl: response.data.invoiceUrl });
                } else {
                    this.setState({ error: "Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            })
    }

    render() {
        const {
            show,
            shopsContext
        } = this.props;
        const {
            error,
            loading,
            success,
            selectedAccountingApplication,
            sendInvoice,
            invoiceUrl
        } = this.state;
        return (
            <Modal show={ show } onHide={ this.onHide } onShow={ this.onShow }>
                <Modal.Header closeButton>
                    <Modal.Title>Factuur aanmaken</Modal.Title>
                </Modal.Header>
                <form className="mb-0">
                    <Modal.Body>
                        { error && (
                            <Alert variant="danger">{ error }</Alert>
                        )}
                        { success ? (
                            <div className="text-center">
                                <h1 className="text-success"><i className="fas fa-paper-plane"/></h1>
                                <h4>Factuur aangemaakt!</h4>
                                { invoiceUrl && (
                                    <a href={ invoiceUrl } className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                                        Factuur bekijken
                                    </a>
                                )}
                            </div>
                        ) : (
                            <React.Fragment>
                                { shopsContext.currentShop.accountingApplications.length === 1 ? (
                                    <div className="d-flex justify-content-center mb-3">
                                        <AccountingApplicationLogo
                                            accountingApplication={ shopsContext.currentShop.accountingApplications[0] }
                                            style={{ width: "250px" }}
                                        />
                                    </div>
                                ) : (
                                    <div className="d-flex justify-content-center mb-3" style={{ gap: "0.75rem" }}>
                                        { shopsContext.currentShop.accountingApplications.map((accountingApplication) => (
                                            <div
                                                key={ accountingApplication }
                                                className="card pointer-cursor h-100"
                                                style={{
                                                    borderColor: selectedAccountingApplication === accountingApplication ? "#007bff" : undefined,
                                                }}
                                                onClick={ () => this.setState({ selectedAccountingApplication: accountingApplication }) }
                                            >
                                                <div className="card-body d-flex justify-content-center align-items-center">
                                                    <AccountingApplicationLogo
                                                        key={ accountingApplication }
                                                        accountingApplication={ accountingApplication }
                                                        className="img-fluid"
                                                        style={{
                                                            width: "200px",
                                                            height: "50px",
                                                            objectFit: "contain"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <p>
                                    Weet je zeker dat je een factuur wilt aanmaken?
                                </p>
                                <div className="custom-control custom-checkbox mb-0">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="sendInvoice"
                                        checked={ sendInvoice }
                                        onChange={ (event) => this.setState({ sendInvoice: event.target.checked }) }
                                        disabled={ loading }
                                    />
                                    <label className="custom-control-label" htmlFor="sendInvoice">
                                        Factuur direct versturen naar klant
                                    </label>
                                </div>
                            </React.Fragment>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        { success ? (
                            <Button variant="secondary" onClick={ this.onHide } disabled={ loading }>
                                Sluiten
                            </Button>
                        ) : (
                            <React.Fragment>
                                <Button variant="secondary" onClick={ this.onHide } disabled={ loading }>
                                    Annuleer
                                </Button>
                                <Button variant="primary" onClick={ this.createInvoice } disabled={ loading }>
                                    { loading && (
                                        <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                                    )}
                                    Aanmaken
                                </Button>
                            </React.Fragment>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }

}
CreateInvoiceModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    refreshOrder: PropTypes.func.isRequired,
    orderId: PropTypes.number.isRequired,
    shopsContext: ShopsContextPropTypes.isRequired
}

export default withShopsContext(CreateInvoiceModal);
