import React, {
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Title
} from "@zandor300/backoffice-framework";

import ShopsContext from "../../context/ShopsContext";
import Helmet from "../../components/Helmet";
import DashboardCard from "./components/DashboardCard";
import DashboardOrderRevenueChart from "./components/DashboardOrderRevenueChart";

function Dashboard() {
    const shopsContext = useContext(ShopsContext);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setData(null);
        setError(null);
        axios.post("/getDashboardData", { shopId: shopsContext.currentShop.id })
            .then((response) => {
                if(response.data.valid) {
                    setData(response.data);
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                }
            })
            .catch(() => {
                setError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }, [shopsContext.currentShop]);

    return (
        <React.Fragment>
            <Helmet title="Dashboard"/>
            <Title preTitle="Overzicht">
                Dashboard
            </Title>
            <div className="row">
                <DashboardCard
                    title="Aantal bestellingen"
                    content={ data && data.paidOrderAmount }
                    error={ error }
                />
                { shopsContext.currentShop.entitlementAuthentication && (
                    <DashboardCard
                        title="Aantal geregistreerde klanten"
                        content={ data && data.registeredUsersAmount }
                        subline={ data && (data.activeUsersAmount30Days + " actief de afgelopen 30 dagen") }
                        error={ error }
                    />
                )}
                <DashboardCard
                    title="Omzet"
                    content={ data && new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(data.totalRevenue) }
                    error={ error }
                    subline="excl. btw"
                />
                <div className="col-md-12">
                    <DashboardOrderRevenueChart/>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(Dashboard);
