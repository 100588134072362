import React from "react";
import PropTypes from "prop-types";

import PriceFormatter from "../../../../components/priceFormatter";
import PriceSummaryRow from "../../../../components/PriceSummaryRow";

function OrderDetailPriceSummary({ order }) {
    return (
        <div className="card">
            <div className="card-body">
                <PriceSummaryRow
                    title="BTW"
                    className="text-muted mb-2"
                >
                    <PriceFormatter price={ order.totalTaxPrice }/>
                </PriceSummaryRow>
                { order.shippingPrice > 0 && (
                    <PriceSummaryRow
                        title="Verzendkosten"
                        className="text-muted mb-2"
                    >
                        <PriceFormatter price={ order.shippingPrice }/>
                    </PriceSummaryRow>
                )}
                { order.discountAmountInclVat > 0 && (
                    <PriceSummaryRow
                        title="Korting"
                        className="text-muted mb-2"
                    >
                        <PriceFormatter price={ order.discountAmountInclVat }/>
                    </PriceSummaryRow>
                )}
                <PriceSummaryRow
                    title="Totaalbedrag"
                    subtitle="exclusief btw"
                    className="text-muted mb-2"
                >
                    <PriceFormatter price={ order.totalPriceExclTax }/>
                </PriceSummaryRow>
                { (order.originalPriceInclVat !== null && order.originalPriceInclVat !== order.totalPrice) && (
                    <PriceSummaryRow
                        title="Originele prijs"
                        subtitle="inclusief btw"
                        className="text-danger mb-2"
                        style={{
                            textDecoration: "line-through"
                        }}
                    >
                        <PriceFormatter price={ order.originalPriceInclVat }/>
                    </PriceSummaryRow>
                )}
                <PriceSummaryRow
                    title="Totaalbedrag"
                    subtitle="inclusief btw"
                    className="mb-2"
                >
                    <PriceFormatter price={ order.totalPrice }/>
                </PriceSummaryRow>
            </div>
        </div>
    )
}
OrderDetailPriceSummary.propTypes = {
    order: PropTypes.object
}

export default React.memo(OrderDetailPriceSummary);
