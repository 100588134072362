import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Spinner
} from "react-bootstrap";

function CourseForm({ course, setCourse, loading, onSubmit }) {
    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Algemene info</h5>
                    <div className="form-group">
                        <label htmlFor="title">Titel</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={ course.title }
                            onChange={ (event) => setCourse({ ...course, title: event.target.value }) }
                            disabled={ loading }
                        />
                    </div>
                    <div className="form-group mb-0">
                        <label htmlFor="maxAccessDuration">Toegangsduur (aantal dagen)</label>
                        <input
                            type="number"
                            className="form-control"
                            id="maxAccessDuration"
                            value={ course.maxAccessDuration }
                            onChange={ (event) => setCourse({ ...course, maxAccessDuration: event.target.value }) }
                            disabled={ loading }
                        />
                        <small className="form-text text-muted">
                            Dit is alleen van toepassing op nieuwe bestellingen. Klanten die al toegang hebben behouden
                            dezelfde toegangsduur.
                        </small>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Beschrijving</h5>
                    <div className="form-group mb-0">
                        <label htmlFor="description">Beschrijving</label>
                        <textarea
                            className="form-control"
                            id="description"
                            value={ course.description }
                            onChange={ (event) => setCourse({ ...course, description: event.target.value }) }
                            disabled={ loading }
                            style={{ height: "150px" }}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button
                    variant="primary"
                    onClick={ onSubmit }
                    disabled={ loading }
                >
                    { loading && (
                        <Spinner animation="border" size="sm" variant="dark" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </div>
        </React.Fragment>
    )
}
CourseForm.propTypes = {
    course: PropTypes.object.isRequired,
    setCourse: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default React.memo(CourseForm);
