import PropTypes from "prop-types";
import axios from "axios";

import BaseAddProductModal from "../../../../components/modal/BaseAddProductModal";

class CategoryAddProductModal extends BaseAddProductModal {
    abstractGetTitle() {
        return "Product toevoegen"
    }

    // eslint-disable-next-line no-unused-vars
    abstractSave(productId, onComplete, onError) {
        axios.post("/addProductToCategory", {
            shopId: this.context.currentShop.id,
            categoryId: this.props.category.id,
            productId
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setCategory(response.data.category);
                    onComplete();
                } else {
                    onError("Er ging iets fout bij het toevoegen van het product. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                onError("Er ging iets fout bij het toevoegen van het product.");
            });
    }
}
CategoryAddProductModal.propTypes = {
    ...BaseAddProductModal.propTypes,
    category: PropTypes.object.isRequired,
    setCategory: PropTypes.func.isRequired
}

export default CategoryAddProductModal;
